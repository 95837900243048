import React, { lazy, Suspense } from 'react';

const LazyOlink = lazy(() => import('./Olink'));

const Olink = (props: JSX.IntrinsicAttributes & { children?: React.ReactNode; }) => (
  <Suspense fallback={null}>
    <LazyOlink {...props}/>
  </Suspense>
)

export default Olink;

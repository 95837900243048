import React, { useState } from "react";
import { Button } from "@mui/material";

import style from "./PrivacyPolicy.module.scss";
import { useNavigate } from "react-router-dom";
import { useWindowDimensions } from "../../../services/widthCheck.service";
import PreviewPageHome from "../../ui/PreviewPageHome/PreviewPageHome";
import { useUser } from "../../../providers/user.provider";
import Banner from "../../ui/Banner/Banner";

const PrivacyPolicy = () => {
  const { width } = useWindowDimensions();
  const navigate = useNavigate();
  const user = useUser();
  const handleOnBack = async () => {
    user ? navigate("/settings/main") : navigate("/");
  };

  return (
    <div
      className={`main-content privacy_policy ${
        !user ? "privacy_policy--landing" : ""
      }`}
    >
      <div
        className={`${style.privacy_policy} ${
          !user ? style.privacy_policy__landing : ""
        }  bg-gray_light`}
      >
        {width >= 768 && (
          <div className="page-title">
            <h2 className="text-center text-black">Privacy Policy</h2>
          </div>
        )}

        <div className={` container`}>
          {/* <Banner /> */}
          <div className="page_top">
            {user ? (
              <button
                type="button"
                className="btn_navigate btn_navigate_arrow"
                onClick={handleOnBack}
              >
                Back
              </button>
            ) : null}

            {width < 768 ? (
              <h2 className="text-center ff-gilroy text-black">
                Privacy Policy
              </h2>
            ) : null}
          </div>

          <div className={`${style.privacy_policy__wrapper} card card--xl`}>
            <div className={`${style.privacy_policy__card} `} tabIndex={0}>
              <h6 className={`mb-10 p fw-600`}>The Only Link Privacy Policy</h6>
              <p className={style.privacy_policy__text}>
                At The Only Link, we are committed to protecting your privacy
                and handling your personal data responsibly. We collect and
                process your information only with your consent and under the
                terms outlined in this Privacy Policy.
              </p>
              <p className={style.privacy_policy__text}>
                This document explains when, where, and why we collect your
                personal data, how we use it, under what circumstances we may
                disclose it to third parties, your rights, and the measures we
                take to protect your information.
              </p>
              <p className={style.privacy_policy__text}>
                We may update this Privacy Policy from time to time, so we
                recommend checking this page periodically. In the event of
                changes, we will notify you via your The Only Link account. If
                you continue using the service within one week of receiving the
                notification, it will be considered that you accept the changes
                unless otherwise required by law.
              </p>
              <p className={style.privacy_policy__text}>
                Any questions regarding this Policy and our services should be
                sent to
                <a href="mailto:contact@theonly.link"> contact@theonly.link</a>.
              </p>
              <h6 className={`${style.privacy_policy__header} p fw-600`}>
                1. What is The Only Link?
              </h6>
              <p className={style.privacy_policy__text}>
                Since you have chosen The Only Link, we assume that you are
                familiar with the core focus of our services and activities. You
                have also reviewed our Terms and Conditions. However, to ensure
                a better understanding of the purposes for which your personal
                data is collected and processed, we would like to reiterate
                this.
              </p>
              <p className={style.privacy_policy__text}>
                The Only Link is a powerful tool for social media and marketing
                management. It allows you to create a single link for all your
                media resources. The platform is operated by Mobizy OÜ,
                registered at Jõe tn 3-301, Kesklinna linnaosa, Harju maakond,
                Tallinn, 10151, Estonia.
              </p>
              <h6 className={`${style.privacy_policy__header} p fw-600`}>
                2. What Is Personal Information, and What Is Processing of
                Personal Information?
              </h6>
              <p className={style.privacy_policy__text}>
                Personal information (or data) is any information relating to
                you and that alone or in cumulation with other pieces of
                information gives the opportunity to the person that collects
                and processes such information to identify you as a person. It
                can be your name, your identification number, your location
                data, or information related to your physical, physiological,
                genetic, mental, economic, cultural or social life. Processing
                of the information means any action with your personal data, for
                example, collection, recording, organisation, structuring,
                storage, use, disclosure by any means and so on.
              </p>
              <h6 className={`${style.privacy_policy__header} p fw-600`}>
                3. How Does This Privacy Notice Apply to Me?
              </h6>
              <p className={style.privacy_policy__text}>
                This Privacy Notice applies to personal information collected
                and processed by us as controllers from:
                <br />
                <span className={style.dot}></span>Visitors to our Platform
                ("Platform Visitors").
                <br />
                <span className={style.dot}></span>Individuals or
                representatives of businesses using our services via a paid plan
                ("Paid Plan Users") or free plan ("Free Plan Users"),
                collectively referred to as "The Only Link Users".
                <br />
                <span className={style.dot}></span>Subscribers following user
                profiles.
                <br />
                <span className={style.dot}></span>Visitors interacting with
                user profiles.
              </p>
              <p className={style.privacy_policy__text}>
                If you provide information about another person, please ensure
                they receive this Privacy Notice and are aware of how their
                information will be processed.
              </p>
              <h6 className={`${style.privacy_policy__header} p fw-600`}>
                4. What Personal Information Do We Collect?
              </h6>
              <p className={style.privacy_policy__text}>
                <b style={{ color: "#2e2e2e" }}>4.1. </b> Information You
                Provide Voluntarily
              </p>
              <p className={style.privacy_policy__text}>
                We may collect personal data such as your name, email address,
                phone number, address, and payment information when you:
                <br />
                <span className={style.dot}></span>Sign up for our platform,
                whether as a free or paid user.
                <br />
                <span className={style.dot}></span>Interact with our platform.
                <br />
                <span className={style.dot}></span>Submit reports, queries, or
                requests.
              </p>
              <p className={style.privacy_policy__text}>
                <b style={{ color: "#2e2e2e" }}> 4.2.</b> Automatically
                Collected Information
              </p>
              <p className={style.privacy_policy__text}>
                We gather aggregate data such as IP address, browser type, site
                activity, and frequently accessed pages to analyze usage trends
                and improve services. This information is anonymized and does
                not directly identify individuals.
              </p>
              <p className={style.privacy_policy__text}>
                <b style={{ color: "#2e2e2e" }}> 4.3. </b>Information from Third
                Parties
              </p>
              <p className={style.privacy_policy__text}>
                We may receive data from authorized third-party sources, such as
                partners or service providers.
              </p>
              <p className={style.privacy_policy__text}>
                <b style={{ color: "#2e2e2e" }}>4.4. </b>Children’s Data
              </p>
              <p className={style.privacy_policy__text}>
                Our services are not intended for individuals under 18. Parents
                should contact us to address any inadvertent data collection.
              </p>
              <h6 className={`${style.privacy_policy__header} p fw-600`}>
                5. Why does The Only Link collect information from you?
              </h6>
              <p className={style.privacy_policy__text}>
                We collect and use your personal information to:
              </p>
              <p className={style.privacy_policy__text}>
                <b style={{ color: "#2e2e2e" }}>5.1. </b>Provide Services:
                Deliver and maintain personalized and efficient platform
                functionality tailored to your needs.
              </p>
              <p className={style.privacy_policy__text}>
                <b style={{ color: "#2e2e2e" }}>5.2. </b> Enhance User
                Experience: Optimize and personalize services, content, and
                recommendations based on your preferences.
              </p>
              <p className={style.privacy_policy__text}>
                <b style={{ color: "#2e2e2e" }}>5.3. </b> Facilitate
                Transactions: Process payments, manage billing, and resolve
                transactional issues.
              </p>
              <p className={style.privacy_policy__text}>
                <b style={{ color: "#2e2e2e" }}>5.4. </b> Customer Support:
                Respond to inquiries, complaints, and reports promptly and
                effectively.
              </p>
              <p className={style.privacy_policy__text}>
                <b style={{ color: "#2e2e2e" }}>5.5. </b> Deliver Updates:
                Notify you about changes, updates, or improvements within the
                platform.
              </p>
              <p className={style.privacy_policy__text}>
                <b style={{ color: "#2e2e2e" }}>5.6. </b> Marketing
                Communication: Share promotional offers, products, and services
                aligned with your interests (where consented).
              </p>
              <p className={style.privacy_policy__text}>
                <b style={{ color: "#2e2e2e" }}>5.7. </b> Conduct Research:
                Perform statistical analysis, market research, and business
                planning to improve our services.
              </p>
              <p className={style.privacy_policy__text}>
                <b style={{ color: "#2e2e2e" }}>5.8. </b> Ensure Security:
                Detect and prevent fraud, unauthorized access, and technical
                issues to maintain platform safety.
              </p>
              <p className={style.privacy_policy__text}>
                <b style={{ color: "#2e2e2e" }}>5.9. </b> Legal Compliance:
                Fulfill legal obligations and assist with regulatory or law
                enforcement requests.
              </p>
              <p className={style.privacy_policy__text}>
                <b style={{ color: "#2e2e2e" }}>5.10. </b> User Verification:
                Verify identities to prevent misuse and ensure accurate profile
                data.
              </p>
              <p className={style.privacy_policy__text}>
                <b style={{ color: "#2e2e2e" }}>5.11. </b> Improve
                Functionality: Test and analyze data to troubleshoot issues and
                optimize platform features.
              </p>
              <p className={style.privacy_policy__text}>
                <b style={{ color: "#2e2e2e" }}>5.12. </b> Content Moderation:
                Review and manage content in line with our community standards
                and terms of service.
              </p>
              <p className={style.privacy_policy__text}>
                <b style={{ color: "#2e2e2e" }}>5.13. </b> Performance
                Monitoring: Track platform usage to identify trends, improve
                reliability, and enhance performance.
              </p>
              <p className={style.privacy_policy__text}>
                <b style={{ color: "#2e2e2e" }}>5.14. </b> User Feedback:
                Collect feedback to refine services and introduce new features.
              </p>
              <p className={style.privacy_policy__text}>
                <b style={{ color: "#2e2e2e" }}>5.15. </b> Algorithm
                Enhancement: Improve recommendations and suggestions using data
                insights for better personalization.
              </p>
              <p className={style.privacy_policy__text}>
                <b style={{ color: "#2e2e2e" }}>5.16. </b> Platform
                Administration: Manage operations, including troubleshooting,
                data analysis, testing, and system maintenance.
              </p>
              <p className={style.privacy_policy__text}>
                Any changes to the purpose of processing your personal data will
                be communicated via email before implementation.
              </p>
              <h6 className={`${style.privacy_policy__header} p fw-600`}>
                6. Who Will Get Access to Your Personal Information?
              </h6>
              <p className={style.privacy_policy__text}>
                In order to provide high-quality services within the platform
                The Only Link hires people, enters into agreements with
                independent contractors as well as cooperates with other
                services providers, companies and organizations. For that
                reasons, some of your personal information can be passed to the
                persons mentioned. In order to achieve the platform goals in the
                best way The Only Link uses various technologies and services
                including the one that can be provided and/or hosted in other
                countries. Your data may thus be accessed by the IT services
                contractors of The Only Link in the USA, Ukraine or other
                countries that are not members of European Economic Area. The
                transfer of your data to the recipients in these countries are
                secured by the Standard data protection clauses adopted by the
                European Commission and compliant with the EU data protection
                laws. By registering within the The Only Link platform and by
                using it, you agree to such transfer and processing within the
                purposes set in Section 4 of this Privacy Policy.
              </p>
              <p className={style.privacy_policy__text}>
                In all cases we pass the information to other persons, we ensure
                that your information is being protected and used only within
                the purposes specified in Section 4 of this Privacy Policy. This
                is achieved by using only certified services and products,
                signing agreements on protection of personal data with
                contractors as well as taking technical measures to ensure the
                information is protected when stored, used and while being
                transferred. You also have to be aware of the fact that The Only
                Link may disclose your personal information to enforce and
                comply with the law. In other words, The Only Link may disclose
                to officials information necessary for the investigation or
                legal process on official request or the official bodies acting
                within their power.
              </p>
              <p className={style.privacy_policy__text}>
                We may also share your personal information with:
                <br />
                <span className={style.dot}></span>Group companies and
                third-party providers to improve the platform’s functionality
                and security.
                <br />
                <span className={style.dot}></span>Social media providers if we
                introduce log-ins.
                <br />
                <span className={style.dot}></span>Law enforcement or
                governmental bodies when required by law or to protect legal
                rights.
                <br />
                <span className={style.dot}></span>Potential buyers during a
                merger or acquisition.
              </p>
              <p className={style.privacy_policy__text}>
                For payment services, we rely on third-party payment processors,
                and their privacy policies govern the use of your payment
                information.
              </p>
              <h6 className={`${style.privacy_policy__header} p fw-600`}>
                7. Disclosure of Personal Information to Other Countries
              </h6>
              <p className={style.privacy_policy__text}>
                Your personal information may be transferred to and processed in
                countries outside your residence. These countries may have
                different data protection laws. Specifically, The Only Link may
                transfer data to the USA or other countries in which we operate.
                We ensure appropriate safeguards are in place to protect your
                information, including data transfer agreements with group
                companies and third-party service providers. Transfers will only
                occur if adequate security measures are in place to protect your
                personal information. More details are available upon request.
              </p>
              <h6 className={`mb-10 p fw-600`}>
                8. Duration of Personal Information Processing and Storage by
                The Only Link
              </h6>
              <p className={style.privacy_policy__text}>
                Your personal information will be stored by The Only Link within
                the term of your use of the platform and up to your termination
                of such use which means the deletion of your account from The
                Only Link platform. The Only Link shall terminate the processing
                of your personal information unless the special retention period
                for the storage of such data is set by the relevant legislation.
              </p>
              <h6 className={`mb-10 p fw-600`}>
                9. Your Rights Regarding Your Personal Information The Only Link
                Collects And Processes
              </h6>
              <p className={style.privacy_policy__text}>
                <b style={{ color: "#2e2e2e" }}>9.1. </b> You have a right to
                request The Only Link to access to your personal information
              </p>
              <p className={style.privacy_policy__text}>
                That means that you can ask The Only Link what personal
                information of yours is being processed as well as for the
                clarifications on the information described above, i.e. purpose
                of collecting and processing, period of processing, third
                parties that have access to information.
              </p>
              <p className={style.privacy_policy__text}>
                <b style={{ color: "#2e2e2e" }}>9.2. </b> You have a right to
                request The Only Link to rectify your personal information
              </p>
              <p className={style.privacy_policy__text}>
                This means you can ask all the inaccurate personal information
                concerning you being corrected. You can also complete the
                personal information if you feel there is a need to do so.
              </p>
              <p className={style.privacy_policy__text}>
                <b style={{ color: "#2e2e2e" }}>9.3. </b> You have a right to
                request The Only Link to erase personal information
              </p>
              <p className={style.privacy_policy__text}>
                You can request The Only Link to erase such data if its
                processing is no longer necessary in relation to the purposes
                for which it was collected, as well as if there are no legal
                grounds for the processing. In most cases The Only Link will
                erase it unless otherwise will be required by legislation.
              </p>
              <p className={style.privacy_policy__text}>
                <b style={{ color: "#2e2e2e" }}>9.4. </b> You have a right to
                request The Only Link to restrict the processing of your
                personal information
              </p>
              <p className={style.privacy_policy__text}>
                In some cases, prescribed by law you will be able to restrict
                the processing of your personal data, for example if you contest
                the accuracy of your personal information being processed or in
                case The Only Link will not be interested to process your
                personal information any longer, but you will want The Only Link
                to do this on different reasons, for example, to bring some
                claim for somebody and instead of the erasure of information its
                processing will be just restricted.
              </p>
              <p className={style.privacy_policy__text}>
                <b style={{ color: "#2e2e2e" }}>9.5. </b> You have a right to
                withdraw consent
              </p>
              <p className={style.privacy_policy__text}>
                You can withdraw your consent for the collection and processing
                of your personal information by The Only Link at any time,
                without affecting the lawfulness of processing based on consent
                before its withdrawal. After we at The Only Link receive such
                withdrawal from you we will process it in a timely manner and no
                longer process such information unless the obligation to proceed
                is set by law.
              </p>
              <p className={style.privacy_policy__text}>
                If you have any request to The Only Link related to the use of
                your rights, please drop us a line here
                <a href="mailto:contact@theonly.link"> contact@theonly.link</a>.
              </p>
              <h6 className={`mb-10 p fw-600`}>
                10. Time For Reply And Reaction
              </h6>
              <p className={style.privacy_policy__text}>
                The Only Link will provide information on action taken on your
                request related to your rights specified above within one month
                of receipt of the request for the longest. That period may be
                extended by two further months if The Only Link is overwhelmed
                by the number of the requests. The Only Link will inform you of
                any such extension within one month of receipt of the request,
                together with the reasons for the delay.
              </p>
              <h6 className={`mb-10 p fw-600`}>11. Complaints</h6>
              <p className={style.privacy_policy__text}>
                We take privacy concerns seriously. If you have a complaint
                about how we handle your personal information or our privacy
                practices, please contact us via email at
                <a href="mailto:contact@theonly.link"> contact@theonly.link</a>.
                We will acknowledge your complaint and, if necessary, initiate
                an investigation.
              </p>
              <p className={style.privacy_policy__text}>
                If further details are needed, we may reach out. We will inform
                you of the outcome of the investigation. If you're unsatisfied
                with our response, you may contact your local privacy and data
                protection authority.
              </p>
              <h6 className={`mb-10 p fw-600`}>
                12. How The Only Link Protects Personal Information?
              </h6>
              <p className={style.privacy_policy__text}>
                The Only Link takes technical and organizational measures to
                ensure the information is processed in a manner that ensures
                appropriate security of information, including protection
                against unauthorized or unlawful processing and against
                accidental loss, destruction or damage.
              </p>
              <p className={style.privacy_policy__text}>
                We provide access to your personal information only to
                employees, agents, contractors, and other third parties who need
                it for business purposes. They are required to process your data
                solely under our instructions and maintain confidentiality.
              </p>
              <p className={style.privacy_policy__text}>
                While we take commercially reasonable measures to protect your
                personal information, we cannot guarantee absolute security. We
                have implemented procedures to address any suspected data
                breaches and will notify you and any relevant regulator if
                required by law.
              </p>
              <h6 className={`mb-10 p fw-600`}>13. Cookies</h6>
              <p className={style.privacy_policy__text}>
                What are cookies? These are tiny pieces of code that remain in
                your computer after you have visited some website. These pieces
                then enable the server to provide you with the information that
                is customized with your needs when you use some platform or
                website for the next time.
              </p>
              <p className={style.privacy_policy__text}>
                If you visited our website and came here, to the Privacy Policy,
                the cookies consent form was the first you had to notice on the
                website. Please see the detailed information on these tools in
                our Cookies Policy. The Cookies Policy is a part of this Privacy
                Policy.
              </p>
              <h6 className={`mb-10 p fw-600`}>
                14. Are You Already 18 Years Old?
              </h6>
              <p className={style.privacy_policy__text}>
                The privacy of children is one of our concerns as well. Here at
                The Only Link we can provide services only in case you as the
                user are aged 18 or older. If you are under 18 you will need to
                get your parent’s/guardian’s permission before submission any
                private information to The Only Link.
              </p>
              <h6 className={`mb-10 p fw-600`}>
                15. Subscription Plans: Free and Paid Versions
              </h6>
              <p className={style.privacy_policy__text}>
                We offer a free version with basic features. The paid version
                unlocks advanced tools, settings, and full customization to suit
                your business needs, offering greater results and efficiency.
              </p>
              <p className={style.privacy_policy__text}>
                The current prices and other conditions of the tariff plans and
                Services are posted on the Website at the link:{" "}
                <a href="https://theonly.link/" target="_blank">
                  https://theonly.link/
                </a>
                .
              </p>
              <h6 className={`mb-10 p fw-600`}>
                16. Supplemental Information for California Residents
              </h6>
              <p className={style.privacy_policy__text}>
                In compliance with California laws, we provide the following
                details about the personal information we collect:
              </p>
              <p className={style.privacy_policy__text}>
                <b style={{ color: "#2e2e2e" }}>1. </b> Types of Data Collected:
                Identifiers, payment info, geolocation, online activity, and
                more.
              </p>
              <p className={style.privacy_policy__text}>
                <b style={{ color: "#2e2e2e" }}>2. </b> Sources of Data:
                Directly from you, via services, or third-party providers.
              </p>
              <p className={style.privacy_policy__text}>
                <b style={{ color: "#2e2e2e" }}>3. </b> TYour Rights: Request
                access to your data, delete data, opt-out of selling (we do not
                sell), and more.
              </p>
              <p className={style.privacy_policy__text}>
                <b style={{ color: "#2e2e2e" }}>4. </b> Non-discrimination: You
                will not be discriminated against for exercising privacy rights.
              </p>
              <p className={style.privacy_policy__text}>
                For any inquiries, please contact us at
                <a href="mailto:contact@theonly.link"> contact@theonly.link</a>.
              </p>
              <p className={style.privacy_policy__text}>
                We hope this Privacy Policy helped you to understand how your
                personal information will be dealt with here at The Only Link.
                Do not be afraid — we will take care of it in order the platform
                will only help you to do your stuff and not to be concerned over
                your privacy at The Only Link. So, enjoy our platform!
              </p>
              <p className={style.privacy_policy__text}>
                If you have any request to The Only Link, please drop us a line
                here
                <a href="mailto:contact@theonly.link"> contact@theonly.link</a>,
                we will be happy to help and chat.
              </p>
              <p className={style.privacy_policy__text}>
                Your The Only Link team.
              </p>
            </div>
          </div>
        </div>

        {user && width > 767 && (
          <div className="right_bar right_bar_preview-window">
            <h4 className="ff-gilroy fw-700 l-spacing text-center">Preview</h4>
            <div className="right_bar__container">
              <PreviewPageHome />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default PrivacyPolicy;

import React, { useEffect, useRef, useState } from "react";
import Dialog from "@mui/material/Dialog";
import { DialogContent, DialogTitle } from "@mui/material";
import cross from "../../../../assets/svg/cross.svg";
import copy from "../../../../assets/svg/copy.svg";
import copyGray from "../../../../assets/svg/copy--gray.svg";
import share from "../../../../assets/svg/share.svg";
import download from "../../../../assets/svg/download.svg";
import downloadGrey from "../../../../assets/svg/download--gray.svg";
import QRCodeStyling from "qr-code-styling";

import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MobileStepper from "@mui/material/MobileStepper";
import SwipeableViews from "react-swipeable-views";
import Button from "@mui/material/Button";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";

import styles from "./ShareDialog.module.scss";
import { useUser } from "../../../../providers/user.provider";
import { useGuest } from "../../../../providers/guest.provider";
import { useWindowDimensions } from "../../../../services/widthCheck.service";
import QRCode from "../../QRCode/QRCode";
import html2canvas from "html2canvas";

import domtoimage from "dom-to-image";

interface ITolDialog {
  open: boolean;
  onClose: () => void;
  onShare: () => void;
  [key: string]: any;
}

const ShareDialog = (props: ITolDialog) => {
  const { width } = useWindowDimensions();
  const { setActiveSnackBar, activeSnackBar } = useGuest();
  const user = useUser();
  const { open, onClose, onShare, ...other } = props;
  const [base64, setBase64] = useState<string[]>([]);
  const [activeStep, setActiveStep] = useState(0);
  const [activeUrl, setActiveUrl] = useState(
    process.env.REACT_APP_BACK_URL + "/" + user.currentOLink?.path
  );
  const [loading, setLoading] = useState<boolean>(true);
  const qrCodeImageRef = useRef<any>(null);
  const qrCodeRef = useRef<any>(null);
  const [qrCodeData, setQrCodeData] = useState<any>(null);

  const olinks = [
    {
      path: user.currentOLink?.path,
    },
  ];

  const isSafari = /^((?!chrome|android).)*safari/i.test(navigator?.userAgent);
  // user.currentOLink?.olinksNames!.map((on) => {
  //   olinks.push({
  //     path: user.currentOLink?.path + "?name=" + on.name,
  //   });
  // });

  const handleClose = () => {
    onClose && onClose();
  };

  const shareViaSocial = (type: string) => () => {
    onShare && onShare();
  };

  const copyToClipboard = () => {
    copyImageToClipBoardSafari();

    // if (isSafari) {
    //   copyImageToClipBoardSafari();
    // } else {
    //   copyImageToClipBoardOtherBrowsers();
    // }
  };

  const snapshotCreator = (): any => {
    return new Promise((resolve, reject) => {
      try {
        const scale = window.devicePixelRatio;
        const element = qrCodeImageRef.current;
        domtoimage
          .toBlob(element, {
            height: element.offsetHeight * scale,
            width: element.offsetWidth * scale,
            style: {
              transform: "scale(" + scale + ")",
              transformOrigin: "top left",
              width: element.offsetWidth + "px",
              height: element.offsetHeight + "px",
              backgroundColor: "#FFFFFF",
            },
          })
          .then((blob: any) => {
            resolve(blob);
          });
      } catch (e) {
        reject(e);
      }
    });
  };

  const copyImageToClipBoardSafari = () => {
    navigator.clipboard
      .write([
        new ClipboardItem({
          "image/png": new Promise(async (resolve, reject) => {
            try {
              const blob = await snapshotCreator();
              resolve(new Blob([blob], { type: "image/png" }));
            } catch (err) {
              reject(err);
            }
          }),
        }),
      ])
      .then(() => {
        setActiveSnackBar({
          state: true,
          name: "success",
          message: "QR Code copied",
        });
      })
      .catch((err) =>
        // Error
        console.error("Error:", err)
      );
  };

  const copyImageToClipBoardOtherBrowsers = async () => {
    const canvas = await html2canvas(qrCodeImageRef.current, {
      allowTaint: true,
      useCORS: true,
    });

    const dataURL = canvas.toDataURL("image/png");

    const makeImagePromise = async () => {
      const response = await fetch(dataURL);
      return await response.blob();
    };

    navigator.clipboard
      .write([new ClipboardItem({ "image/png": makeImagePromise() })])
      .then(function () {
        setActiveSnackBar({
          state: true,
          name: "success",
          message: "QR Code copied",
        });
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  const childComponentRef = useRef<any>(null);

  const handleDownload = () => {
    if (childComponentRef.current) {
      const qrInfo = childComponentRef.current.getQRCodeInfo();
      qrInfo.data.data = qrInfo.url;
      qrInfo.data.width = 1024;
      qrInfo.data.height = 1024;
      const qrCode = new QRCodeStyling(qrInfo?.data);

      console.log("QR Info:", qrInfo);
      qrCode.download({ name: "qr", extension: "svg" });
    }
  };
  // const handleDownload = async () => {
  //   const canvas = await html2canvas(qrCodeRef.current, {
  //     scale: 2,
  //     allowTaint: true,
  //     useCORS: true,
  //   });
  //   const dataURL = canvas.toDataURL("image/png");
  //   const blob = await new Promise((resolve, reject) => {
  //     const link = document.createElement("a");
  //     link.href = dataURL;
  //     link.download = "qrCode.png";
  //     // Simulate click to trigger download (prevents memory leaks)
  //     link.style.display = "none";
  //     document.body.appendChild(link);
  //     link.click();
  //     document.body.removeChild(link);

  //     // Resolve with Blob object after simulated click
  //     link.onload = () => {
  //       URL.revokeObjectURL(link.href);
  //       resolve(new Blob([dataURL.substring(22)], { type: "image/png" })); // Remove data:image/png;base64, prefix
  //     };
  //     link.onerror = reject;
  //   });
  // };

  const handleCopy = () => {
    const copyText = document.createElement("input") as HTMLInputElement;
    document.body.appendChild(copyText);
    copyText.value = activeUrl;
    copyText.select();
    copyText.setSelectionRange(0, 99999);
    navigator.clipboard.writeText(copyText.value);
    document.body.removeChild(copyText);
    setActiveSnackBar({
      state: true,
      name: "success",
      message: "Link copied",
    });
  };

  const handleGetBase64 = (b64: string, index: number) => {
    const [...newBase64] = base64;
    newBase64[index] = b64;
    setBase64(newBase64);
  };

  const theme = useTheme();
  const maxSteps = olinks.length;

  const handleStepChange = (step: number) => {
    setActiveStep(step);
    if (
      user.currentOLink?.olinksNames &&
      user.currentOLink?.olinksNames.length > 0
    ) {
      if (step > 0) {
        let params = "?name=" + user.currentOLink.olinksNames[step - 1].name;
        setActiveUrl(
          process.env.REACT_APP_BACK_URL +
            "/" +
            user.currentOLink?.path +
            params
        );
      } else {
        setActiveUrl(
          process.env.REACT_APP_BACK_URL + "/" + user.currentOLink?.path
        );
      }
    }
  };

  useEffect(() => {
    setActiveUrl(
      process.env.REACT_APP_BACK_URL + "/" + user.currentOLink?.path
    );
  }, [user.currentOLink]);

  useEffect(() => {
    setQrCodeData({
      ...user.qrCode,
    });
  }, [user.qrCode]);

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="customized-dialog-title"
      open={props.open}
      className={styles.share}
    >
      <div className={styles.share__wrapper}>
        <DialogTitle className={`${styles.share__title} text-black`}>
          <p className="text-center ff-gilroy fw-700">Share</p>
          {width <= 1200 && (
            <button className={styles.share__close} onClick={handleClose}>
              <img src={cross} alt="Close edit" width={24} height={24} />
            </button>
          )}
        </DialogTitle>
        <DialogContent className={styles.share__content}>
          <Box className={styles.carousel}>
            <SwipeableViews
              axis={theme.direction === "rtl" ? "x-reverse" : "x"}
              index={activeStep}
              onChangeIndex={handleStepChange}
              enableMouseEvents
            >
              {olinks.map((item, index) => {
                return (
                  <div className={styles.carousel__slide} key={index}>
                    <div
                      className={`${styles.carousel__wrapper} card card--xl`}
                    >
                      <div
                        ref={qrCodeImageRef}
                        className={`${styles.export_block}`}
                      >
                        <div ref={qrCodeRef} className={styles.carousel__code}>
                          <QRCode
                            data={qrCodeData}
                            url={activeUrl}
                            componentName={true}
                            enableUpdate={true}
                            loaded={(e: boolean) => setLoading(e)}
                            ref={childComponentRef}
                          />
                        </div>
                        <p className="text-center text-black ff-gilroy fw-600">
                          {item.path}
                        </p>
                      </div>
                    </div>
                  </div>
                );
              })}
            </SwipeableViews>
            {maxSteps >= 2 ? (
              <MobileStepper
                steps={maxSteps}
                position="static"
                variant="dots"
                activeStep={activeStep}
                className={styles.carousel__pagination}
                nextButton={
                  <>
                    {width > 767 && (
                      <Button
                        className={styles.carousel__navArrow}
                        disabled={activeStep === maxSteps}
                      >
                        {theme.direction === "rtl" ? (
                          <KeyboardArrowLeft />
                        ) : (
                          <KeyboardArrowRight />
                        )}
                      </Button>
                    )}
                  </>
                }
                backButton={
                  <>
                    {width > 767 && (
                      <Button
                        className={styles.carousel__navArrow}
                        disabled={activeStep === 0}
                      >
                        {theme.direction === "rtl" ? (
                          <KeyboardArrowRight />
                        ) : (
                          <KeyboardArrowLeft />
                        )}
                      </Button>
                    )}
                  </>
                }
              />
            ) : null}
          </Box>
          <div className={`${styles.share__row} mb-25`}>
            <button
              className={`${styles.share__btn} `}
              onClick={handleDownload}
              disabled={loading}
            >
              <img
                src={loading ? downloadGrey : download}
                alt="icon"
                width={24}
                height={24}
              />
              Download
            </button>
            <button
              id="copyBtn"
              className={`${styles.share__btn}`}
              onClick={() => copyToClipboard()}
              disabled={loading}
            >
              <img
                src={loading ? copyGray : copy}
                alt="icon"
                width={24}
                height={24}
              />
              Copy
            </button>
          </div>

          <h5 className={`${styles.border_top} text-black ff-gilroy mb-10`}>
            Link
          </h5>
          <p className="text-gray_dark mb-20 text_overflow">{activeUrl}</p>

          <div className={`${styles.share__row} mb-30`}>
            <button
              className={styles.share__btn}
              onClick={shareViaSocial("link")}
            >
              <img src={share} alt="icon" width={24} height={24} /> Share
            </button>
            <button className={styles.share__btn} onClick={handleCopy}>
              <img src={copy} alt="icon" width={24} height={24} /> Copy
            </button>
          </div>
        </DialogContent>
      </div>
    </Dialog>
  );
};

export default ShareDialog;

import React, { useState } from "react";
import "./Loading.module.scss";
import loader from "../../../assets/svg/loader.gif";
import Fade from "@mui/material/Fade";
import { useUser } from "../../../providers/user.provider";
import { useGuest } from "../../../providers/guest.provider";

const Loading = () => {
  const user = useUser();
  const guest = useGuest();
  const loading = user ? user.loading : guest.loading;

  return (
    <>
      {loading ? (
        <Fade in={loading}>
          <div className={"loader loader_active"}>
            <figure>
              <img src={loader} alt="loader gif" />
            </figure>
          </div>
        </Fade>
      ) : null}
    </>
  );
};

export default Loading;

import React, { useState } from "react";
import styles from "./UserHavingRights.module.scss";
import { useApi } from "../../../providers/api.provider";
import { IDelegateUserContext } from "../../../interfaces/user.context";
import { useGuest } from "../../../providers/guest.provider";
import { ESnackBarType } from "../../ui/SnackBar/SnackBar";

interface IPropsUsers {
  users: IDelegateUserContext[];
  olinkId: string;
  onChange: (delegateId: number) => void;
  updateData: () => void;
}

const UserHavingRights = (props: IPropsUsers) => {
  const { setActiveSnackBar } = useGuest();
  const api = useApi();
  const [active, setActive] = useState(false); // true or false
  const [indexActive, setIndexActive] = useState<string | number>("");

  const handleClick = (event: any, index: number) => {
    setActive(true);
    setIndexActive(index);
    // event.target.classList.add(`${styles.users__btn__active}`);
  };

  const handleClickRevoke = (delegateId: number | null) => {
    setActive(false);
    setIndexActive("");
    if (delegateId) {
      api.delegates
        .revokeProfile(delegateId)
        .then((res) => {
          props.onChange && props.onChange(delegateId);
        })
        .catch(async (err) => {
          if (
            err.response.data.message ===
            "Delegating rights for this profile not found"
          ) {
            props.updateData();
            setActiveSnackBar({
              state: true,
              name: ESnackBarType.error,
              message: "Delegating rights for this profile not found",
            });
          }
        });
    }
  };

  const handleClickCancelRevoke = () => {
    setActive(false);
    setIndexActive("");
  };
  // IDelegateUserContextIDelegateUserContext
  const UsersItem = (props: { user: any; index: number }) => {
    return (
      <>
        <div className={styles.users__item}>
          <h5
            className={
              active && indexActive === props.index
                ? `${styles.users__name} ${styles.users__name__active}`
                : `${styles.users__name}`
            }
          >
            {props.user!.delegatedUser?.firstName}{" "}
            {props.user!.delegatedUser?.lastName}
          </h5>
          <button
            onClick={(event) => handleClick(event, props.index)}
            className={`${styles.users__btn} text-error`}
            disabled={active}
            aria-label={
              props.user!.delegatedUser?.firstName +
              " " +
              props.user!.delegatedUser?.lastName +
              "Revoke"
            }
          >
            Revoke
          </button>
        </div>
        {active && indexActive === props.index ? (
          <div className={styles.choices}>
            <p className={styles.choices__text}>Revoke the rights?</p>
            <div className={styles.choices__row}>
              <button onClick={handleClickCancelRevoke}>No</button>
              <button
                className="text-error"
                onClick={() => {
                  handleClickRevoke(props.user.id);
                }}
              >
                Yes
              </button>
            </div>
          </div>
        ) : null}
      </>
    );
  };

  return (
    <div className={active ? styles.users__active : styles.users}>
      {props.users.map(
        (user: any, index) => (
          // user?.accepted === true ? (
          <UsersItem user={user} index={index} key={index} />
        )
        // ) : null
      )}
    </div>
  );
};

export default UserHavingRights;

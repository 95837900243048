import React from "react";
import { Navigate, useLocation } from "react-router-dom";
import jwtDecode from "jwt-decode";
import { useApi } from "../../../providers/api.provider";

const PublicRoute = ({ children }: { children: JSX.Element }) => {
  let api = useApi();
  let location = useLocation();

  if (api.accessToken) {
    const { confirmed, ...other } = jwtDecode<any>(api.accessToken);
    if (confirmed === true) {
      if (location.pathname === "/auth/code") {
        return (
          <Navigate to="/auth/welcome" state={{ from: location }} replace />
        );
      } else if (location.pathname === "/auth/welcome") {
        return children;
      } else {
        return <Navigate to="/" state={{ from: location }} replace />;
      }
    } else if (location.pathname !== "/auth/code") {
      return <Navigate to="/auth/code" state={{ from: location }} replace />;
    } else {
      return children;
    }
  } else {
    if (
      !api.accessToken &&
      (location.pathname === "/auth/code" ||
        location.pathname === "/auth/welcome")
    ) {
      return <Navigate to="/" state={{ from: location }} replace />;
    }
    return children;
  }
};

export default PublicRoute;

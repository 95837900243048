import React, { useState } from "react";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { IconButton, TextField, InputAdornment } from "@mui/material";
import { useApi } from "../../../providers/api.provider";
import { useUser } from "../../../providers/user.provider";

import styles from "./DeleteAccount.module.scss";
import warning from "../../../assets/svg/warning.svg";
import { useNavigate } from "react-router-dom";
import { useWindowDimensions } from "../../../services/widthCheck.service";
import PreviewPageHome from "../../ui/PreviewPageHome/PreviewPageHome";
import Banner from "../../ui/Banner/Banner";

export interface IPasswordErrors {
  keywords: null | string;
}

const DeleteAccount = () => {
  const { width } = useWindowDimensions();
  const navigate = useNavigate();
  const handleOnBack = async () => {
    navigate(-1);
  };

  const {
    setLoading,
    user,
    setUser,
    setCurrentProfile,
    setNotifications,
    setEvents,
    setUserProfiles,
    setPreviousAccessToken,
  } = useUser();
  const users = useUser();

  const [showPassword, setShowPassword] = useState<boolean>(false);

  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = () => setShowPassword(!showPassword);
  const [errors, setErrors] = useState<IPasswordErrors>({
    keywords: null,
  });
  const [disableBtn, setDisableBtn] = useState<boolean>(true);
  const [validate, setValidate] = useState<boolean>(false);
  const [keywords, setKeywords] = useState<string>("");

  const handleOnChangeTextField = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setKeywords(event.target.value);
    setDisableBtn(false);
  };

  const api = useApi();

  const handleDelete = async () => {
    if (validateForm()) {
      const id: any = user.id;
      setLoading(true);
      await api.user.deleteAccount({ id, keywords }).then(
        () => {
          setValidate(false);
          setDisableBtn(true);
          api.auth.clearDataLogout();
          setPreviousAccessToken(true);
          users.setUser({
            id: null,
            created_at: null,
            updated_at: null,
            firstName: null,
            lastName: null,
            email: null,
            confirmed: null,
            isActive: null,
            role: null,
            selectedProfile: null,
          });
          users.setCurrentProfile({
            id: null,
            created_at: null,
            updated_at: null,
            fullName: null,
            nickname: null,
            location: null,
            avatar: null,
            olinks: null,
            isActive: null,
            title: null,
            description: null,
            events: null,
            interest: null,
            links: null,
            settings: null,
            olink: null,
            delegates: [],
            userSocials: [],
          });
          users.setNotifications([]);
          users.setEvents([]);
          users.setListOlink([]);
          users.setUserProfiles([]);
          users.setCurrentOLink([]);
          users.setProfileLinks([]);
          users.setInActiveEvents([]);
          users.setDelegates([]);
        },
        (err) => {
          const mess = err.response.data.message;
          if (mess) {
            switch (mess) {
              case "User with this nickname already exists":
                setErrors((prevState: IPasswordErrors) => {
                  return { ...prevState, password: mess };
                });
                break;
            }
          }
        }
      );
      setLoading(false);
    }
  };

  const validateForm = (): boolean => {
    const errs = { ...errors };
    let res = true;
    if (!keywords || keywords.length === 0) {
      errs.keywords = "Keywords is required!";
      res = false;
    } else {
      errs.keywords = null;
    }
    if (keywords && keywords !== "deleting account") {
      errs.keywords = "Keywords is not correct!";
      res = false;
    } else {
      errs.keywords = null;
    }
    setErrors(errs);
    setValidate(true);
    return res;
  };

  return (
    <div className="main-content">
      <div className={`${styles.delete} bg-gray_light`}>
        {width >= 768 && (
          <div className="page-title">
            <h2 className="text-center text-error">Delete Account</h2>
          </div>
        )}
        <div className={`${styles.container} container`}>
          <Banner />
          <div className="page_top">
            <button
              type="button"
              className="btn_navigate btn_navigate_arrow"
              onClick={handleOnBack}
            >
              Back
            </button>
            {width < 768 && (
              <h2 className="text-center ff-gilroy text-error">
                Delete Account
              </h2>
            )}
          </div>

          <div className={"deleteContent edit-card"}>
            <p className="deleteContent__text fw-400 text-color text-gray_dark_2 text-center mb-30">
              After deleting, all your data will be irretrievably lost. If you
              are sure that you want to delete your Account, enter the fraze for
              <b> "deleting account".</b>
            </p>
            <div className="deleteContent__wrapper">
              <TextField
                id="keywords"
                name="keywords"
                label="deleting account"
                value={keywords}
                type={"text"}
                variant="outlined"
                onChange={handleOnChangeTextField}
                error={!!errors.keywords}
              />

              <figure className={styles.delete__warning}>
                <img src={warning} alt="Warning sign" />
                <figcaption className="text-gray_dark fw-600 text-center">
                  Are you sure want to&nbsp;delete your Account?
                </figcaption>
              </figure>

              <div className={`${styles.answers} delete__answers`}>
                <button
                  className={`${styles.answers__no} fs-16`}
                  onClick={handleOnBack}
                  aria-label={"No go back"}
                >
                  No
                </button>
                <button
                  onClick={handleDelete}
                  className={`${styles.answers__yes} text-error`}
                  disabled={disableBtn}
                  aria-label={"Yes delete my account"}
                >
                  Yes, delete
                </button>
              </div>
            </div>
          </div>
        </div>

        {width > 767 && (
          <div className="right_bar right_bar_preview-window">
            <h4 className="ff-gilroy fw-700 l-spacing text-center">Preview</h4>
            <div className="right_bar__container">
              <PreviewPageHome />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default DeleteAccount;

import AppleMusic from "../assets/svg/linksIcon/appleMusic.svg";
import ApplePodcasts from "../assets/svg/linksIcon/applePodcasts.svg";
import Bandcamp from "../assets/svg/linksIcon/bandcamp.svg";
import Behance from "../assets/svg/linksIcon/behance.svg";
import Buymeacoffee from "../assets/svg/linksIcon/buymeacoffee.svg";
import Clubhouse from "../assets/svg/linksIcon/clubhouse.svg";
import Discord from "../assets/svg/linksIcon/discord.svg";
import Dribbble from "../assets/svg/linksIcon/dribbble.svg";
import Etsy from "../assets/svg/linksIcon/etsy.svg";
import Email from "../assets/svg/linksIcon/email.svg";
import Facebook from "../assets/svg/linksIcon/facebook.svg";
import Github from "../assets/svg/linksIcon/github.svg";
import Instagram from "../assets/svg/linksIcon/instagram.svg";
import LinkedIn from "../assets/svg/linksIcon/linkedIN.svg";
import Medium from "../assets/svg/linksIcon/medium.svg";
import Patreon from "../assets/svg/linksIcon/patreon.svg";
import Pinterest from "../assets/svg/linksIcon/pinterest.svg";
import Reddit from "../assets/svg/linksIcon/reddit.svg";
import Shopify from "../assets/svg/linksIcon/shopify.svg";
import Snapchat from "../assets/svg/linksIcon/snapchat.svg";
import Soundcloud from "../assets/svg/linksIcon/soundcloud.svg";
import Spotify from "../assets/svg/linksIcon/spotify.svg";
import Substack from "../assets/svg/linksIcon/substack.svg";
import Telegram from "../assets/svg/linksIcon/telegram.svg";
import TikTok from "../assets/svg/linksIcon/tiktok.svg";
import Twitch from "../assets/svg/linksIcon/twitch.svg";
import Twitter from "../assets/svg/linksIcon/twitter.svg";
import Vimeo from "../assets/svg/linksIcon/vimeo.svg";
import WhatsApp from "../assets/svg/linksIcon/whatsApp.svg";
import YouTube from "../assets/svg/linksIcon/youTube.svg";
import Plug from "../assets/svg/warning--event.svg";
import Website from "../assets/svg/linksIcon/website.svg";
import CustomLink from "../assets/svg/linksIcon/custom_link.svg";
import AppStore from "../assets/svg/linksIcon/appStore.svg";
import GooglePlayMarket from "../assets/svg/linksIcon/googlePlayMarket.svg";

export const getRandomColor = () => {
  const letters = "0123456789ABCDEF";
  let color = "#";
  for (let i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }
  return color;
};

export const getIconLink = (name: string): string => {
  switch (name) {
    case "Apple Music":
      return AppleMusic;
    case "Apple Podcasts":
      return ApplePodcasts;
    case "Bandcamp":
      return Bandcamp;
    case "Behance":
      return Behance;
    case "Buymeacoffe":
      return Buymeacoffee;
    case "Clubhouse":
      return Clubhouse;
    case "Discord":
      return Discord;
    case "Dribbble":
      return Dribbble;
    case "Etsy":
      return Etsy;
    case "Email":
      return Email;
    case "Facebook":
      return Facebook;
    case "GitHub":
      return Github;
    case "Instagram":
      return Instagram;
    case "LinkedIn":
      return LinkedIn;
    case "Medium":
      return Medium;
    case "Patreon":
      return Patreon;
    case "Pinterest":
      return Pinterest;
    case "Reddit":
      return Reddit;
    case "Shopify":
      return Shopify;
    case "Snapchat":
      return Snapchat;
    case "SoundCloud":
      return Soundcloud;
    case "Spotify":
      return Spotify;
    case "Substrack":
      return Substack;
    case "Telegram":
      return Telegram;
    case "TikTok":
      return TikTok;
    case "Twitch":
      return Twitch;
    case "X":
      return Twitter;
    case "Vimeo":
      return Vimeo;
    case "WhatsApp":
      return WhatsApp;
    case "YouTube":
      return YouTube;
    case "Website":
      return Website;
    case "Custom Link":
      return CustomLink;
    case "App Store":
      return AppStore;
    case "Google Play Market":
      return GooglePlayMarket;
    default:
      return Plug;
  }
};

import React, { useState, useEffect } from "react";
import { useApi } from "../../../providers/api.provider";

import styles from "./DeleteOlink.module.scss";
import warning from "../../../assets/svg/warning.svg";
import { useNavigate, useParams } from "react-router-dom";
import { useUser } from "../../../providers/user.provider";
import { useWindowDimensions } from "../../../services/widthCheck.service";
import PreviewPageHome from "../../ui/PreviewPageHome/PreviewPageHome";
import moment from "moment";
import Banner from "../../ui/Banner/Banner";

export interface IPasswordErrors {
  password: null | string;
}

const DeleteOlink = () => {
  const { width } = useWindowDimensions();
  const navigate = useNavigate();
  const handleOnBack = async () => {
    navigate("/settings/details");
  };
  const { id } = useParams();
  const api = useApi();
  const { setLoading, userProfiles, setUserProfiles, setDelegates } = useUser();
  const user = useUser();

  const getValueForProfilesUser = async () => {
    if (user.user.id && user.userProfiles.length === 0) {
      await api.profiles.getProfiles(user.user.id).then((res) => {
        //, 1, 10
        setUserProfiles(res.data);
      });
    }
  };

  const getValueForDelegatesUser = async () => {
    if (user.user.id && user.delegates.length === 0) {
      await api.delegates.getDelegates(user.user.id).then((res) => {
        //, 1, 10
        setDelegates(res.data);
      });
    }
  };

  useEffect(() => {
    if (user.user.id) {
      getValueForProfilesUser();
    }
  }, [user.user.id, user.userProfiles?.length]);

  useEffect(() => {
    if (user.user.id) {
      getValueForDelegatesUser();
    }
  }, [user.user.id, user.delegates?.length]);

  const [errors, setErrors] = useState<IPasswordErrors>({
    password: null,
  });

  const handleDelete = async () => {
    setLoading(true);
    await api.user.deleteOlink(Number(id)).then(
      (item) => {
        const newProfiles = userProfiles?.data.filter((el: any) => {
          return el.id !== Number(id);
        });
        setUserProfiles((prevState: any) => ({
          ...prevState,
          data: newProfiles,
        }));

        api.user
          .me()
          .then((res: any) => {
            // console.log(res);
            if (res.profile) {
              user.setCurrentProfile(res.profile);
              user.setEvents([]);
              user.setListOlink([]);
              user.setInActiveEvents([]);
              user.setCurrentOLink([]);
              user.setProfileLinks([]);
              user.setSocialTypes([]);
              api.social.userSocials().then((res) => {
                user.setUserSocials(res);
              });
              api.link.getLinks().then((res) => {
                const newArray = [...res];
                const indexProlink = res.findIndex(
                  (el: any) => el.type === "prolink"
                );

                if (indexProlink !== -1) {
                  const prolinkElement = newArray.splice(indexProlink, 1)[0];
                  newArray.unshift(prolinkElement);
                }

                newArray.sort(
                  (itemOne, itemTwo) => itemOne.order - itemTwo.order
                );
                user.setProfileLinks(newArray);
              });
              user.setPersonalizate({
                themeName: res.profile.settings!.theme.themeName,
                color: {
                  background: res.profile.settings!.theme.color_background,
                  type: res.profile.settings!.theme.color_type,
                },
                btn: {
                  rounding: res.profile.settings!.theme.btn_rounding,
                  outline: res.profile.settings!.theme.btn_outline,
                  white: res.profile.settings!.theme.btn_white,
                  shadow: res.profile.settings!.theme.btn_shadow,
                },
                fontsName: res.profile.settings!.theme.fontsName,
                statusLogo: res.profile.settings!.theme.statusLogo,
              });

              if (
                res.profile.settings?.qrcode &&
                res.profile.settings?.qrcode.options
              ) {
                user.setQrCode(
                  JSON.parse(res.profile.settings!.qrcode.options)
                );
              } else {
                user.setQrCode({
                  width: 300,
                  height: 300,
                  image: "",
                  dotsOptions: {
                    type: "extra-rounded",
                  },
                  cornersSquareOptions: {
                    type: "none",
                  },
                  cornersDotOptions: {
                    type: "none",
                  },
                  imageOptions: {
                    crossOrigin: "anonymous",
                    imageSize: 0.5,

                    margin: 10,
                  },
                });
              }

              if (
                res.profile.settings?.qrcode.logo &&
                res.profile.settings?.qrcode.logo.url
              ) {
                user.setQrCode((prevState: any) => ({
                  ...prevState,
                  image: res.profile.settings!.qrcode.logo.url,
                }));
              } else {
                user.setQrCode((prevState: any) => ({
                  ...prevState,
                  image: null,
                }));
              }
            }
          })
          .catch(() => {});

        navigate("/olink/switch");
      },
      (err) => {
        const mess = err.response.data.message;
        if (mess) {
          switch (mess) {
            case "User with this nickname already exists":
              setErrors((prevState: IPasswordErrors) => {
                return { ...prevState, password: mess };
              });
              break;
          }
        }
      }
    );
    setLoading(false);
  };

  return (
    <div className="main-content">
      <div className={`${styles.delete} bg-gray_light`}>
        {width >= 768 && (
          <div className="page-title">
            <h2 className="text-center text-error">Delete OLink</h2>
          </div>
        )}
        <div className={`${styles.container} container`}>
          <Banner />
          <div className="page_top">
            <button
              type="button"
              className="btn_navigate btn_navigate_arrow"
              onClick={handleOnBack}
            >
              Back
            </button>
            {width < 768 && (
              <h2 className="text-center ff-gilroy text-error">Delete OLink</h2>
            )}
          </div>

          <div className={"deleteContent edit-card"}>
            <p className="deleteContent__text fw-400 text-color text-gray_dark_2 text-center mb-30">
              After deleting, all your data will be irretrievably lost. If you
              are sure that you want to delete your OLink, click the button
              <b>"Yes, delete"</b>
            </p>

            <div className="deleteContent__wrapper">
              <figure className={styles.delete__warning}>
                <img src={warning} alt="Warning sign" />
                <figcaption className="text-gray_dark fw-600 text-center">
                  Are you sure want to&nbsp;delete your OLink?
                </figcaption>
              </figure>

              <div className={`${styles.answers} delete__answers`}>
                <button
                  className={styles.answers__no}
                  onClick={handleOnBack}
                  aria-label={"No go back"}
                >
                  No
                </button>
                <button
                  onClick={handleDelete}
                  disabled={userProfiles.length === 1 ? true : false}
                  className={`${styles.answers__yes} text-error`}
                  aria-label={"Yes delete my Olink"}
                >
                  Yes, delete
                </button>
              </div>
            </div>
          </div>
        </div>

        {width > 767 && (
          <div className="right_bar right_bar_preview-window">
            <h4 className="ff-gilroy fw-700 l-spacing text-center">Preview</h4>
            <div className="right_bar__container">
              <PreviewPageHome />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default DeleteOlink;

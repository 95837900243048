import React, { useState } from "react";
import Avatar from "@mui/material/Avatar";
import Stack from "@mui/material/Stack";
import Switch from "@mui/material/Switch";
import FormControlLabel from "@mui/material/FormControlLabel";
import {Link, useNavigate} from "react-router-dom";

import styles from "./OlinkMyDelegated.module.scss";
import pen from "../../../assets/svg/pen--orange.svg";
import link from "../../../assets/svg/link.svg";
import arrow_right from "../../../assets/svg/arrow-right.svg";
import {useWindowDimensions} from "../../../services/widthCheck.service";

const OlinkMyDelegated = () => {
  const { width } = useWindowDimensions();
  const navigate = useNavigate();
  const handleOnBack = async () => {
    navigate(-1);
  };
  const [activeNotif, setActiveNotif] = useState(false);

  const switchHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    setActiveNotif(event.target.checked);
  };

  return (
    <div className="main-content">
      <div className={`${styles.delegate} bg-gray_light`}>
        <div className={`${styles.container} container`}>
          <div className="page_top">
            <button
              type="button"
              className="btn_navigate btn_navigate_arrow"
              onClick={handleOnBack}
            >
              Back
            </button>
            {width < 768 && (
              <h4 className="text-center ff-gilroy">Details</h4>
            )}
          </div>


          <div className={`${styles.delegate__user} mb-20`}>
            <Avatar className={styles.delegate__ava} alt="User" src="" />
            <h4 className={`${styles.delegate__name} ff-gilroy`}>
              Delegated OLink
            </h4>
            <p className={`${styles.delegate__text} small-text`}>
              onelink.com/ALevineSinger
            </p>
          </div>

          <div className={`${styles.card_section} card `}>
            <div
              className={`${styles.card_section__link} ${styles.card_section__link__label}`}
            >
              <img
                className={styles.card_section__icon}
                src={link}
                alt="icon"
              />
              <FormControlLabel
                control={
                  <Switch
                    checked={activeNotif}
                    onChange={(e) => switchHandler(e)}
                  />
                }
                label="Active"
                className="styled_label"
              />
            </div>
            <a className={styles.card_section__link} href="/">
              <img className={styles.card_section__icon} src={pen} alt="icon" />
              <button className={styles.card_section__btn}>Edit</button>
              <img
                src={arrow_right}
                alt="icon"
                className={styles.card_section__arrow}
              />
            </a>
          </div>
          <a href="/" className={styles.delegate__delete}>
            Waive Rights
          </a>
        </div>
      </div>
    </div>
  );
};

export default OlinkMyDelegated;

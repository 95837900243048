import React from "react";
import { useWindowDimensions } from "../../../services/widthCheck.service";
import Box from "@mui/material/Box";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import Avatar from "@mui/material/Avatar";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import Button from "@mui/material/Button";
import { Badge } from "@mui/material";
import share from "../../../assets/svg/share.svg";

import styles from "./Sidebar.module.scss";

import avaRound from "../../../assets/svg/ava-round.svg";
import settings from "../../../assets/svg/settings.svg";
import logOut from "../../../assets/svg/log-out.svg";
import calendar from "../../../assets/svg/calendar.svg";
import statistic from "../../../assets/svg/statistic.svg";
import diamant from "../../../assets/svg/diamant.svg";

import AvatarUser from "../Avatar/AvatarUser";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useUser } from "../../../providers/user.provider";
import LogoutDialog from "../Dialogs/LogoutDialog/LogoutDialog";
import { useApi } from "../../../providers/api.provider";
import ShareDialog from "../Dialogs/ShareDialog/ShareDialog";
import ShareSocialDialog from "../Dialogs/ShareSocialDialog/ShareSocialDialog";
import avaSquareIcon from "../../../assets/svg/ava-square.svg";
import style from "../../pages/DetailsProfile/DetailsProfile.module.scss";
import Popup from "reactjs-popup";

type Anchor = "left";

const Sidebar = React.memo(() => {
  const { width } = useWindowDimensions();
  let url = useLocation();
  const api = useApi();
  const user = useUser();
  const navigate = useNavigate();

  const [state, setState] = React.useState({
    left: false,
  });

  const [dialodLogout, setDialogLogout] = React.useState({
    open: false,
  });
  const [dialodShare, setDialogShare] = React.useState({
    open: false,
  });
  const [dialodSocialShare, setDialodSocialShare] = React.useState({
    open: false,
  });

  const toggleDrawer =
    (anchor: Anchor, open: boolean) =>
    (event: React.KeyboardEvent | React.MouseEvent) => {
      if (width >= 770) return;
      if (
        event &&
        event.type === "keydown" &&
        ((event as React.KeyboardEvent).key === "Tab" ||
          (event as React.KeyboardEvent).key === "Shift")
      ) {
        return;
      }

      setState({
        ...state,
        [anchor]: open,
      });
    };

  const toggleDialogLogout = (open: boolean) => () => {
    setDialogLogout({ open });
  };

  const openDialogShare = () => {
    setDialogShare({ open: true });
  };

  const openSocialDialogShare = () => {
    setDialodSocialShare({ open: true });
  };

  const closeDialogShare = () => {
    setDialogShare({ open: false });
  };

  const closeSocialDialogShare = () => {
    setDialodSocialShare({ open: false });
  };

  const logout = () => {
    setDialogLogout({ open: true });
  };

  const handleClick = (type: string, anchor: Anchor) => () => {
    switch (type) {
      case "logout":
        logout();
        break;
      case "settings":
        navigate("/settings/main");
        break;
    }
  };

  const list = (anchor: Anchor) => (
    <Box
      sx={{ width: anchor === "left" ? 300 : null }}
      role="presentation"
      className={styles.sidebar}
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <AvatarUser />
      <nav className={styles.sidebar__navigation}>
        <ul className={styles.sidebar__list} role={"menu"}>
          <li
            className={`${styles.sidebar__item} ${styles.has_inner} inner`}
            tabIndex={0}
            role={"none"}
          >
            <Link
              to="olink/my-tol"
              className={`${
                url.pathname === "/olink/my-tol" ? styles.active : ""

                // url.pathname.includes("/olink") &&
                // ![
                //   "/olink/statistic",
                //   "/olink/switch",
                //   "/olink/premium",
                // ].includes(url.pathname)
                // ? styles.active
                // : ""
              } ff-gilroy fw-600 icon`}
              // style={{
              //   pointerEvents: "none",
              //   // color: "gray",
              //   textDecoration: "none",
              // }}
              aria-haspopup={true}
              role={"menuitem"}
            >
              <ListItemIcon>
                <img
                  className={styles.sidebar__icon}
                  src={avaRound}
                  alt=""
                  width={24}
                  height={24}
                />
              </ListItemIcon>
              My TOL
            </Link>

            <ul className={styles.inner__list} role={"menu"}>
              <li className={`${styles.inner__item}`}>
                <Link
                  to="olink/edit"
                  className={`${
                    url.pathname === "/olink/edit" ? styles.active : ""
                  } ff-gilroy fw-600`}
                  role={"menuitem"}
                >
                  Profile
                </Link>
              </li>

              <li className={`${styles.inner__item}`}>
                <Link
                  to="olink/my"
                  className={`${
                    url.pathname === "/olink/my" ||
                    url.pathname === "/olink/add"
                      ? styles.active
                      : ""
                  } ff-gilroy fw-600`}
                  role={"menuitem"}
                >
                  Link Management
                </Link>
              </li>

              <li className={`${styles.inner__item}`}>
                <Link
                  to="olink/personalization"
                  className={`${
                    url.pathname === "/olink/personalization" ||
                    url.pathname === "/olink/create-qr"
                      ? styles.active
                      : ""
                  } ff-gilroy fw-600`}
                  role={"menuitem"}
                >
                  Personalisation
                </Link>
              </li>

              <li className={`${styles.inner__item}`}>
                <Link
                  to="olink/source"
                  className={`${
                    url.pathname === "/olink/source" ? styles.active : ""
                  } ff-gilroy fw-600`}
                  role={"menuitem"}
                >
                  QR code Management
                </Link>
              </li>
            </ul>
          </li>

          <li className={`${styles.sidebar__item} inner`} role={"none"}>
            <Link
              to="event/events"
              className={`${
                url.pathname.includes("/event") ? styles.active : ""
              } ff-gilroy fw-600 icon`}
              role={"menuitem"}
            >
              <div>
                <img
                  className={styles.sidebar__icon}
                  src={calendar}
                  alt=""
                  width={24}
                  height={24}
                />
              </div>
              Smart Redirects
            </Link>
          </li>

          <li className={`${styles.sidebar__item} inner`} role={"none"}>
            <Link
              to="olink/statistic"
              className={`${
                url.pathname === "/olink/statistic" ? styles.active : ""
              } ff-gilroy fw-600 icon`}
              role={"menuitem"}
              // delete after QA tests
              // tabIndex={-1}
            >
              <div>
                <img
                  className={styles.sidebar__icon}
                  src={statistic}
                  alt=""
                  width={24}
                  height={24}
                />
              </div>
              <span>Statistics</span>
              {/*delete after QA tests*/}
              <span style={{ marginLeft: "auto" }} className={`premium-color`}>
                Beta
              </span>
            </Link>
          </li>

          {/* <li className={`${styles.inner__item} inner`} role={"none"}>
            <a
              href="#"
              className="ff-gilroy fw-600"
              onClick={openDialogShare}
              role={"menuitem"}
              aria-label={"Share or copy your Olinks"}
            >
              Share
            </a>
          </li> */}

          <li className={`${styles.sidebar__item} inner`} role={"none"}>
            <Link
              to="#"
              className={`ff-gilroy fw-600 icon`}
              role={"menuitem"}
              onClick={openDialogShare}
            >
              <div>
                <img
                  className={styles.sidebar__icon}
                  src={share}
                  alt=""
                  width={24}
                  height={24}
                />
              </div>
              Share
            </Link>
          </li>
        </ul>
      </nav>

      {user?.user?.access === "regular" ? ( // || user?.user?.access === "premium"
        <div className={styles.sidebar__btnWrapper}>
          <Link
            // hidden
            // style={{ display: "none" }}
            // to="https://yelbaiev.gumroad.com/l/tol"
            to="olink/premium"
            // target="_blank"
            className={`${styles.sidebar__btnPremium} btn__premium btn__premium_small`}
          >
            Go Premium
          </Link>
        </div>
      ) : user?.user?.access === "pro" ? (
        <div className={styles.sidebar__message}>
          <div className={styles.sidebar__message__title}>
            <img
              className={styles.sidebar__icon}
              src={diamant}
              alt=""
              width={32}
              height={32}
            />
            <span>You are currently on a Pro plan</span>
          </div>
          <Link to="olink/premium" className={styles.sidebar__message__link}>
            View other Plans
          </Link>
        </div>
      ) : (
        ""
      )}
      <Box className={styles.controls}>
        <button
          type="button"
          className="controls__log-out icon text-gray_dark_2 ff-gilroy fw-400"
          onClick={handleClick("logout", anchor)}
        >
          <img
            className={styles.sidebar__icon}
            src={logOut}
            alt=""
            width={24}
            height={24}
          />
          Log Out
        </button>
        <button
          type="button"
          className={`${
            url.pathname.includes("/settings") &&
            !"/settings/notifications".includes(url.pathname)
              ? styles.active
              : ""
          } 
          controls__settings icon text-gray_dark_2 ff-gilroy fw-400`}
          onClick={handleClick("settings", anchor)}
          aria-label={"Go to the Settings page"}
        >
          <img
            className={styles.sidebar__icon}
            src={settings}
            alt=""
            width={20}
            height={20}
          />
          Settings
        </button>
      </Box>
    </Box>
  );

  function handleLogout() {
    api.auth.logout();
    user.setPreviousAccessToken(true);
    user.setUser({
      id: null,
      created_at: null,
      updated_at: null,
      firstName: null,
      lastName: null,
      email: null,
      confirmed: null,
      isActive: null,
      role: null,
      selectedProfile: null,
    });
    user.setCurrentProfile({
      id: null,
      created_at: null,
      updated_at: null,
      fullName: null,
      nickname: null,
      location: null,
      avatar: null,
      olinks: null,
      isActive: null,
      title: null,
      description: null,
      events: null,
      interest: null,
      links: null,
      settings: null,
      olink: null,
      delegates: [],
      userSocials: [],
    });
    user.setNotifications([]);
    user.setEvents([]);
    user.setListOlink([]);
    user.setUserProfiles([]);
    user.setCurrentOLink([]);
    user.setProfileLinks([]);
    user.setInActiveEvents([]);
    user.setDelegates([]);
    user.setQrCode({
      width: 140,
      height: 140,
      image: "",
      dotsOptions: {
        color: "#000",
        type: "extra-rounded",
      },
      cornersSquareOptions: {
        type: "none",
        color: "#000",
      },
      cornersDotOptions: {
        type: "none",
        color: "#000",
      },
      imageOptions: {
        crossOrigin: "anonymous",
        imageSize: 0.5,
        margin: 10,
      },
      backgroundOptions: {
        color: "#fff",
      },
    });
    user.setPersonalizate({
      themeName: "default",
      color: { background: "", type: "" },
      btn: {
        rounding: false,
        outline: false,
        white: false,
        shadow: false,
      },
      fontsName: null,
      statusLogo: true,
    });
  }

  return (
    <div>
      {(["left"] as const).map((anchor) => (
        <React.Fragment key={anchor}>
          {width < 768 && (
            <Button onClick={toggleDrawer(anchor, true)}>
              <Avatar
                alt="User"
                srcSet={
                  user.currentProfile.avatar
                    ? user.currentProfile.avatar.url
                    : ""
                }
              />
            </Button>
          )}
          <SwipeableDrawer
            anchor={anchor}
            open={width >= 768 ? true : state[anchor]}
            onClose={toggleDrawer(anchor, false)}
            onOpen={toggleDrawer(anchor, true)}
            hideBackdrop={width >= 768}
            variant={width >= 768 ? "permanent" : "temporary"}
            // sx={{
            //   "& .MuiDrawer-paper": {
            //     marginTop: width >= 768 && user.banner ? "63px" : "0",
            //     height:
            //       width >= 768 && user.banner ? "calc(100vh - 63px)" : "100dvh",
            //     // overflowY: "auto",
            //   },
            // }}
          >
            {list(anchor)}
          </SwipeableDrawer>
          <LogoutDialog
            open={dialodLogout.open}
            onClose={toggleDialogLogout(false)}
            onLogout={handleLogout}
          />
          <ShareDialog
            open={dialodShare.open}
            onClose={closeDialogShare}
            onShare={openSocialDialogShare}
          />
          <ShareSocialDialog
            open={dialodSocialShare.open}
            onClose={closeSocialDialogShare}
          />
        </React.Fragment>
      ))}
    </div>
  );
});

export default Sidebar;

import { ChangeEvent, useEffect, useState } from "react";
import { TextField, InputAdornment, IconButton } from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import style from "./ChangePassword.module.scss";
import saveIcon from "../../../assets/svg/save.svg";

import { useApi } from "../../../providers/api.provider";
import { useUser } from "../../../providers/user.provider";
import { passwordLength } from "../../../constants/passwords.const";
import { useGuest } from "../../../providers/guest.provider";
import { ESnackBarType } from "../../ui/SnackBar/SnackBar";

const ChangePassword = () => {
  const api = useApi();
  const { setLoading } = useUser();
  const { setActiveSnackBar } = useGuest();
  const [stateDisabled, setStateDisabled] = useState<boolean>(true);
  const [showOldPassword, setShowOldPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showRepeatPassword, setShowRepeatPassword] = useState(false);
  const [changePasswordForm, setChangePasswordForm] = useState({
    newPassword: "",
    repeatPassword: "",
    oldPassword: "",
  });

  const [errors, setErrors] = useState({
    newPassword: "",
    repeatPassword: "",
    oldPassword: "",
  });

  const handleClickNewPassword = () => setShowNewPassword(!showNewPassword);
  const handleMouseNewPassword = () => setShowNewPassword(!showNewPassword);

  const handleClickShowRepeatPassword = () =>
    setShowRepeatPassword(!showRepeatPassword);
  const handleMouseDownRepeatPassword = () =>
    setShowRepeatPassword(!showRepeatPassword);

  const handleClickShowOldPassword = () => setShowOldPassword(!showOldPassword);
  const handleMouseDownOldPassword = () => setShowOldPassword(!showOldPassword);

  const handleClickUpdatePassword = async () => {
    if (validateForm(changePasswordForm)) {
      setLoading(true);
      await api.user
        .updatePassword(changePasswordForm)
        .then((res) => {
          clearForm();
          setActiveSnackBar({
            state: true,
            name: ESnackBarType.success,
            message: "Successfully changed",
          });
        })
        .catch((res) => {
          setLoading(false);
          // TODO - Need to modify error handling
          let message = "Error occurred";

          if (
            res.response?.status === 403 &&
            res.response?.data?.response?.toLowerCase() === "wrong password"
          ) {
            message = "Old password is wrong";
          }

          setActiveSnackBar({
            state: true,
            name: ESnackBarType.error,
            message,
          });
        });
      setLoading(false);
    }
  };

  const handleChangePassword = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const value = event.target.value.trim();
    let form = { ...changePasswordForm };
    switch (event.target.id) {
      case "oldPassword":
        form.oldPassword = value;
        break;
      case "newPassword":
        form.newPassword = value;
        break;
      case "repeatPassword":
        form.repeatPassword = value;
        break;
    }
    setChangePasswordForm(form);
    validateForm(form);
  };

  useEffect(() => {
    if (
      changePasswordForm.oldPassword &&
      changePasswordForm.newPassword &&
      changePasswordForm.repeatPassword &&
      !checkFormHasErrors(errors)
    ) {
      setStateDisabled(false);
    } else {
      setStateDisabled(true);
    }
  }, [
    changePasswordForm.oldPassword,
    changePasswordForm.newPassword,
    changePasswordForm.repeatPassword,
  ]);

  const checkFormHasErrors = (obj: any) => {
    let arr = [];
    for (let key of Object.keys(obj)) {
      arr.push(obj[key]);
    }
    return arr.findIndex((el) => el.length > 0) !== -1;
  };

  const validateForm = (form: any): any => {
    const errs = { ...errors };
    let res = true;

    if (!form.oldPassword || form.oldPassword.length === 0) {
      errs.oldPassword = "Password is required!";
      res = false;
    } else if (form.oldPassword.length < passwordLength) {
      errs.oldPassword = `Minimum number of characters ${passwordLength}`;
      res = false;
    } else {
      errs.oldPassword = "";
    }

    if (!form.newPassword || form.newPassword.length === 0) {
      errs.newPassword = "Password is required!";
      res = false;
    } else if (form.newPassword.length < passwordLength) {
      errs.newPassword = `Minimum number of characters ${passwordLength}`;
      res = false;
    } else {
      errs.newPassword = "";
    }

    if (!form.repeatPassword || form.repeatPassword.length === 0) {
      errs.repeatPassword = "Password is required!";
      res = false;
    } else {
      if (form.repeatPassword.length < passwordLength) {
        errs.repeatPassword = `Minimum number of characters ${passwordLength}`;
        res = false;
      } else {
        errs.repeatPassword = "";
      }
    }

    if (
      form.newPassword &&
      form.repeatPassword &&
      form.repeatPassword.length >= passwordLength &&
      form.newPassword.length >= passwordLength
    ) {
      if (form.newPassword !== form.repeatPassword) {
        errs.newPassword = "Password mismatch!";
        errs.repeatPassword = "Password mismatch!";
        res = false;
      } else {
        errs.newPassword = "";
        errs.repeatPassword = "";
      }
    }
    setErrors(errs);
    return res;
  };

  const clearForm = () => {
    setChangePasswordForm({
      ...changePasswordForm,
      oldPassword: "",
      newPassword: "",
      repeatPassword: "",
    });
  };

  return (
    <>
      {/* <div className="main-content"> */}
      <section className={`bg-gray_light account-edit`}>
        {/* {width >= 768 && (
          <div className="page-title">
            <h2 className="text-center text-black">Change Password</h2>
          </div>
        )} */}
        <div className={`${style.container}`}>
          {/* <div className="page_top">
            {width < 768 ? (
              <h2 className="text-black text-center ff-gilroy">
                Change Password
              </h2>
            ) : null}
          </div> */}
          <div className={`${style.acc_edit__wrapper}  edit-card`}>
            <h2
              className="text-center ff-gilroy"
              style={{ marginBottom: 20, fontSize: "1.3rem" }}
            >
              Change Password
            </h2>
            <div className={"mb-10 flex-1"}>
              <TextField
                id="oldPassword"
                name="oldPassword"
                label="Old password"
                value={changePasswordForm.oldPassword}
                helperText={errors.oldPassword}
                error={!!errors.oldPassword}
                onChange={handleChangePassword}
                // onBlur={handleBlurEvent}
                type={showOldPassword ? "text" : "password"}
                variant="outlined"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowOldPassword}
                        onMouseDown={handleMouseDownOldPassword}
                        edge="end"
                      >
                        {showOldPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              <TextField
                id="newPassword"
                name="newPassword"
                label="New password"
                value={changePasswordForm.newPassword}
                onChange={handleChangePassword}
                type={showNewPassword ? "text" : "password"}
                helperText={errors.newPassword}
                error={!!errors.newPassword}
                variant="outlined"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickNewPassword}
                        onMouseDown={handleMouseNewPassword}
                        edge="end"
                      >
                        {showNewPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              <TextField
                id="repeatPassword"
                name="repeatPassword"
                label="Repeat Password"
                helperText={errors.repeatPassword}
                error={!!errors.repeatPassword}
                value={changePasswordForm.repeatPassword}
                type={showRepeatPassword ? "text" : "password"}
                onChange={handleChangePassword}
                variant="outlined"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowRepeatPassword}
                        onMouseDown={handleMouseDownRepeatPassword}
                        edge="end"
                      >
                        {showRepeatPassword ? (
                          <VisibilityOff />
                        ) : (
                          <Visibility />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </div>
            <button
              type={"submit"}
              className={"btn icon icon__center btn_desktop"}
              disabled={stateDisabled}
              onClick={handleClickUpdatePassword}
            >
              <img
                src={saveIcon}
                alt="save"
                aria-hidden="true"
                role="presentation"
              />
              Save
            </button>
          </div>
        </div>
      </section>
      {/* </div> */}
    </>
  );
};

export default ChangePassword;

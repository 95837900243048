import {
  useEffect,
  forwardRef,
  useRef,
  useState,
  useImperativeHandle,
} from "react";
import QRCodeStyling from "../../../utilities/qr-code-styling/QRCodeStyling";
import styles from "./QRCode.module.scss";
import loader from "../../../assets/svg/loader.gif";

/* 
  TODO and Note:
  Note - QRCodeStyling tooks from npm lib qr-code-styling because there was an issue on some mobile devices
  that logo is not displaying on QR code. We didn't figure out where issue comes (looks like lib is not loading image)
  so for test (as quick solution) we fully copied code from that lib and cut some points there. In future the best
  way to fork from there github source and create own lib
*/

const QRCode = forwardRef((props: any, childComponentRef: any) => {
  const initialQrCode = {
    width: 300,
    height: 300,
    type: "svg",
    dotsOptions: {
      color: "#000",
      type: "square",
    },
    cornersSquareOptions: {
      type: "square",
      color: "#000",
    },
    cornersDotOptions: {
      type: "square",
      color: "#000",
    },
    imageOptions: {
      crossOrigin: "anonymous",
      imageSize: 0.5,
      margin: 10,
    },
  };

  const [loading, setLoading] = useState(false);
  const [qrCode, setQrCode] = useState<QRCodeStyling | null>(null);

  const ref = useRef(null);

  useEffect(() => {
    if (qrCode === null) {
      setLoading(true);
      let qrCodeData = props.data ? props.data : initialQrCode;
      qrCodeData.data = props.url;
      setQrCode(new QRCodeStyling(qrCodeData));
    } else {
      if (props.enableUpdate && props.data) {
        let qrCodeData = props.data;
        qrCode.update({ ...qrCodeData });
      }
    }
  }, [props.data]);

  useEffect(() => {
    if (qrCode !== null) {
      fetchData()
        .then(() => setLoading(false))
        .catch((err) => {});
    }
  }, [qrCode]);

  const fetchData = async () => {
    setLoading(true);
    if (ref.current !== null) {
      qrCode?.append(ref.current);
      await new Promise((resolve) => setTimeout(resolve, 1500));
    }
    setLoading(false);
  };

  useEffect(() => {
    if (!!props.loaded) props.loaded(loading);
  }, [loading]);

  useImperativeHandle(childComponentRef, () => ({
    getQRCodeInfo: () => {
      return {
        url: props.url,
        data: props.data,
      };
    },
  }));

  return (
    <>
      <div ref={childComponentRef} className={styles.qrWrapper}>
        {loading ? (
          <div className={"loader loader_md loader_active"}>
            <figure>
              <img src={loader} alt="loader gif" />
            </figure>
          </div>
        ) : (
          <></>
        )}
        <div className="qr_code" ref={ref} />
      </div>
    </>
  );
});

export default QRCode;

import React from 'react';

export namespace Icons {

  export const ProLink = () => (
    <svg width="32" height="36" viewBox="0 0 32 36" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M1.91209 10.8824C1.50525 8.58511 3.01374 6.38792 5.30376 5.94225L6.14358 5.77881L6.45217 24.001C6.51028 27.4323 9.31402 30.1851 12.7475 30.1819L29.4115 30.1664C29.2269 30.5576 28.8614 30.8561 28.4026 30.9366L10.3708 34.1024C8.04263 34.5111 5.82112 32.9556 5.40892 30.628L1.91209 10.8824Z" stroke="white" stroke-width="2"/>
        <path d="M7 26.3288L11.1178 21.1383C11.9187 20.1288 13.4292 20.0656 14.3116 21.0049L15.4759 22.2441C16.4475 23.2783 18.1421 23.0793 18.8476 21.8481L21.6022 17.0407C22.3931 15.6605 24.3656 15.6145 25.2134 16.9605C27.442 20.4986 30.8829 25.9885 30.9918 26.3288C31.1532 26.8332 28.1454 29 28.1454 29H8.35698L7 26.3288Z" fill="white"/>
        <rect x="7.02441" y="1" width="23.976" height="28.507" rx="4.28" stroke="white" stroke-width="2"/>
        <path d="M13.509 13.9457C14.7247 13.9457 15.7103 12.9701 15.7103 11.7666C15.7103 10.5631 14.7247 9.58752 13.509 9.58752C12.2932 9.58752 11.3076 10.5631 11.3076 11.7666C11.3076 12.9701 12.2932 13.9457 13.509 13.9457Z" fill="white"/>
    </svg>

)

  export const Video = () => (
      <svg
          width="32"
          height="32"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M25 6H7C4.79086 6 3 7.79086 3 10V22C3 24.2091 4.79086 26 7 26H25C27.2091 26 29 24.2091 29 22V10C29 7.79086 27.2091 6 25 6Z"  stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M12.6205 20.64V12.24C12.6163 12.0981 12.6509 11.9579 12.7205 11.8343C12.7901 11.7106 12.8921 11.6083 13.0156 11.5384C13.139 11.4684 13.2792 11.4335 13.421 11.4373C13.5628 11.4411 13.7009 11.4835 13.8205 11.56L21.0205 15.76C21.1408 15.8305 21.2405 15.9314 21.3098 16.0524C21.3791 16.1734 21.4156 16.3105 21.4156 16.45C21.4156 16.5894 21.3791 16.7265 21.3098 16.8475C21.2405 16.9686 21.1408 17.0694 21.0205 17.14L13.8205 21.34C13.6983 21.4105 13.5597 21.4475 13.4187 21.4472C13.2776 21.4468 13.1392 21.4092 13.0173 21.3382C12.8955 21.2671 12.7946 21.1651 12.7249 21.0425C12.6552 20.9199 12.6192 20.781 12.6205 20.64V20.64Z"  stroke-width="1.6" stroke-linecap="round" stroke-linejoin="round"/>
      </svg>
  )

  export const Audio = () => (
      <svg
          width="32"
          height="32"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M10.5 29C13.2614 29 15.5 26.7614 15.5 24C15.5 21.2386 13.2614 19 10.5 19C7.73858 19 5.5 21.2386 5.5 24C5.5 26.7614 7.73858 29 10.5 29Z"  stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M15.5 24V3C16.9445 3 18.3749 3.28452 19.7095 3.83733C21.0441 4.39013 22.2567 5.20039 23.2782 6.22183C24.2996 7.24328 25.1099 8.45591 25.6627 9.79049C26.2155 11.1251 26.5 12.5555 26.5 14V14"  stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
      </svg>
  )

}
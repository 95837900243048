import React, { useEffect } from "react";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import { Helmet, HelmetProvider } from "react-helmet-async";
/*** Routes ***/
import Auth from "./components/routes/Auth/Auth.lazy";
import Olink from "./components/routes/Olink/Olink.lazy";
import Event from "./components/routes/Event/Event.lazy";
import Settings from "./components/routes/Settings/Settings";
import PrivateRoute from "./components/routes/PrivateRoute/PrivateRoute";
import PublicRoute from "./components/routes/PublicRoute/PublicRoute";
/*** Components ***/
import Header from "./components/ui/Header/Header";
import { UserProvider } from "./providers/user.provider";
import { ApiProvider } from "./providers/api.provider";
import NotFound from "./components/pages/NotFound/NotFound";
import { GuestProvider } from "./providers/guest.provider";
import SnackBar from "../src/components/ui/SnackBar/SnackBar";
import Loading from "./components/ui/Loading/Loading";
import OlinkGuestPage from "./components/pages/OlinkGuestPage/OlinkGuestPage";
import PreviewPageHome from "./components/ui/PreviewPageHome/PreviewPageHome";
import { GoogleOAuthProvider } from "@react-oauth/google";
import TermsConditions from "./components/pages/TermsConditions/TermsConditions";
import PrivacyPolicy from "./components/pages/PrivacyPolicy/PrivacyPolicy";
import posthog from "posthog-js";
import { PostHogProvider } from "posthog-js/react";
import PaymentDialog from "./components/ui/Dialogs/PaymentDialog/PaymentDialog";

posthog.init(process.env.REACT_APP_PUBLIC_POSTHOG_KEY as string, {
  api_host: process.env.REACT_APP_PUBLIC_POSTHOG_HOST,
});

const OwnerOLink = (
  props: JSX.IntrinsicAttributes & { children?: React.ReactNode }
) => {
  const REACT_APP_GOOGLE_ID_LOGIN = process.env.REACT_APP_GOOGLE_ID_LOGIN;
  const location = useLocation();
  const noIndexPaths = [
    "/auth/signin",
    "/auth/signup",
    "/auth/code",
    "/auth/forgot",
    "/settings/terms-conditions",
    "/settings/privacy-policy",
  ];
  const isNoIndex = noIndexPaths.includes(location.pathname);

  useEffect(() => {
    const metaTag = document.querySelector('meta[name="robots"]');
    if (metaTag) {
      document.head.insertBefore(metaTag, document.head.firstChild);
    }
  }, [isNoIndex, location]);

  return (
    <HelmetProvider>
      <PostHogProvider client={posthog}>
        <GoogleOAuthProvider clientId={`${REACT_APP_GOOGLE_ID_LOGIN}`}>
          <ApiProvider>
            <UserProvider>
              <Helmet>
                {!isNoIndex && (
                  <meta
                    name="robots"
                    content="noindex"
                    data-react-helmet="true"
                  />
                )}
              </Helmet>
              <Header />
              <Routes>
                <Route
                  path="auth/*"
                  element={
                    <PublicRoute>
                      <Auth />
                    </PublicRoute>
                  }
                />
                <Route
                  path="olink/*"
                  element={
                    <PrivateRoute>
                      <Olink />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="event/*"
                  element={
                    <PrivateRoute>
                      <Event />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="settings/*"
                  element={
                    <PrivateRoute>
                      <Settings />
                    </PrivateRoute>
                  }
                />
                <Route path="*" element={<Navigate to="/olink/my" />} />
              </Routes>
              <PaymentDialog />
            </UserProvider>
          </ApiProvider>
        </GoogleOAuthProvider>
      </PostHogProvider>
    </HelmetProvider>
  );
};

const App = () => {
  return (
    <GuestProvider>
      <Routes>
        {/* <Route path="/" element={<MainPage />} /> */}
        <Route path="/*" element={<OwnerOLink />} />
        <Route path="/:id" element={<OlinkGuestPage />} />
        <Route path="/404" element={<NotFound />} />
        <Route path="/terms-conditions" element={<TermsConditions />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
      </Routes>
      <SnackBar />
      <Loading />
    </GuestProvider>
  );
};

export default App;

export const setStyles = (profile: any) => {
  const root = document.body;
  const colors = profile;
  if (profile?.themeName !== "custom") {
    root.removeAttribute("style");
  } else {
    root.removeAttribute("style");
    [colors].map((item) => {
      if (item.color_type === "flat" || item.color?.type === "flat") {
        root.style.setProperty(
          "--main-bg",
          item.color_type ? item.color_background : item.color.background
        );
      }
      if (item.color_type === "gradient" || item.color?.type === "gradient") {
        root.style.setProperty(
          "--main-bg",
          `linear-gradient(${
            item.color_type ? item.color_background : item.color.background
          })`
        );
      }
      if (item.btn_rounding || item.btn?.rounding) {
        root.style.setProperty("--brdr-radius", "16px");
      }
      if (item.btn_outline || item.btn?.outline) {
        root.style.setProperty("--tiles-bg", "transparent");
        root.style.setProperty("--tiles-haeder-clr", "#2e2e2e");
        root.style.setProperty("--tiles-text-clr", "#2e2e2e");
        root.style.setProperty("--brdr-clr", "#2e2e2e");
        root.style.setProperty("--divider-clr", "#2e2e2e");
        root.style.setProperty("--tiles-icon-clr", "#2e2e2e");
        root.style.setProperty("--chckbx-text-clr", "#2e2e2e");
        root.style.setProperty("--media-type-bg-clr", "transparent");
        root.style.setProperty("--input-clr", "#2e2e2e");
        root.style.setProperty("--input-border-clr", "#2e2e2e");
      }
      if (item.btn_white || item.btn?.white) {
        root.style.setProperty("--name-clr", "#ffffff");
        root.style.setProperty("--interest-clr", "#ffffff");
        root.style.setProperty("--location-clr", "#ffffff");
        root.style.setProperty("--tiles-bg", "#ffffff");
        root.style.setProperty("--brdr-clr", "transparent");
        root.style.setProperty("--tiles-haeder-clr", "#2e2e2e");
        root.style.setProperty("--tiles-text-clr", "#2e2e2e");
        root.style.setProperty("--add-bg-clr", "transparent");
        root.style.setProperty("--add-brdr-clr", "#ffffff");
        root.style.setProperty("--add-text-clr", "#ffffff");
        root.style.setProperty("--divider-clr", "#eaeaea");
        root.style.setProperty("--chckbx-text-clr", "#585858");
        root.style.setProperty("--tiles-icon-clr", "#585858");
        root.style.setProperty("--social-icons-clr", "#2e2e2e");
        // root.style.setProperty("--inputs", "#2e2e2e");
        root.style.setProperty("--media-type-bg-clr", "#eaeaea");
        root.style.setProperty("--input-clr", "#2e2e2e");
        root.style.setProperty("--input-border-clr", "#eaeaea");
        root.style.setProperty("--cbox-bg-clr-unchecked", "#f5f5f5");
        root.style.setProperty("--cbox-thumb-bg-clr-checked", "#fff");
        root.style.setProperty("--cbox-thumb-bg-clr-unchecked", "#585858");
        root.style.setProperty("--cbox-border-clr-unchecked", "#eaeaea");
      }
      if (item.btn_shadow || item.btn?.shadow) {
        root.style.setProperty(
          "--box-shadow",
          "0px 6px 20px -3px rgba(88, 88, 88, 0.15), 0px 4px 8px -4px rgba(88, 88, 88, 0.1)"
        );
      }
      if (
        (item.btn_white && item.btn_outline) ||
        (item.btn?.white && item.btn?.outline)
      ) {
        root.style.setProperty("--tiles-bg", "transparent");
        root.style.setProperty("--brdr-clr", "#2e2e2e");
        root.style.setProperty("--tiles-haeder-clr", "#ffffff");
        root.style.setProperty("--tiles-text-clr", "#ffffff");
        root.style.setProperty("--brdr-clr", "#ffffff");
        root.style.setProperty("--divider-clr", "#ffffff");
        root.style.setProperty("--tiles-icon-clr", "#ffffff");
        root.style.setProperty("--chckbx-text-clr", "#ffffff");
        root.style.setProperty("--social-icons-clr", "#ffffff");
        root.style.setProperty("--media-type-bg-clr", "transparent");
        root.style.setProperty("--input-clr", "#ffffff");
        root.style.setProperty("--input-border-clr", "#ffffff");
      }
      switch (item.fontsName) {
        case "gilroy":
          root.style.setProperty("--f-family", "Gilroy, sans-serif");
          break;
        case "arial":
          root.style.setProperty("--f-family", "Arial, sans-serif");
          break;
        case "comic":
          root.style.setProperty("--f-family", "Comic Sans MS, cursive");
          break;
        case "times":
          root.style.setProperty("--f-family", "Times New Roman, sarif");
          break;
        default:
          break;
      }
    });
  }
};

import React, { ReactChild, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import style from "./SettingsMain.module.scss";

import ava from "../../../assets/svg/ava-round.svg";
import tiles from "../../../assets/svg/tiles.svg";
import doc from "../../../assets/svg/doc.svg";
import info from "../../../assets/svg/info.svg";
import letter from "../../../assets/svg/letter.svg";
import questionIcon from "../../../assets/svg/question-icon.svg";

import keyIcon from "../../../assets/svg/key-icon.svg";
import { useWindowDimensions } from "../../../services/widthCheck.service";
import PreviewPageHome from "../../ui/PreviewPageHome/PreviewPageHome";
import Banner from "../../ui/Banner/Banner";

const SettingsMain = () => {
  const navigate = useNavigate();
  const [premium, setPremium] = useState(false); // true or false check button "Go premium"
  const [activeNotif, setActiveNotif] = useState(false);
  const { width } = useWindowDimensions();

  const switchHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    setActiveNotif(event.target.checked);
  };

  return (
    <div className="main-content">
      <div className={`${style.acc_settings} bg-gray_light`}>
        {width >= 768 && (
          <div className="page-title">
            <h2 className="text-center text-black">Settings</h2>
          </div>
        )}
        <div className={`${style.container} container`}>
          <Banner />
          {width < 768 ? (
            <div className="page_top">
              <h2 className="text-center ff-gilroy text-black">Settings</h2>
            </div>
          ) : null}

          <div className={style.acc_settings__wrapper}>
            {/* hidden */}
            {width < 768 && (
              <>
                {premium ? (
                  <Link
                    to="/olink/premium"
                    className="btn__premium btn__premium--active mb-20"
                  >
                    <span>Subscription Info</span>
                  </Link>
                ) : (
                  <Link to="/olink/premium" className={`btn__premium mb-20`}>
                    Go Premium
                  </Link>
                )}
              </>
            )}

            <ul className={style.card_section} role={"menu"}>
              <li role={"none"}>
                <Link
                  to="/settings/account-edit"
                  className={style.card_section__link}
                  role={"menuitem"}
                >
                  <img
                    className={style.card_section__icon}
                    src={ava}
                    width={24}
                    height={24}
                    alt=""
                    role={"presentation"}
                  />
                  <span
                    className={`${style.card_section__title} text_overflow `}
                  >
                    Account
                  </span>
                </Link>
              </li>
              {/*<li role={"none"}>*/}
              {/*  <Link*/}
              {/*    to="/settings/management"*/}
              {/*    className={style.card_section__link}*/}
              {/*    role={"menuitem"}*/}
              {/*  >*/}
              {/*    <img*/}
              {/*      className={style.card_section__icon}*/}
              {/*      src={tiles}*/}
              {/*      width={24}*/}
              {/*      height={24}*/}
              {/*      alt=""*/}
              {/*      role={"presentation"}*/}
              {/*    />*/}
              {/*    <span*/}
              {/*      className={`${style.card_section__title} text_overflow `}*/}
              {/*    >*/}
              {/*      OLinkManagement*/}
              {/*    </span>*/}
              {/*  </Link>*/}
              {/*</li>*/}

              <li role={"none"}>
                <Link
                  to="/settings/newsletter"
                  className={`${style.card_section__link} ${style.soon}`}
                  role={"menuitem"}
                  tabIndex={-1}
                >
                  <img
                    className={style.card_section__icon}
                    src={letter}
                    width={24}
                    height={24}
                    alt=""
                    role={"presentation"}
                  />
                  <span
                    className={`${style.card_section__title} text_overflow `}
                  >
                    Newsletter
                  </span>
                  {/*delete after QA tests*/}
                  <span
                    style={{ marginLeft: "auto" }}
                    className={`${style.soon__text} premium-color`}
                  >
                    Soon
                  </span>
                </Link>
              </li>
              <li role={"none"}>
                <Link
                  to="/olink/premium"
                  className={style.card_section__link}
                  role={"menuitem"}
                >
                  <img
                    className={style.card_section__icon}
                    src={ava}
                    width={24}
                    height={24}
                    alt=""
                    role={"presentation"}
                  />
                  <span
                    className={`${style.card_section__title} text_overflow `}
                  >
                    Plans
                  </span>
                </Link>
              </li>

              {/*<li role={"none"}>*/}
              {/*  <Link*/}
              {/*    to="/settings/change-password"*/}
              {/*    className={style.card_section__link}*/}
              {/*    role={"menuitem"}*/}
              {/*  >*/}
              {/*    <img*/}
              {/*      className={style.card_section__icon}*/}
              {/*      src={keyIcon}*/}
              {/*      width={24}*/}
              {/*      height={24}*/}
              {/*      alt=""*/}
              {/*      role={"presentation"}*/}
              {/*    />*/}
              {/*    <span*/}
              {/*      className={`${style.card_section__title} text_overflow `}*/}
              {/*    >*/}
              {/*      Change Password*/}
              {/*    </span>*/}
              {/*  </Link>*/}
              {/*</li>*/}
            </ul>

            <ul className={style.card_section} role={"menu"}>
              <li role={"none"}>
                <Link
                  to="/settings/terms-conditions"
                  className={style.card_section__link}
                  role={"menuitem"}
                >
                  <img
                    className={style.card_section__icon}
                    src={doc}
                    width={24}
                    height={24}
                    alt=""
                    role={"presentation"}
                  />
                  <span
                    className={`${style.card_section__title} text_overflow`}
                  >
                    Terms & Conditions
                  </span>
                </Link>
              </li>

              <li role={"none"}>
                <Link
                  to="/settings/privacy-policy"
                  className={style.card_section__link}
                  role={"menuitem"}
                >
                  <img
                    className={style.card_section__icon}
                    src={info}
                    width={24}
                    height={24}
                    alt=""
                    role={"presentation"}
                  />
                  <span
                    className={`${style.card_section__title} text_overflow `}
                  >
                    Privacy Policy
                  </span>
                </Link>
              </li>
            </ul>

            <ul className={style.card_section} role={"menu"}>
              <li role={"none"}>
                <Link
                  to="/settings/support"
                  className={style.card_section__link}
                  role={"menuitem"}
                >
                  <img
                    className={style.card_section__icon}
                    src={questionIcon}
                    width={24}
                    height={24}
                    alt=""
                    role={"presentation"}
                  />
                  <span
                    className={`${style.card_section__title} text_overflow `}
                  >
                    Contact Support
                  </span>
                </Link>
              </li>
            </ul>

            {/*<Link*/}
            {/*  to="/settings/delete"*/}
            {/*  className={`btn__delete text-error l-spacing_xs`}*/}
            {/*>*/}
            {/*  Delete Account*/}
            {/*</Link>*/}
          </div>
        </div>

        {width > 767 && (
          <div className="right_bar right_bar_preview-window">
            <h4 className="ff-gilroy fw-700 l-spacing text-center">Preview</h4>
            <div className="right_bar__container">
              <PreviewPageHome />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default SettingsMain;

import getMode from "./tools/getMode";
import mergeDeep from "./tools/merge";
import QRCanvas from "./QRCanvas";

import defaultOptions, { RequiredOptions } from "./QROptions";
import sanitizeOptions from "./tools/sanitizeOptions";
import { QRCode, Options } from "./types";
import qrcode from "qrcode-generator";

export default class QRCodeStyling {
  _options: RequiredOptions;
  _container?: HTMLElement;
  _canvas?: QRCanvas;
  _qr?: QRCode;
  _canvasDrawingPromise?: Promise<void>;

  constructor(options?: Partial<Options>) {
    this._options = options ? sanitizeOptions(mergeDeep(defaultOptions, options) as RequiredOptions) : defaultOptions;
    this.update();
  }

  static _clearContainer(container?: HTMLElement): void {
    if (container) {
      container.innerHTML = "";
    }
  }

  async _getQRStylingElement(): Promise<QRCanvas> {
    if (!this._qr) throw "QR code is empty";

    let promise, canvas: QRCanvas;

    if (this._canvas && this._canvasDrawingPromise) {
      canvas = this._canvas;
      promise = this._canvasDrawingPromise;
    } else {
      canvas = new QRCanvas(this._options);
      promise = canvas.drawQR(this._qr);
    }

    await promise;

    return canvas;
  }

  update(options?: Partial<Options>): void {
    QRCodeStyling._clearContainer(this._container);
    this._options = options ? sanitizeOptions(mergeDeep(this._options, options) as RequiredOptions) : this._options;

    if (!this._options.data) {
      return;
    }

    this._qr = qrcode(this._options.qrOptions.typeNumber, this._options.qrOptions.errorCorrectionLevel);
    this._qr.addData(this._options.data, this._options.qrOptions.mode || getMode(this._options.data));
    this._qr.make();

    this._canvas = new QRCanvas(this._options);
    this._canvasDrawingPromise = this._canvas.drawQR(this._qr);

    this.append(this._container);
  }

  append(container?: HTMLElement): void {
    if (!container) {
      return;
    }

    if (typeof container.appendChild !== "function") {
      throw "Container should be a single DOM node";
    }

    if (this._canvas) {
      container.appendChild(this._canvas.getCanvas());
    }

    this._container = container;
  }
}

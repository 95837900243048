import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useUser } from "../../../providers/user.provider";
import style from "./Plans.module.scss";
import { useWindowDimensions } from "../../../services/widthCheck.service";
import PreviewPageHome from "../../ui/PreviewPageHome/PreviewPageHome";
import { useApi } from "../../../providers/api.provider";
import { useGuest } from "../../../providers/guest.provider";
import moment from "moment";
import Banner from "../../ui/Banner/Banner";

const Plans = () => {
  const user = useUser();
  const api = useApi();
  const guest = useGuest();
  const { width } = useWindowDimensions();
  const [action, setAction] = useState<string | null>(null);
  const [disabled, setDisabled] = useState<boolean>(false);
  const [free, setFree] = useState<string | null>(null);
  const navigate = useNavigate();

  const ClearSessionStorage = () => {
    sessionStorage.removeItem("CancelSubscription");
    navigate("/olink/premium");
    sessionStorage.removeItem("freePlan");
  };
  useEffect(() => {
    const data = sessionStorage.getItem("CancelSubscription");
    const freePlan = sessionStorage.getItem("freePlan");
    setAction(data);
    setFree(freePlan);
  });

  const setTrialOrder = async () => {
    user.setLoading(true);
    try {
      api.user
        .trialOrder()
        .then(() => {
          user.setLoading(false);
          guest.setActiveSnackBar({
            state: true,
            name: "success",
            message: "Successfully changed",
          });
          setDisabled(true);
        })
        .catch((error) => {
          console.log(error);
          user.setLoading(false);
          guest.setActiveSnackBar({
            state: true,
            name: "error",
            message: error.response.data.message,
          });
          setDisabled(true);
        });
    } catch (error) {
      user.setLoading(false);
    }
  };

  return (
    <div className="main-content">
      <div className={`${style.acc_edit} bg-gray_light account-edit`}>
        {width >= 768 && (
          <div className="page-title">
            <h2 className="text-center text-black">Plans</h2>
          </div>
        )}
        <div className={`${style.container} container`}>
          <Banner margin={5} />
          <div className="page_top">
            {width < 768 ? (
              <h2 className="text-black text-center ff-gilroy">Plans</h2>
            ) : null}
          </div>

          <div
            className="OlinkAccountEdit_acc_edit__wrapper__N2oGx edit-card"
            style={{
              maxWidth: 678,
              height: 280,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <h4
              style={{
                maxWidth: 404,
              }}
              className="text-black ff-gilroy fw-700"
            >
              {action === "cancel" ? "Your subscription has been canceled" : ""}
              {free === "free" ? "Downgrade to Free plan" : ""}
              {user?.user?.access === "regular" && action !== "cancel"
                ? "Change to Pro plan"
                : ""}
              {(user?.user?.access === "pro" &&
                action !== "cancel" &&
                free !== "free") ||
              user?.user?.access === "pro year"
                ? "Upgrade to Premium plan"
                : ""}
            </h4>
            <p
              className="TermsConditions_privacy_policy text-gray_dark"
              style={{
                maxWidth: 440,
                marginTop: 20,
                textAlign: "center",
              }}
            >
              {action === "cancel" || free === "free"
                ? `You will not be charged for any future payments. Your current plan
              remains active until
              ${moment(user?.user?.accessed_at).format(
                "YYYY-MM-DD"
              )}. After this
              date, your account will automatically transition to our free plan.`
                : ""}
              {user?.user?.access === "regular" && action !== "cancel"
                ? `Your current plan Free. If you want to upgrade, `
                : ""}
              {(user?.user?.access === "pro" &&
                action !== "cancel" &&
                free !== "free") ||
              user?.user?.access === "pro year"
                ? `Your current plan remains active until
								${moment(user?.user?.accessed_at).format(
                  "YYYY-MM-DD"
                )}. After this date, your account will automatically transition to our Premiun plan.`
                : ""}
            </p>
            <div
              style={{
                marginTop: 20,
                display: "flex",
                justifyContent: "space-evenly",
              }}
            >
              <button
                style={{
                  width: 130,
                  height: 44,
                  borderRadius: 8,
                  background: "#FFFFFF",
                  border: "1px solid #EAEAEA",
                }}
                className="text-blackff-gilroy fw-700"
                onClick={setTrialOrder}
                disabled={disabled}
              >
                Accept
              </button>

              <button
                style={{
                  width: 130,
                  height: 44,
                  borderRadius: 8,
                  background: "#FC7B52",
                }}
                className="text-white ff-gilroy fw-700"
                onClick={ClearSessionStorage}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
        {width > 767 && (
          <div className="right_bar right_bar_preview-window">
            <h4 className="ff-gilroy fw-700 l-spacing text-center">Preview</h4>
            <div className="right_bar__container">
              <PreviewPageHome />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Plans;

import React, { useState } from "react";
import { Button } from "@mui/material";

import style from "./TermsConditions.module.scss";
import { useNavigate } from "react-router-dom";
import { useWindowDimensions } from "../../../services/widthCheck.service";
import PreviewPageHome from "../../ui/PreviewPageHome/PreviewPageHome";
import { useUser } from "../../../providers/user.provider";
import Banner from "../../ui/Banner/Banner";

const TermsConditions = () => {
  const { width } = useWindowDimensions();
  const navigate = useNavigate();
  const handleOnBack = async () => {
    user ? navigate("/settings/main") : navigate("/");
  };

  const user = useUser();

  return (
    <div
      className={`main-content privacy_policy ${
        !user ? "privacy_policy--landing" : ""
      }`}
    >
      <div
        className={`${style.privacy_policy} ${
          !user ? style.privacy_policy__landing : ""
        } bg-gray_light`}
      >
        {width >= 768 && (
          <div className="page-title">
            <h2 className="text-center text-black">Terms & Conditions</h2>
          </div>
        )}

        <div className={`${style.container} container`}>
          {/* <Banner /> */}
          <div className="page_top">
            {user ? (
              <button
                type="button"
                className="btn_navigate btn_navigate_arrow"
                onClick={handleOnBack}
              >
                Back
              </button>
            ) : null}

            {width < 768 ? (
              <h2 className="text-center ff-gilroy text-black">
                Terms & Conditions
              </h2>
            ) : null}
          </div>

          <div className={`${style.privacy_policy__wrapper} card card--xl`}>
            <div className={`${style.privacy_policy__card} `} tabIndex={0}>
              <h6 className={`p fw-600`}>The Only Link Terms and Conditions</h6>
              <p className={style.privacy_policy__text}>
                Welcome to The Only Link!
              </p>
              {/* <p className={style.privacy_policy__text}>
                Last updated: 14 February 2025
              </p> */}
              <p className={style.privacy_policy__text}>
                We are happy you chose our service, which allows you to create a
                single link for all media resources.
              </p>
              <p className={style.privacy_policy__text}>
                Please read these Terms and Conditions (the Terms) carefully
                before using the tools, services, materials, information The
                Only Link provides (the Services) as they affect your
                obligations and legal rights, including, but not limited to,
                waivers of rights, limitation of liability, and your indemnity
                to us. It is very important.
              </p>
              <p className={style.privacy_policy__text}>
                These Terms, together with the Privacy Policy, govern your use
                of the Service and constitute a legally binding agreement
                between you and Mobizy OÜ. Please take the time to review these
                Terms thoroughly, and feel free to reach out with any questions
                or concerns. If you disagree with these Terms, we kindly ask you
                to refrain from using The Only Link
              </p>

              <h6 className={`${style.privacy_policy__header} p fw-600`}>
                1. Your Acceptance of Terms
              </h6>
              <p className={style.privacy_policy__text}>
                Access to all Services is given to you on the condition that you
                accept and agree with the Terms. Signing a contract with The
                Only Link and use of the Services indicates your consent with
                these Terms. If you do not agree to all the terms and
                conditions, then you may not access the Services. If these Terms
                are considered an offer, acceptance is expressly limited to
                these Terms.
              </p>
              <p className={style.privacy_policy__text}>
                The privacy and wellbeing of children is one of our concerns.
                Here at The Only Link, we provide services only to individuals
                who have reached the age of majority and are 18 years or older.
                If you are under 18, you will need to obtain your parent’s or
                guardian’s permission before submitting any private information
                to The Only Link.
              </p>
              <p className={style.privacy_policy__text}>
                We reserve the right to refuse the provision of Services to
                anyone for any reason at any time. We reserve the right at any
                time to modify or discontinue the Services (or any part thereof)
                without notice at any time.
              </p>

              <h6 className={`${style.privacy_policy__header} p fw-600`}>
                2. Enjoying the The Only Link
              </h6>
              <p className={style.privacy_policy__text}>
                <b style={{ color: "#2e2e2e" }}>2.1.</b> Registration <br />
                In order to use The Only Link Platform you are required to sign
                a contract with the Platform. You should provide true, actual
                and complete information while registering, and update your
                account information if necessary.
              </p>
              <p className={style.privacy_policy__text}>
                You are responsible for maintaining the confidentiality of your
                password and for all activities that occur within your Platform.
                The Only Link will not be responsible for any loss or damage
                arising from your failure to provide accurate information or to
                keep your password secure. Please use the unique combination of
                numbers, letters and special characters for passwords.
              </p>
              <p className={style.privacy_policy__text}>
                <b style={{ color: "#2e2e2e" }}> 2.2. </b>After your account has
                been created… <br />
                After you created your website and app at The Only Link Platform
                you will be able to use The Only Link computer program, created
                (developed) to create a single link for all media resources, and
                all objects of copyright and related rights, inventions,
                technology, ideas, concepts, processes, business plans,
                know-hows, trade secrets, documentation, financial projections,
                models and any other items, authored, conceived, invented,
                developed, designed or acquired by The Only Link as part of the
                Platform.
              </p>
              <p className={style.privacy_policy__text}>
                <b style={{ color: "#2e2e2e" }}> 2.3. </b> When creating an
                account on The Only Link, you must select a username that
                respects the rights of others, including intellectual property
                (IP) rights such as copyright and trademarks. Avoid using
                offensive, vulgar, or misleading names, as well as names
                belonging to others, such as celebrities or brands. Domain
                squatting for profit is prohibited. The Only Link may request
                you to change your username if necessary, and inactive accounts
                may risk losing their usernames after six months, with prior
                notice given to the user.
              </p>

              <h6 className={`${style.privacy_policy__header} p fw-600`}>
                3. License to use the Platform
              </h6>
              <p className={style.privacy_policy__text}>
                Subject to your complete and ongoing compliance with all the
                terms and conditions set forth in these Terms, The Only Link
                grants you the right (known as a license) to access and use the
                Platform pursuant to these Terms.
              </p>
              <p className={style.privacy_policy__text}>The license is:</p>
              <p className={style.privacy_policy__text}>
                <b style={{ color: "#2e2e2e" }}> 3.1.</b> ‘Limited’ (meaning
                that you can only use the Platform for the purposes we set out
                in these Terms);
              </p>
              <p className={style.privacy_policy__text}>
                <b style={{ color: "#2e2e2e" }}>3.2. </b>‘Non-exclusive’
                (meaning that The Only Link can grant the same and similar
                licenses to other people as well);
              </p>
              <p className={style.privacy_policy__text}>
                <b style={{ color: "#2e2e2e" }}> 3.3.</b> ‘Non-transferable’
                (meaning that the license is only for your benefit, and you may
                not transfer any of the rights that we grant to you to any other
                person);
              </p>
              <p className={style.privacy_policy__text}>
                <b style={{ color: "#2e2e2e" }}>3.4.</b> ‘Non-sublicensable’,
                (meaning that you also may not sublicense any of the rights that
                we grant to you to any other person);
              </p>
              <p className={style.privacy_policy__text}>
                <b style={{ color: "#2e2e2e" }}> 3.5.</b> ‘Revocable’ (meaning
                that The Only Link can terminate this license);
              </p>
              <p className={style.privacy_policy__text}>
                <b style={{ color: "#2e2e2e" }}> 3.6.</b> And conditional on
                your compliance with these Terms.
              </p>
              <p className={style.privacy_policy__text}>
                Your license to access the Platform is not limited by territory.
                Thus, the license applies worldwide, unless the applicable law
                or the law of the country of your location forbids such license
                in certain countries.
              </p>
              <p className={style.privacy_policy__text}>
                Such license does not confer on you any ownership interest in
                the Platform. Words such as «purchase», «payment» or «sale» (and
                similar terms, including all tenses), as applied to the Platform
                and the Services, refer to the grant to you of a limited license
                as described herein.
              </p>
              <p className={style.privacy_policy__text}>
                All The Only Link’s materials, including intellectual property
                rights therein and thereto, are the property of The Only Link or
                its subsidiaries or affiliated companies and/or third-party
                licensors. You may not reproduce, distribute, rent, lease, sell,
                license, copy, modify, publicly perform or display, transfer,
                transmit, publish, edit, adapt, prepare derivative works based
                on, or otherwise use the materials from the Platform except as
                expressly authorized by these Terms.
              </p>
              <h6 className={`${style.privacy_policy__header} p fw-600`}>
                4. Your Content
              </h6>
              <p className={style.privacy_policy__text}>
                <b style={{ color: "#2e2e2e" }}>4.1. </b> At The Only Link, we
                value the diverse content shared by our users. However, for a
                safe experience, we have Community Standards that outline
                acceptable content. By using the Platform, you agree to ensure
                your content is:
                <br />
                <span className={style.dot}></span>Yours or properly licensed
                for sharing.
                <br />
                <span className={style.dot}></span>Free from infringement on
                rights like privacy and intellectual property.
                <br />
                <span className={style.dot}></span>Honest and accurate, without
                misleading or harmful information.
                <br />
                <span className={style.dot}></span>Secure, free from viruses or
                disruptive code.
                <br />
                <span className={style.dot}></span>Not using automated tools to
                collect data.
                <br />
                <span className={style.dot}></span>Free from unauthorized ads or
                endorsements.
                <br />
                <span className={style.dot}></span>Does not contain explicit 18+
                material.
                <br />
                <span className={style.dot}></span>Promote the use or
                distribution of prohibited substances.
                <p className={style.privacy_policy__text}>
                  We reserve the right to remove content or restrict access as
                  needed, following legal and regional regulations.
                </p>
              </p>
              <h6 className={`${style.privacy_policy__header} p fw-600`}>
                5. Price and Payment Terms
              </h6>
              <p className={style.privacy_policy__text}>
                <b style={{ color: "#2e2e2e" }}> 5.1. </b>You can register with
                The Only Link under a free or paid plan, which begins upon
                accepting these Terms and continues until canceled. If you
                cancel a paid plan, it remains active until the end of the
                billing cycle before reverting to a free plan.
              </p>
              <p className={style.privacy_policy__text}>
                <b style={{ color: "#2e2e2e" }}> 5.2. </b> The current prices
                and other conditions of the tariff plans and Services are posted
                on the Website at the link:
                <a href="https://theonly.link/" target="_blank">
                  https://theonly.link/
                </a>
                . and constitute an integral part of the Terms.
              </p>
              <p className={style.privacy_policy__text}>
                <b style={{ color: "#2e2e2e" }}> 5.3. </b> Payment for the
                Services is made on the terms of prepayment based on the issued
                invoice using one of the following methods:
                <br />
                <span className={style.dot}></span>Online payment;
                <br />
                <span className={style.dot}></span>Payment to a bank account.
              </p>
              <p className={style.privacy_policy__text}>
                <b style={{ color: "#2e2e2e" }}> 5.4.</b> In the event of a
                refusal to continue accessing the Program or a unilateral
                termination of the Terms initiated by you, the prepayment made
                by you can be refunded on a proportional basis only in case of
                business cessation. In all other cases, prepayment for the
                Services is non-refundable. If you cancel a paid subscription,
                your plan will remain active until the end of your billing
                cycle.
              </p>
              <p className={style.privacy_policy__text}>
                <b style={{ color: "#2e2e2e" }}>5.5. </b> If your business is
                undergoing a reorganization that eliminates the need for access
                to the Program, The Only Link may defer your next payment for an
                appropriate period. This deferral takes effect from the date of
                your request to us.
              </p>
              <p className={style.privacy_policy__text}>
                <b style={{ color: "#2e2e2e" }}> 5.6.</b> In the event of a
                unilateral termination of the Terms initiated by The Only Link,
                except in cases of your violation of the terms of this Offer,
                payments made by you as prepayment shall be returned to the
                account specified by you.
              </p>
              <p className={style.privacy_policy__text}>
                <b style={{ color: "#2e2e2e" }}>5.7.</b> The Only Link has the
                right to unilaterally change the terms of tariff plans,
                including the prices for the Services.
              </p>
              <p className={style.privacy_policy__text}>
                <b style={{ color: "#2e2e2e" }}> 5.8.</b> If you disagree with
                the changes to the tariff plan and do not make further payments
                or cease to pay for the Services for any other reason, the Terms
                is considered terminated at your initiative after one month from
                the expiration of the access period to the Program in accordance
                with the terms of the previous tariff plan.
              </p>

              <h6 className={`${style.privacy_policy__header} p fw-600`}>
                6. Limitations on Use of the Service
              </h6>

              <p className={style.privacy_policy__text}>
                While using the Platform you may not, and may not encourage,
                authorize, or assist others to,
              </p>
              <p className={style.privacy_policy__text}>
                <b style={{ color: "#2e2e2e" }}>6.1.</b> Reverse engineer,
                decompile, disassemble, or in any way access or attempt to
                access the source code for the Platform and/or extract any files
                from them.
              </p>
              <p className={style.privacy_policy__text}>
                <b style={{ color: "#2e2e2e" }}> 6.2. </b> Or make or attempt to
                make any modification to the Platform.
              </p>
              <p className={style.privacy_policy__text}>
                <b style={{ color: "#2e2e2e" }}> 6.3.</b>Modify, defeat,
                interfere with, or circumvent any feature of the Platform,
                including any security or access control mechanism.
              </p>
              <p className={style.privacy_policy__text}>
                <b style={{ color: "#2e2e2e" }}> 6.4. </b> Attempt to probe,
                study or test the vulnerability of the Platform or to breach any
                security or authentication measures, regardless of your motives
                and/or intent.
              </p>
              <p className={style.privacy_policy__text}>
                <b style={{ color: "#2e2e2e" }}> 6.5. </b>Attempt to damage,
                interfere with, or disrupt the Services to any user, processor,
                host or network, including by submitting a virus, worm, or
                Trojan horse.
              </p>
              <p className={style.privacy_policy__text}>
                <b style={{ color: "#2e2e2e" }}>6.6. </b> Pretend that you are,
                or that you represent, someone else or impersonate any other
                individual or entity.
              </p>
              <p className={style.privacy_policy__text}>
                <b style={{ color: "#2e2e2e" }}>6.7.</b> Intercept any system
                data, personal information, or other data relating to the
                Platform.
              </p>
              <p className={style.privacy_policy__text}>
                <b style={{ color: "#2e2e2e" }}>6.8.</b> Access or use the
                Platform for any unlawful purpose.
              </p>
              <p className={style.privacy_policy__text}>
                <b style={{ color: "#2e2e2e" }}>6.9. </b>Take any action that
                would impose a disproportionately large or unreasonable load on
                our network or other infrastructure.
              </p>
              <p className={style.privacy_policy__text}>
                <b style={{ color: "#2e2e2e" }}> 6.10. </b> Use or launch any
                «robot», «spider», «page-scraper», «deeplink» or other automated
                means to access, acquire, copy, or monitor any part of the
                Platform.
              </p>
              <p className={style.privacy_policy__text}>
                Any of the above-mentioned as well as any action that is
                contrary to law shall be the ground for unilateral termination
                of this Agreement by The Only Link without the obligation to
                make any refunds of the paid amounts.
              </p>
              <h6 className={`${style.privacy_policy__header} p fw-600`}>
                7. What We Can Do With Your Content
              </h6>
              <p className={style.privacy_policy__text}>
                <b style={{ color: "#2e2e2e" }}>7.1.</b> When you post content
                on The Only Link, you grant us a global, royalty-free, and
                perpetual license to use, display, distribute, modify, adapt,
                and create derivative works from it. This includes using your
                name, image, and likeness for platform and marketing purposes
              </p>
              <p className={style.privacy_policy__text}>
                You retain all rights to your content but acknowledge it will be
                publicly accessible and may be shared by others. Avoid posting
                sensitive personal information or others’ details without
                consent. We reserve the right to monitor, modify, or remove
                content per our Terms.
              </p>
              <p className={style.privacy_policy__text}>
                <b style={{ color: "#2e2e2e" }}>7.2.</b> If your content is
                removed for policy violations, you will receive an email with
                the reason. You can appeal the decision within 7 days by
                contacting support at
                <a href="mailto:contact@theonly.link"> contact@theonly.link</a>.
              </p>
              <h6 className={`${style.privacy_policy__header} p fw-600`}>
                8. Technical Support
              </h6>
              <p className={style.privacy_policy__text}>
                The Only Link shall maintain the reliable performance of the
                Platform throughout the term of its using. This shall include:
              </p>
              <p className={style.privacy_policy__text}>
                <b style={{ color: "#2e2e2e" }}>8.1. </b> Constant updates and
                amendments of the Platform’s software in order to eliminate any
                deficiencies, errors and bugs that can deteriorate the use of
                software.
              </p>
              <p className={style.privacy_policy__text}>
                <b style={{ color: "#2e2e2e" }}> 8.2.</b> Providing
                consultations to you regarding the functions of the Platform,
                Pricing Plans and other organizational issues.
              </p>
              <p className={style.privacy_policy__text}>
                After the payment of the first invoice, an IT-professional shall
                be dedicated to you in order to help you with every issue that
                can arise while you’re using the Platform. In order to reach him
                please use our email
                <a href="mailto:contact@theonly.link"> contact@theonly.link</a>.
              </p>
              <p className={style.privacy_policy__text}>
                If your business needs require additional or more complicated
                technical support, we can enter into a separate agreement on the
                terms for its provision.
              </p>

              <h6 className={`${style.privacy_policy__header} p fw-600`}>
                9. Privacy (Personal Data)
              </h6>

              <p className={style.privacy_policy__text}>
                The Only Link respects your privacy and has established certain
                policies and procedures relating to the collection and use of
                your Personal Data in connection with your use of the Platform.
                Please, check our Privacy Policy to be aware of the policies and
                procedures applied to your use of the Service.
              </p>

              <h6 className={`${style.privacy_policy__header} p fw-600`}>
                10. Deactivation/Termination of Your Registration
              </h6>

              <p className={style.privacy_policy__text}>
                You may deactivate your registration on the Services, at any
                time and for any reason, by sending an email request to
                <a href="mailto:contact@theonly.link"> contact@theonly.link</a>.
              </p>
              <p className={style.privacy_policy__text}>
                We may terminate your use of and registration within the
                Platform at any time if you violate these Terms. In such case
                The Only Link will refund only the amounts paid for the Services
                in upcoming months that were not used by the user without the
                obligation to refund the amounts paid in the month when the
                termination was made.
              </p>

              <h6 className={`${style.privacy_policy__header} p fw-600`}>
                11. Disclaimer of Warranties
              </h6>
              <p className={style.privacy_policy__text}>
                To the maximum extent permitted by applicable law, you agree
                that accessing and using The Only Link is at your own risk. The
                services are provided "as is," "with all faults," and "as
                available," without warranties of any kind. The Only Link
                disclaims all warranties, express, implied, statutory, or
                otherwise, including but not limited to implied warranties of
                fitness for a particular purpose, merchantability, and
                non-infringement.
              </p>
              <p className={style.privacy_policy__text}>
                We do not guarantee continuous availability of services, nor do
                we ensure your use will be uninterrupted, timely, secure, or
                error-free. The Only Link is not liable for any damage to your
                device or data loss from using the service.
              </p>

              <h6 className={`${style.privacy_policy__header} p fw-600`}>
                12. Your Responsibility for Your Visitors and Customers
              </h6>
              <p className={style.privacy_policy__text}>
                You are responsible for the actions and experiences of your
                visitors, including customers (“End Users”), who engage with
                your profile or purchase goods/services through The Only Link.
                This responsibility includes managing how End Users interact
                with your profile and content, and ensuring compliance with all
                applicable laws in transactions with End Users.
              </p>
              <p className={style.privacy_policy__text}>
                The Only Link is not liable for any products or services
                advertised, offered, or sold through its platform.
              </p>

              <h6 className={`${style.privacy_policy__header} p fw-600`}>
                13. Feedback
              </h6>
              <p className={style.privacy_policy__text}>
                We value your ideas on improving The Only Link! Occasionally, we
                may offer “beta” features and invite your feedback. By providing
                feedback, you agree that we can use it freely, without
                compensation to you, or choose not to use it.
              </p>
              <p className={style.privacy_policy__text}>
                Please note that beta features are experimental, under
                evaluation, and might not function as reliably as other parts of
                the platform.
              </p>
              <p className={style.privacy_policy__text}>
                We may share confidential information with you, such as details
                about upcoming features or participation in beta testing. You
                are required to keep this information secret and secure, using
                reasonable measures to prevent unauthorized access. If there is
                specific information from a beta trial that we approve for
                public sharing, we will notify you explicitly.
              </p>

              <h6 className={`${style.privacy_policy__header} p fw-600`}>
                14. Changes to Terms and Assignment
              </h6>
              <p className={style.privacy_policy__text}>
                We may change these Terms from time to time, so please check
                this page to ensure that you’re happy with any changes. The
                additional notifications on any amendments shall be sent to your
                The Only Link’s account. If within one week after the
                notification is sent you continue to use The Only Link Platform,
                you will be considered as having accepted all the amendments
                made.
              </p>
              <h6 className={`${style.privacy_policy__header} p fw-600`}>
                15. Third-Party Services
              </h6>
              <p className={style.privacy_policy__text}>
                The Only Link integrates with various third-party products and
                services, offering access to additional features through the
                Platform. Usage of third-party services is subject to their
                respective terms and conditions, which users must review,
                accept, and follow.
              </p>
              <p className={style.privacy_policy__text}>
                Non-compliance with these terms may lead to account suspension,
                cancellation, or restricted access to third-party integrations
                on the Platform.
              </p>

              <h6 className={`${style.privacy_policy__header} p fw-600`}>
                16. Governing Law and Dispute Resolution
              </h6>
              <p className={style.privacy_policy__text}>
                <b style={{ color: "#2e2e2e" }}> 16.1.</b> These Terms shall be
                governed by and construed in accordance with the laws of
                Estonia. You are responsible for complying with local laws,
                including Estonian laws, if applicable. You specifically agree
                to comply with all applicable laws concerning the transmission
                of technical data exported and/or re-exported from the country
                in which you reside.
              </p>
              <p className={style.privacy_policy__text}>
                <b style={{ color: "#2e2e2e" }}> 16.2.</b> Users outside Estonia
                are also subject to Estonian law when using The Only Link
                platform.
              </p>
              <p className={style.privacy_policy__text}>
                <b style={{ color: "#2e2e2e" }}> 16.3.</b> Both we and you shall
                seek to resolve any dispute arising under these Terms (including
                questions regarding implementation, execution, validity, or
                termination) through negotiations.
              </p>
              <p className={style.privacy_policy__text}>
                <b style={{ color: "#2e2e2e" }}> 16.4.</b> If negotiations fail
                within ninety (90) calendar days, the dispute shall be settled
                under the Rules of Arbitration of the Estonian Chamber of
                Commerce and Industry in Tallinn, with one arbitrator appointed
                according to said rules. The substantive law of Estonia shall
                apply, and the language of the proceedings shall be English.
              </p>
              <p className={style.privacy_policy__text}>
                <b style={{ color: "#2e2e2e" }}> 16.5.</b> If any provision of
                these Terms is found to be unlawful, void, or unenforceable, it
                shall be deemed severable from this agreement, and shall not
                affect the validity and enforceability of the remaining
                provisions. This agreement represents the entire understanding
                between the parties.
              </p>
              <p className={style.privacy_policy__text}>
                <b style={{ color: "#2e2e2e" }}> 16.6.</b> We encourage users to
                contact support first to attempt resolution. If no agreement is
                reached, arbitration in Estonia will be required.
              </p>

              <h6 className={`${style.privacy_policy__header} p fw-600`}>
                17. Legal Restrictions
              </h6>
              <p className={style.privacy_policy__text}>
                Use of the Service is unauthorized and unlicensed wherever it is
                unlawful under applicable law. You may not use the Service at
                any place or in any manner that would violate any applicable
                technology control, export, or import laws. Any offer of any
                feature, product or service is void where prohibited. The Only
                Link may limit or deny to any person, in its sole discretion,
                access to any feature, product or service.
              </p>
              <h6 className={`${style.privacy_policy__header} p fw-600`}>
                18. Assignment
              </h6>
              <p className={style.privacy_policy__text}>
                The Only Link may, in its sole discretion, assign its rights
                and/or delegate its duties under this agreement. You may not
                assign your rights or delegate your duties, and any assignment
                or delegation without the written consent of The Only Link,
                which The Only Link may withhold in its sole discretion, shall
                be void.
              </p>
              <h6 className={`${style.privacy_policy__header} p fw-600`}>
                19. Contact Information
              </h6>
              <p className={style.privacy_policy__text}>
                We hope these Terms helped you to understand how The Only Link
                Platform works and be sure everything is foreseeable and safe
                while using it. So, enjoy our Platform! If you have any
                questions regarding the use of Services or regarding these
                Terms, you should e-mail us to
                <a href="mailto:contact@theonly.link"> contact@theonly.link</a>.
                <br />
                Your The Only Link team.
              </p>
            </div>
          </div>
        </div>

        {user && width > 767 && (
          <div className="right_bar right_bar_preview-window">
            <h4 className="ff-gilroy fw-700 l-spacing text-center">Preview</h4>
            <div className="right_bar__container">
              <PreviewPageHome />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default TermsConditions;

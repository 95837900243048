import React, { useEffect, useRef, useState } from 'react';
import Dialog from '@mui/material/Dialog';
import { DialogContent, DialogTitle, Slide } from '@mui/material';
import cross from '../../../../assets/svg/cross.svg';

import styles from './ShareSocialDialog.module.scss';
import { useUser } from '../../../../providers/user.provider';
import { TransitionProps } from '@mui/material/transitions';
import './ShareSocialDialog.scss';
import {
  EmailIcon,
  EmailShareButton,
  FacebookIcon,
  FacebookShareButton, LinkedinIcon,
  LinkedinShareButton, PinterestIcon, PinterestShareButton,
  TwitterIcon,
  TwitterShareButton
} from 'react-share';

interface ITolDialog {
  open: boolean;
  onClose: () => void;
  [key: string]: any;
}

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const ShareSocialDialog = (props: ITolDialog) => {
  const user = useUser();
  const { open, onClose, ...other } = props;

  const [base64, setBase64] = useState<string | null>(null);

  const handleClose = () => {
    onClose && onClose();
  };

  const handleDownload = () => {
    if (base64) {
      const linkSource = base64;
      const downloadLink = document.createElement('a');
      document.body.appendChild(downloadLink);
      downloadLink.href = linkSource;
      downloadLink.hidden = true;
      downloadLink.target = '_self';
      downloadLink.download = 'OLinkQRCode.png';
      downloadLink.click();
      document.body.removeChild(downloadLink);
    }
  }

  const handleCopy = () => {
    const copyText = document.createElement('input') as HTMLInputElement;
    document.body.appendChild(copyText);
    copyText.value = process.env.REACT_APP_BACK_URL + '/' + user.currentOLink?.path;
    copyText.select();
    copyText.setSelectionRange(0, 99999);
    navigator.clipboard.writeText(copyText.value);
    document.body.removeChild(copyText);
  }

  return (
    <Dialog
      sx={{
        zIndex: 1400,
      }}
      onClose={handleClose}
      open={props.open}
      keepMounted
      TransitionComponent={Transition}
      className={styles.share + ' share-social'}
    >
      <DialogTitle className={styles.share__title}>
        <button className={styles.share__close} onClick={handleClose}>
          <img src={cross} alt='Close edit' width={24} height={24}/>
        </button>
      </DialogTitle>
      <DialogContent className={styles.share__content}>
        <div className={styles.share__row}>
          <FacebookShareButton
            url={process.env.REACT_APP_BACK_URL + '/' + user.currentOLink?.path}
            quote={'OLink'}
          >
            <FacebookIcon size={62}/>
          </FacebookShareButton>
          <TwitterShareButton
            url={process.env.REACT_APP_BACK_URL + '/' + user.currentOLink?.path}
            title={'OLink'}
          >
            <TwitterIcon size={62}/>
          </TwitterShareButton>
          <LinkedinShareButton url={process.env.REACT_APP_BACK_URL + '/' + user.currentOLink?.path}>
            <LinkedinIcon size={62}/>
          </LinkedinShareButton>
          <PinterestShareButton
            url={process.env.REACT_APP_BACK_URL + '/' + user.currentOLink?.path}
            media={`test`}
          >
            <PinterestIcon size={62} />
          </PinterestShareButton>
          <EmailShareButton
            url={process.env.REACT_APP_BACK_URL + '/' + user.currentOLink?.path}
            subject={'OLink'}
            body="body"
          >
            <EmailIcon size={62} />
          </EmailShareButton>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default ShareSocialDialog;

import React, { useRef, useState, useEffect } from "react";

interface ISelectTab {
  handleClickTabs(select: string): void;
}

const Tabs = (props: ISelectTab) => {
  const tabsRef = useRef(null);
  const tabListRef = useRef<(HTMLButtonElement | null)[]>([]);
  const tabsSliderRectRef = useRef<HTMLDivElement>(null);
  const [activeTab, setActiveTab] = useState<string>("Monthly");

  useEffect(() => {
    if (tabsSliderRectRef.current) {
      tabsSliderRectRef.current.style.transform = `translateX(100%)`;
    }
    tabListRef.current[1]?.classList.add("is-active");
  }, []);

  const handleActiveTab = (
    tabs: HTMLButtonElement[],
    event: React.MouseEvent<HTMLButtonElement>,
    className: string
  ) => {
    tabs.forEach((tab) => {
      tab.classList.remove(className);
    });

    const target = event.currentTarget;

    if (!target.classList.contains(className)) {
      target.classList.add(className);
    }
  };

  const handleTabClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    const targetButton = event.currentTarget;
    const targetTranslate = targetButton.dataset.translate;
    const tabName = targetButton.dataset.tab;

    if (targetButton.classList.contains("tol-tab")) {
      if (tabsSliderRectRef.current) {
        tabsSliderRectRef.current.style.transform = `translateX(${targetTranslate})`;
      }

      handleActiveTab(
        tabListRef.current.filter(
          (tab): tab is HTMLButtonElement => tab !== null
        ),
        event,
        "is-active"
      );

      if (tabName) {
        setActiveTab(tabName);
        props.handleClickTabs(tabName);
      }
    }
  };

  return (
    <div className="tol-tabs-container">
      <div className="tol-tabs" ref={tabsRef}>
        <button
          onClick={handleTabClick}
          className="tol-tab"
          data-translate="0"
          data-tab="Annually"
          ref={(el) => (tabListRef.current[0] = el)}
        >
          Annually
        </button>
        <button
          onClick={handleTabClick}
          className="tol-tab"
          data-translate="100%"
          data-tab="Monthly"
          ref={(el) => (tabListRef.current[1] = el)}
        >
          Monthly
        </button>
      </div>
      <div className="tol-tabs-slider">
        <div className="tol-tabs-slider-rect" ref={tabsSliderRectRef}></div>
      </div>
    </div>
  );
};

export default Tabs;

import React, { useEffect } from "react";
import Snackbar from "@mui/material/Snackbar";
import { useGuest } from "../../../providers/guest.provider";

import styles from "./SnackBar.module.scss";

import { useWindowDimensions } from "../../../services/widthCheck.service";

export enum ESnackBarType {
  error = "error",
  success = "success",
  warning = "warning",
}

const SnackBar = () => {
  const { width } = useWindowDimensions();
  const { setActiveSnackBar, activeSnackBar } = useGuest();
  useEffect(() => {
    if (activeSnackBar.state && width <= 1200) {
      document.documentElement.style.setProperty("--snackbar", "40px");
    }
  }, [activeSnackBar.state]);
  const handleClose = (
    event: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    setActiveSnackBar({
      state: false,
      name: "",
      message: "",
    });
    document.documentElement.style.removeProperty("--snackbar");
  };

  const snack = {
    style: "",
  };

  switch (activeSnackBar.name) {
    case ESnackBarType.error:
      snack.style = styles.snackbar__error;
      break;
    case ESnackBarType.success:
      snack.style = styles.snackbar__success;
      break;
    case ESnackBarType.warning:
      snack.style = styles.snackbar__warning;
      break;
    default:
      break;
  }

  return (
    <>
      <Snackbar
        open={activeSnackBar.state}
        autoHideDuration={6000}
        onClose={handleClose}
        // TransitionComponent={Transition}
        /*        ContentProps={{
          className : `${styles.snackbar} ${snack.styles}`,
          sx: { "& .MuiSnackbarContent-message": { padding: "0" } }
        }}*/
      >
        <div
          className={`${styles.snackbar} ${snack.style} ${
            activeSnackBar ? "animation" : ""
          }`}
        >
          <p>{activeSnackBar.message}</p>
        </div>
      </Snackbar>
    </>
  );
};

export default SnackBar;

import React, { useEffect, useState } from "react";

import UserHavingRights from "../../ui/UserHavingRights/UserHavingRights";
import Avatar from "@mui/material/Avatar";
import { TextField } from "@mui/material";
import styles from "./DelegateOlink.module.scss";
import { useNavigate, useParams } from "react-router-dom";
import { useUser } from "../../../providers/user.provider";
import {
  IDelegateUserContext,
  IProfileContext,
} from "../../../interfaces/user.context";
import { useApi } from "../../../providers/api.provider";
import { useWindowDimensions } from "../../../services/widthCheck.service";
import share_icon from "../../../assets/svg/share--white.svg";
import { ESnackBarType } from "../../ui/SnackBar/SnackBar";
import { useGuest } from "../../../providers/guest.provider";
import Banner from "../../ui/Banner/Banner";

export interface IDelegateErrors {
  nickname: string | null;
}

const DelegateOlink = () => {
  const { width } = useWindowDimensions();
  const navigate = useNavigate();
  const user = useUser();
  const api = useApi();
  const { setActiveSnackBar } = useGuest();

  const getValueForProfilesUser = async () => {
    if (user.user.id && user.userProfiles.length === 0) {
      await api.profiles.getProfiles(user.user.id).then((res) => {
        //, 1, 10
        user.setUserProfiles(res.data);
      });
    }
  };

  const getValueForDelegatesUser = async () => {
    if (user.user.id && user.delegates.length === 0) {
      await api.delegates.getDelegates(user.user.id).then((res) => {
        //, 1, 10
        user.setDelegates(res.data);
      });
    }
  };

  const updateGetDelegates = async () => {
    if (user.user.id) {
      await api.delegates.getDelegates(user.user.id).then((res) => {
        //, 1, 10
        user.setDelegates(res.data);
      });
      await api.profiles.getProfiles(user.user.id).then((res) => {
        //, 1, 10
        user.setUserProfiles(res.data);
      });
    }
  };

  useEffect(() => {
    getValueForProfilesUser();
  }, [user.user.id, user?.userProfiles?.length]);

  useEffect(() => {
    getValueForDelegatesUser();
  }, [user.user.id, user?.delegates?.length]);

  const { id } = useParams();
  const setStates = () => {
    if (id) {
      const selectedProfile = user.userProfiles?.data?.find(
        (p: any, index: any) => {
          if (p.id === parseInt(id, 10)) {
            setIndex(index);
            return true;
          } else {
            return false;
          }
        }
      );
      if (selectedProfile) {
        setSelectedProfile(selectedProfile);
      }
    }
  };

  const [nickname, setNickname] = useState<string>("");
  const [stateDelegateBtn, setStateDelegateBtn] = useState<boolean>(false);
  const [selectedProfile, setSelectedProfile] =
    useState<IProfileContext | null>(null);
  const [index, setIndex] = React.useState<number | null>(null);
  const [errors, setErrors] = useState<IDelegateErrors>({
    nickname: null,
  });

  useEffect(() => {
    setStates();
  }, [user.userProfiles]);

  const handleOnBack = async () => {
    navigate(-1);
  };

  const handleChangeNickname = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNickname(event.target.value);
    setErrors({ nickname: null });
    setStateDelegateBtn(false);
  };

  const validateForm = (): boolean => {
    const errs = { ...errors };
    let res = true;
    if (!nickname || nickname.length === 0) {
      errs.nickname = "Nickname is required!";
      res = false;
    } else {
      errs.nickname = null;
    }
    setErrors(errs);
    return res;
  };
  const handleOnDelegate = async () => {
    if (validateForm() && id) {
      user.setLoading(true);
      await api.user.delegateOLink(parseInt(id, 10), nickname).then(
        (res) => {
          const { ...newSelectedProfile } = selectedProfile;
          const { profile, ...delegatedUser } = res;
          if (!newSelectedProfile.delegates) {
            newSelectedProfile.delegates = [];
          }
          newSelectedProfile.delegates = newSelectedProfile.delegates.concat([
            delegatedUser,
          ]);
          setSelectedProfile(newSelectedProfile);
          setNickname("");
          setStateDelegateBtn(true);
          if (index != null) {
            const [...userProfiles] = user.userProfiles.data;
            userProfiles[index] = newSelectedProfile;
            user.setUserProfiles((prevState: any) => ({
              ...prevState,
              data: userProfiles,
            }));
          }
        },
        (err) => {
          setErrors({ nickname: err.response.data.message });
          setActiveSnackBar({
            state: true,
            name: ESnackBarType.error,
            message: err.response.data.message,
          });
        }
      );
      user.setLoading(false);
    }
  };

  const handleOnInvite = () => {
    navigate("/settings/details/invite");
  };

  const handleChangeRelUsers = (delegates: number) => {
    const { ...newSelectedProfiles } = selectedProfile;
    const newArray = newSelectedProfiles.delegates.filter((el: any) => {
      return el.id !== delegates;
    });
    newSelectedProfiles.delegates = newArray;
    setSelectedProfile(newSelectedProfiles);
    if (index != null) {
      const [...userProfiles] = user.userProfiles.data;
      userProfiles[index] = newSelectedProfiles;
      user.setUserProfiles((prevState: any) => ({
        ...prevState,
        data: userProfiles,
      }));
    }
  };

  return (
    <div className="main-content">
      <div className={`${styles.delegate} bg-gray_light`}>
        {width >= 768 && (
          <div className="page-title">
            <h2 className="text-center text-black">Delegate Rights</h2>
          </div>
        )}
        <div className={`${styles.container} container`}>
          <Banner />
          <div className="page_top">
            <button
              type="button"
              className="btn_navigate btn_navigate_arrow"
              onClick={handleOnBack}
            >
              Back
            </button>
            {width < 768 && (
              <h2 className="text-center ff-gilroy text-black">
                Delegate Rights
              </h2>
            )}
          </div>

          <div className={`${styles.delegate__card} edit-card`}>
            <div className="deleteContent__wrapper">
              <div className={`${styles.delegate__user} mb-30`}>
                <Avatar
                  className={styles.delegate__ava}
                  alt="User"
                  src={
                    selectedProfile?.avatar ? selectedProfile?.avatar.url : ""
                  }
                />
                <h4 className={`${styles.delegate__name} ff-gilroy`}>
                  {selectedProfile?.fullName}
                </h4>
                <p
                  className={`${styles.delegate__text}  text_overflow small-text`}
                >
                  {process.env.REACT_APP_BACK_URL +
                    "/" +
                    selectedProfile?.nickname}
                </p>
              </div>

              <div className="container-inner">
                <p className="text-gray_dark_2 mb-15 fw-400">
                  Specify the nickname of&nbsp;the user to&nbsp;whom you want
                  to&nbsp;delegate the rights to&nbsp;TOL
                </p>
              </div>
              <TextField
                id="nickName"
                label="User Nickname "
                name="nickName"
                variant="outlined"
                value={nickname}
                onChange={handleChangeNickname}
                error={!!errors.nickname}
                helperText={errors.nickname}
              />

              {/* {errors.nickname === "Profile not found" ? (
                <>
                  <div className="container-inner">
                    <p className="text-gray_dark_2 mb-20 fw-400 fs-14">
                      You can send an invite to user by email.
                    </p>
                  </div>
                  <button
                    disabled={!nickname || nickname.length < 3}
                    className="btn icon icon__center"
                    onClick={handleOnInvite}
                  >
                    <img src={share_icon} alt="share icon" />
                    Send Invite
                  </button>
                </>
              ) : ( */}
              <>
                <div className="container-inner">
                  <p
                    className={`${styles.delegate__text_small} text-gray_dark_2 mb-20 fw-400 fs-14`}
                  >
                    Delegating rights to&nbsp;TOL, you authorize to&nbsp;edit
                    personal information and the appearance of&nbsp;your TOL
                  </p>
                </div>
                {!stateDelegateBtn ? (
                  <button
                    disabled={!nickname || nickname.length < 3}
                    className="btn"
                    onClick={handleOnDelegate}
                  >
                    Delegate
                  </button>
                ) : (
                  <button
                    disabled={!nickname || nickname.length < 3}
                    className={`btn btn--updated `}
                    onClick={handleOnDelegate}
                  >
                    Rights Delegated
                  </button>
                )}
              </>
              {/* )} */}
            </div>
          </div>

          {selectedProfile?.delegates?.length ? (
            <div className={`${styles.rights} card card--xl`}>
              <h4 className="text-center ff-gilroy mb-10">Having Rights</h4>
              <UserHavingRights
                users={
                  selectedProfile?.delegates?.length
                    ? selectedProfile.delegates
                    : []
                }
                olinkId={id ? id : ""}
                onChange={handleChangeRelUsers}
                updateData={updateGetDelegates}
              />
            </div>
          ) : null}
        </div>

        {width > 767 && (
          <div className="right_bar">
            <h4 className="ff-gilroy fw-700 l-spacing text-center">Preview</h4>
            <div className="right_bar__container"></div>
          </div>
        )}
      </div>
    </div>
  );
};

export default DelegateOlink;

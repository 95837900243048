import { useState, useEffect } from "react";
import logo from "../../../assets/svg/logo-banner.svg";
import { useUser } from "../../../providers/user.provider";
import { useWindowDimensions } from "../../../services/widthCheck.service";
import CloseIcon from "@mui/icons-material/Close";

interface Style {
  margin?: number;
  plans?: boolean;
  mytol?: boolean;
}

const Banner = (props: Style) => {
  const [close, setClose] = useState<boolean>(true);
  const { width } = useWindowDimensions();

  const { banner, setBanner, payment, user, currentProfile } = useUser();
  const handleClickClose = () => {
    setClose(!close);
    setBanner(false);
  };

  const handleClickClaimOffer = () => {
    return payment.map((item: any) => {
      if (item.description.description === "Premium Year") {
        return window.open(
          item.external_url + `?prefilled_email=${user.email}`,
          "_blank"
        );
      }
    });
  };

  useEffect(() => {
    if (user?.access === "premium" && user?.typeSubscription === "year") {
      setClose(false);
    } else {
      if (!banner) {
        setClose(false);
      }
    }
  }, [close, user]);
  return (
    <>
      {close ? (
        <div
          style={{
            ...(props.plans && width >= 768 && { marginLeft: "13px" }),

            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            background: "#FC7B52",
            height:
              (!props.margin && width <= 1395) ||
              (props.margin && width <= 1150)
                ? "76px"
                : "56px",
            minHeight:
              (!props.margin && width <= 1395) ||
              (props.margin && width <= 1150)
                ? "76px"
                : "56px",
            width: "100%",
            padding: "0 16px",
            borderRadius: "0 0 18px 18px",
            marginTop: width >= 768 ? "-30px" : props.mytol ? "-70px" : "-20px",
            position: "sticky",
            zIndex: "1000",
            top: width >= 768 ? "-30px" : "-5px",
            marginBottom: "30px",
            boxShadow:
              currentProfile?.settings?.theme?.themeName === "orange" &&
              width <= 1200
                ? "rgb(255, 255, 255) 0px 1px 8px"
                : "unset",
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flexGrow: width >= 768 ? 1 : 0,
              ...(width <= 767 && { marginLeft: "auto" }),
            }}
          >
            {width >= 985 ? (
              <img
                width={114}
                height={45}
                src={logo}
                alt="the only link logo"
                style={
                  props.margin
                    ? { marginLeft: `${props.margin}%` }
                    : width <= 1200
                    ? { marginLeft: "5%" }
                    : {}
                }
              />
            ) : null}
            <div
              style={{
                color: "#fff",
                ...(props.margin &&
                  width >= 1200 && {
                    marginLeft: "50px",
                    marginRight: "50px",
                  }),
                ...(width <= 1200 && {
                  marginLeft: "5px",
                  marginRight: "5px",
                }),

                fontSize:
                  width <= 650
                    ? "12px"
                    : width <= 837
                    ? "13px"
                    : width >= 1201 && width <= 1229
                    ? "11px"
                    : width >= 1230 && width <= 1315
                    ? "12px"
                    : width >= 900
                    ? "14px"
                    : props.margin
                    ? "16px"
                    : "14px",
              }}
            >
              <span
                style={{
                  fontWeight: "bolder",
                  marginRight: "5px",
                }}
              >
                Get 2 Months Free!
              </span>
              Subscribe to the annual plan and enjoy 12 Months for the Price of
              10. Get full access, save more, and maximize your Only Link
              experience!
            </div>
            <button
              onClick={handleClickClaimOffer}
              className="btn_desktop btn"
              style={{
                marginTop: 0,
                marginBottom: 0,
                height: "40px",
                minWidth:
                  width <= 400
                    ? "65px"
                    : width >= 1201 && width <= 1315 && !props.margin
                    ? "84px"
                    : "94px",
                width: "110px",
                font: "Gilroy",
                lineHeight: "16px",
                fontSize: "14px",
                display: "flex",
                alignItems: "center",
                borderRadius: "8px",
                color: "#FC7B52",
                background: "#FFFFFF",
                justifyContent: "center",
              }}
            >
              Claim Offer
            </button>
          </div>

          <button
            style={{
              background: "transparent",
              border: "none",
              color: "#fff",
              marginLeft: "7px",
              minWidth: "14px",
              height: "24px",
            }}
            onClick={handleClickClose}
          >
            <CloseIcon />
          </button>
        </div>
      ) : null}
    </>
  );
};

export default Banner;

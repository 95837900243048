import React, { useState, useEffect } from "react";
import { useUser } from "../../../providers/user.provider";

import style from "./ContactSupport.module.scss";
import save from "../../../assets/svg/save.svg";

import { TextField, SelectChangeEvent } from "@mui/material";
import { useApi } from "../../../providers/api.provider";
import { Link, useNavigate } from "react-router-dom";
import { useWindowDimensions } from "../../../services/widthCheck.service";
import PreviewPageHome from "../../ui/PreviewPageHome/PreviewPageHome";

interface IErrors {
  email: string | null;
  fullName: string | null;
  subject: string | null;
  message: string | null;
}

const ContactSupport = () => {
  const { width } = useWindowDimensions();
  const { support } = useApi();
  const navigate = useNavigate();

  const user = useUser();

  const [email, setEmail] = useState<string>("");
  const [fullName, setFullName] = useState<string>("");
  const [subject, setSubject] = useState<string>("");
  const [message, setMessage] = useState<string>("");
  const [sendDisabled, setSendDisabled] = useState<boolean>(true);
  const [errors, setErrors] = useState<IErrors>({
    email: null,
    fullName: null,
    subject: null,
    message: null,
  });

  useEffect(() => {
    if (user) {
      setEmail(user?.user?.email ? user?.user?.email : "");
      setFullName(user?.user?.lastName + " " + user?.user?.firstName);
    }
  }, [user]);

  const clickBack = () => navigate("/settings/main");

  const hanldeClickEmail = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value.trim());
    setSendDisabled(false);
  };

  const hanldeClickFullName = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFullName(event.target.value.replace(/^\s+/g, ""));
    setSendDisabled(false);
  };

  const hanldeClickSubject = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSubject(event.target.value.replace(/^\s+/g, ""));
    setSendDisabled(false);
  };

  const hanldeClickMessage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setMessage(event.target.value);
    setSendDisabled(false);
  };

  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  const validateForm = (): any => {
    const errs = { ...errors };
    let res = true;
    if (!email || email.length === 0) {
      errs.email = "Email is required";
      res = false;
    } else if (!emailRegex.test(email)) {
      errs.email = "Incorrect email";
      res = false;
    } else {
      errs.email = null;
    }

    if (!fullName || fullName.length === 0) {
      errs.fullName = "FullName is required!";
      res = false;
    } else {
      errs.fullName = null;
    }

    if (!subject || subject.length === 0) {
      errs.subject = "Subject is required!";
      res = false;
    } else {
      errs.subject = null;
    }

    if (!message || message.length === 0) {
      errs.message = "Message is required!";
      res = false;
    } else {
      errs.message = null;
    }

    setErrors(errs);
    return res;
  };

  const handleClickSend = () => {
    if (validateForm()) {
      support
        .contacts({
          email: email,
          fullName: fullName,
          subject: subject,
          message: message,
        })
        .then((res: any) => {
          setSendDisabled(true);
        });
    }
  };

  return (
    <div className="main-content">
      <div className={`${style.acc_edit} bg-gray_light account-edit`}>
        {width >= 768 && (
          <div className="page-title">
            <h2 className="text-center text-black">Contact Support</h2>
          </div>
        )}

        <div className={`${style.container} container`}>
          <div className="page_top">
            <button
              type="button"
              className="btn_navigate btn_navigate_arrow"
              onClick={clickBack}
            >
              Back
            </button>
            {width < 768 ? (
              <h2 className="text-black text-center ff-gilroy">
                Contact Support
              </h2>
            ) : null}
          </div>

          <div className={`${style.acc_edit__wrapper} edit-card`}>
            <div className={`mb-20 flex-1`}>
              <div className={style.acc_edit__col}>
                <TextField
                  id="email"
                  label={email ? "" : "Email"}
                  name="email"
                  variant="outlined"
                  value={email}
                  onChange={hanldeClickEmail}
                  error={!!errors.email}
                  helperText={errors.email}
                />
                <TextField
                  id="name"
                  label={fullName ? "" : "Your Last and First name"}
                  name="name"
                  variant="outlined"
                  value={fullName}
                  onChange={hanldeClickFullName}
                  error={!!errors.fullName}
                  helperText={errors.fullName}
                />
                <TextField
                  id="subject"
                  label={subject ? "" : "Subject"}
                  name="subject"
                  variant="outlined"
                  value={subject}
                  onChange={hanldeClickSubject}
                  error={!!errors.subject}
                  helperText={errors.subject}
                />
                <TextField
                  id="message"
                  label={message ? "" : "Message"}
                  name="message"
                  variant="outlined"
                  multiline={true}
                  rows={6}
                  value={message}
                  onChange={hanldeClickMessage}
                />
              </div>

              <button
                type="submit"
                className="btn icon icon__center btn_desktop"
                disabled={sendDisabled}
                onClick={handleClickSend}
              >
                {!sendDisabled ? "Send" : "Was sent"}
              </button>
            </div>
          </div>
        </div>

        {width > 767 && (
          <div className="right_bar right_bar_preview-window">
            <h4 className="ff-gilroy fw-700 l-spacing text-center">Preview</h4>
            <div className="right_bar__container">
              <PreviewPageHome />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default ContactSupport;

import React, { useEffect, useState } from "react";
import {
  IInterestContext,
  ILinkContext,
  IProfileContext,
  ISocialContext,
  IUserContext,
  IPersonalizationContext,
  IUserContextType,
  IDelegateProfilesContext,
  IDelegateUserContext,
  IOLinkContext,
  INotification,
  IEventContext,
} from "../interfaces/user.context";
import { useApi } from "./api.provider";
import { ISocialType } from "../interfaces/social.interface";
import { getRandomColor } from "../services/utilites.service";
import moment from "moment";
import axios from "axios";
import timezones from "../timezones";
import { useLocation, useNavigate } from "react-router-dom";
import { ReactTagManager } from "react-gtm-ts";

const UserContext = React.createContext<IUserContextType>(null!);

export function UserProvider({ children }: { children: React.ReactNode }) {
  const api = useApi();
  const navigate = useNavigate();

  const [user, setUser] = useState<IUserContext>({
    id: null,
    created_at: null,
    updated_at: null,
    firstName: null,
    lastName: null,
    timezone: null,
    email: null,
    confirmed: null,
    isActive: null,
    role: null,
    selectedProfile: null,
    access: null,
    accessed_at: null,
  });
  const [userProfiles, setUserProfiles] = useState<IProfileContext[]>([]);
  const [delegates, setDelegates] = useState<IDelegateProfilesContext[]>([]);
  const [currentProfile, setCurrentProfile] = useState<any>({
    //IProfileContext
    id: null,
    created_at: null,
    updated_at: null,
    fullName: null,
    nickname: null,
    location: null,
    avatar: null,
    olinks: null,
    isActive: null,
    title: null,
    description: null,
  });
  const [currentOLink, setCurrentOLink] = useState<IOLinkContext | null>(null);
  const [listOlink, setListOlink] = useState<any>([]);
  const [profileInterest, setProfileInterest] = useState<IInterestContext>({
    id: null,
    name: null,
  });
  const [profileLinks, setProfileLinks] = useState<ILinkContext[]>([]);
  const [profileSocials, setProfileSocials] = useState<ISocialContext[]>([]);
  const [profileDelegates, setProfileDelegates] = useState<
    IDelegateUserContext[]
  >([]);
  const [socialTypes, setSocialTypes] = useState<ISocialType[]>([]);
  const [banner, setBanner] = useState<boolean>(true);
  const [userSocials, setUserSocials] = useState<ISocialContext[]>([]);
  const [selectedOlinkSetting, setSelectedOlinkSetting] =
    useState<IProfileContext | null>(null);
  const [personalizate, setPersonalizate] = useState<IPersonalizationContext>({
    themeName: "default",
    color: { background: "", type: "" },
    btn: {
      rounding: false,
      outline: false,
      white: false,
      shadow: false,
    },
    fontsName: null,
    statusLogo: true,
  });

  const [previewImage, setPreviewImage] = useState<any>([]);
  const [timezoneOffset, setTimezoneOffset] = useState<number>(0);
  const [timezone, setTimezone] = useState<string>("Europe/London");

  const [qrCode, setQrCode] = useState<any>({
    width: 140,
    height: 140,
    dotsOptions: {
      color: "#000",
      type: "extra-rounded",
    },
    cornersSquareOptions: {
      type: "none",
      color: "#000",
    },
    cornersDotOptions: {
      type: "none",
      color: "#000",
    },
    imageOptions: {
      crossOrigin: "anonymous",
      // imageSize: 0.5,
      margin: 10,
    },
    backgroundOptions: {
      color: "#fff",
    },
  });

  const [notifications, setNotifications] = useState<INotification[]>([]);
  const [listLocales, setListLocales] = useState<any>("");
  const [listLocations, setListLocations] = useState<any>("");
  const [events, setEvents] = useState<any[]>([]);
  const [inActiveEvents, setInActiveEvents] = useState<any>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [payment, setPayment] = useState<any>([]);
  const [previousAccessToken, setPreviousAccessToken] =
    useState<boolean>(false);
  const setStates = (profile: IProfileContext) => {
    const { userSocials, links, olinks, settings, events } = profile;
    setCurrentProfile(profile);

    let olink = olinks?.find((o) => o.state === true);
    if (olink) {
      setCurrentOLink(olink);
    }

    setProfileSocials(userSocials!);

    setProfileLinks(
      links!.sort((a, b) => {
        return a.order! - b.order!;
      })
    );

    if (events) {
      setEvents(events);
    }
  };
  const key = "pk_31b9b73cb71db2285b225a742af7f49744e1480d";
  const getPreview = async () => {
    const result: any = [];
    await Promise.all(
      profileLinks.map((item) => {
        if (item.type === "audio" || item.type === "video") {
          return axios
            .get(
              `https://links.theonly.link/services/link-data/extract-v4-2?url=${item.url}`,
              {
                headers: {
                  "Content-Type": "application/json",
                  "Accept": "*",
                  // "Accept-Encoding": "gzip, deflate, br",
                  "Access-Control-Allow-Origin": "*",
                  "Access-Control-Allow-Methods": "GET",
                  "Access-Control-Allow-Headers": "Content-Type",
                  // "Access-Control-Allow-Credentials": "true",
                  "Cache-Control": "no-cache",
                  // "Host": "links.theonly.link",
                },
              },
              //"https://jsonlink.io/api/extract?url=" +
              // item.url +
              // `&api_key=${key}`
              // `&api_key=${process.env.REACT_APP_PREVIEW_KEY}`
            )
            .then((res) => {
              if (
                res.data.image !== undefined &&
                res.data.image !== null
                // res.data.images !== undefined &&
                // res.data.images?.length > 0
              ) {
                let resUrl = res.data.image
                // let resUrl = res.data.images[0]
                  .replace("http://", "https://");
                let idUrl = item.id;
                result.push({ url: resUrl, id: idUrl });
              }
            })
            .catch((error) => {
              console.error("An error occurred:", error);
            });
        }
      })
    );
    setPreviewImage(result);
  };

  const settingsUserData = async (u: any) => {
    if (u) {
      u.profile.role = u.role;
      let olink = u.profile?.olinks?.find((o: any) => o.state === true);
      if (olink) {
        setCurrentOLink(olink);
      }
      const { profile, ...newUserData } = u;
      setUser(newUserData);
      if (u.profile.interest) {
        setProfileInterest(u.profile?.interest);
      }
      if (u.profile?.settings?.theme) {
        const { theme } = u.profile?.settings;

        setPersonalizate({
          themeName: theme.themeName,
          color: {
            background: theme.color_background,
            type: theme.color_type,
          },
          btn: {
            rounding: theme.btn_rounding,
            outline: theme.btn_outline,
            white: theme.btn_white,
            shadow: theme.btn_shadow,
          },
          fontsName: theme.fontsName,
          statusLogo: theme.statusLogo,
        });
      }
      if (u.profile?.settings) {
        if (u.profile?.settings?.qrcode) {
          let isSetQrCode = false;
          let qrCodeOptions = {};
          if (
            u.profile?.settings.qrcode &&
            u.profile?.settings.qrcode.options
          ) {
            qrCodeOptions = {
              ...qrCodeOptions,
              ...JSON.parse(u.profile?.settings.qrcode.options),
            };
            isSetQrCode = true;
          }

          if (
            u.profile?.settings.qrcode.logo &&
            u.profile?.settings.qrcode.logo.url
          ) {
            qrCodeOptions = {
              ...qrCodeOptions,
              image: u.profile?.settings.qrcode.logo.url,
              imageOptions: {
                crossOrigin: "anonymous",
                imageSize: 0.5,
                margin: 10,
              },
            };
            isSetQrCode = true;
          }

          if (isSetQrCode) {
            setQrCode(qrCodeOptions);
          }
        }
      }
      setCurrentProfile(u?.profile);
      if (u.timezone) {
        if (timezones.hasOwnProperty(u.timezone)) {
          const offsetStr = extractTimeOffset(timezones[u.timezone]) || "";
          const offsetHours = extractOffsetHours(offsetStr);
          setTimezoneOffset(Number(offsetHours) || 0);
          setTimezone(u.timezone);
        } else {
          if (u.timezone.includes("GMT")) {
            const offsetStr = extractTimeOffset(u.timezone) || "";
            const offsetHours = extractOffsetHours(offsetStr);
            setTimezoneOffset(Number(offsetHours) || 0);

            for (let timezone of Object.keys(timezones)) {
              // TODO - Unfortunatelly we had previously wrong writing of Kyiv so made that for compatibility for old accounts
              if (timezones[timezone] === u.timezone?.replace("Kiev", "Kyiv")) {
                setTimezone(timezone);
                break;
              }
            }
          }
        }
      }
      await api.social.userSocials().then((res) => {
        setUserSocials(res);
      });
      await api.link.getLinks().then((res) => {
        const newArray = [...res];
        const indexProlink = res.findIndex((el: any) => el.type === "prolink");

        if (indexProlink !== -1) {
          const prolinkElement = newArray.splice(indexProlink, 1)[0];
          newArray.unshift(prolinkElement);
        }

        newArray.sort((itemOne, itemTwo) => itemOne.order - itemTwo.order);
        setProfileLinks(newArray);
      });
      api.user.getNotifications().then(
        (res) => {
          const notifications = res.sort((a, b) => {
            return (
              moment(b.updated_at).valueOf() - moment(a.updated_at).valueOf()
            );
          });
          setNotifications(notifications);
        },
        (error) => {}
      );
      api.listLocations.getLocations().then(
        (res) => {
          setListLocations(res.data[0]);
        },
        (error) => {}
      );
      api.listLocale.getLocales().then(
        (res) => {
          setListLocales(res.data[0]);
        },
        (error) => {}
      );
    }
  };

  const location = useLocation();

  useEffect(() => {
    if (location.pathname !== "/settings/plans") {
      sessionStorage.removeItem("CancelSubscription");
      sessionStorage.removeItem("freePlan");
    }
  }, [location]);

  useEffect(() => {
    const nav = location.pathname;
    const authRoutes = [
      "/auth/signin",
      "/auth/signup",
      "/auth/code",
      "/auth/forgot",
    ];

    if (!authRoutes.includes(nav) && !previousAccessToken) {
      sessionStorage.setItem("navigate", nav);
    }

    if (user.id === null && api.accessToken) {
      api.user.me().then(
        async (u) => {
          const action = localStorage.getItem("google_login");
          if (action) {
            if (JSON.parse(action as string) === "google_login") {
              ReactTagManager.action({
                event: "log_in",
                event_category: "User",
                event_label: "Log in",
                user_email: u.email,
              });
              localStorage.removeItem("google_login");
            }
          }
          if (authRoutes.includes(nav)) {
            navigate(sessionStorage.getItem("navigate") as string);
          }
          sessionStorage.removeItem("navigate");
          return await settingsUserData(u);
        },
        (error) => {}
      );
    }
  }, [api.accessToken]);

  useEffect(() => {
    const fetchPaymentLinks = async () => {
      try {
        const res = await api.user.getPaymentLinks();
        setPayment(res);
      } catch (error) {}
    };

    fetchPaymentLinks();
  }, [api.accessToken]);

  useEffect(() => {
    if (profileLinks.length) {
      getPreview();
    }
  }, [profileLinks.length]);

  let value: IUserContextType = {
    user,
    setUser,
    userProfiles,
    setUserProfiles,
    delegates,
    setDelegates,
    currentProfile,
    setCurrentProfile,
    currentOLink,
    setCurrentOLink,
    profileInterest,
    setProfileInterest,
    profileLinks,
    setProfileLinks,
    profileSocials,
    setProfileSocials,
    profileDelegates,
    setProfileDelegates,
    socialTypes,
    setSocialTypes,
    userSocials,
    setUserSocials,
    selectedOlinkSetting,
    setSelectedOlinkSetting,
    personalizate,
    setPersonalizate,
    notifications,
    setNotifications,
    events,
    setEvents,
    loading,
    setLoading,
    qrCode,
    setQrCode,
    listLocations,
    setListLocations,
    listLocales,
    setListLocales,
    previewImage,
    setPreviewImage,
    inActiveEvents,
    setInActiveEvents,
    listOlink,
    setListOlink,
    timezoneOffset,
    setTimezoneOffset,
    timezone,
    setTimezone,
    payment,
    setPayment,
    previousAccessToken,
    setPreviousAccessToken,
    banner,
    setBanner,
  };

  return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
}

export function useUser() {
  return React.useContext(UserContext);
}

export function extractOffsetHours(str: string): number | null {
  const match = str.match(/([+-])(\d{2}):(\d{2})$/);
  if (match && match.length > 3) {
    const sign = match[1]; // Sign (+ or -)
    const hours = parseInt(match[2]); // Hours as a number
    const minutes = parseFloat(match[3]) / 60;
    return parseFloat(sign + (hours + minutes).toFixed(2)); // Adjust for positive or negative offset
  }
  return null;
}

export function extractTimeOffset(str: string): string | null {
  const match = str.match(/\(GMT([+-][\d]{2}:[0-5]{1}[0-9]{1})\)/);
  if (match && match.length > 1) {
    return match[1];
  }
  return null;
}

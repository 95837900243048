// import { EUserRoles, IRelativeUser } from './user.interface';
import { ELinkType } from "./link.interface";
import { ISocialType } from "./social.interface";
import { ERolesEnum } from "../enums/roles.enum";

export interface ISocialsContext {
  id: number;
  url: string;
  name: string;
  type?: string;
  selected: boolean;
  order: number;
}

export interface IAvatarUser {
  id: number;
  key: number;
  url: string;
}

export interface IDelegateProfilesContext {
  id: number | any;
  delegates?: any;
  accepted: boolean | any;
  profile: IProfileContext | any;
  color?: string;
}

export interface IDelegateUserContext {
  id: number | null;
  accepted: boolean | null;
  delegatedUser: IUserDelegateContext | null;
}

export interface IUserDelegateContext {
  id: number;
  firstName: string;
  lastName: string;
  email: string;
  isActive: boolean;
}

export interface IUserContext {
  id: number | null;
  created_at: string | null;
  updated_at: string | null;
  firstName: string | null;
  lastName: string | null;
  timezone: string | null;
  email: string | null;
  confirmed: boolean | null;
  isActive: boolean | null;
  role: ERolesEnum | null;
  selectedProfile: number | null;
  access?: string | null;
  accessed_at?: Date | null;
  typeSubscription?: string | null;
}

export interface IOlinksNamesContext {
  id?: number;
  created_at?: string;
  name: string;
}

export interface IOLinkContext {
  id: number;
  state: boolean;
  path: string;
  olinksNames?: IOlinksNamesContext[];
}

export interface ISettingsContext {
  id: number;
  theme: {
    btn_outline: boolean;
    btn_rounding: boolean;
    btn_shadow: boolean;
    btn_white: boolean;
    color_background: string;
    color_type: string;
    created_at: string;
    fontsName: string;
    id: number;
    statusLogo: boolean;
    themeName: string;
    updated_at: string;
  };
  qrcode: any;
}

export interface IProfileContext {
  id: number | null;
  created_at: string | null;
  updated_at: string | null;
  fullName: string | null;
  nickname: string | null;
  location: string | null;
  title?: string | null;
  description?: string | null;
  olinks?: IOLinkContext[] | null;
  isActive: boolean | null;
  avatar: IAvatarUser | any;
  interest?: IInterestContext | null;
  userSocials?: ISocialsContext[] | null;
  links?: ILinkContext[] | null;
  delegates?: IDelegateUserContext[] | null | any;
  settings?: ISettingsContext;
  events?: any;
  color?: string;
  isDelegated?: boolean;
  role?: string;
}

export interface IInterestContext {
  id: number | null;
  name: string | null;
}

export interface ILinkContext {
  id: number;
  created_at?: string;
  updated_at?: string;
  type: ELinkType | null;
  title: string | null;
  url: string | null;
  visible: boolean | null;
  order: number | null;
  cover?: { id: number | null; key: string | null; url: string | null };
  currentUrl?: string | null;
}

export interface ISocialContext {
  id: number;
  url: string;
  selected: boolean;
  name: string;
  order: number;
}

export interface IRelativeUserContext {
  id: number;
  created_at: Date;
  updated_at: Date;
  email: string;
  firstName: string;
  lastName: string;
  confirmed: boolean;
  isActive: boolean;
}

export interface IPersonalizationColor {
  background: string;
  type: string;
}

export interface IPersonalizationBtn {
  rounding: boolean;
  outline: boolean;
  white: boolean;
  shadow: boolean;
}

export interface IPersonalizationContext {
  themeName: string;
  color: IPersonalizationColor;
  btn: IPersonalizationBtn;
  fontsName: string | null;
  statusLogo: boolean;
}

export interface IUserContextType {
  user: IUserContext;
  setUser: any;

  userProfiles: any;
  setUserProfiles: any;

  delegates: any;
  //IDelegateProfilesContext[];
  setDelegates: any;

  currentProfile: IProfileContext;
  setCurrentProfile: any;

  currentOLink: IOLinkContext | null;
  setCurrentOLink: any;

  profileInterest: IInterestContext;
  setProfileInterest: any;

  profileLinks: ILinkContext[];
  setProfileLinks: any;

  profileSocials: ISocialContext[];
  setProfileSocials: any;

  profileDelegates: IDelegateUserContext[];
  setProfileDelegates: any;

  socialTypes: ISocialType[];
  setSocialTypes: any;

  userSocials: ISocialContext[];
  setUserSocials: any;

  selectedOlinkSetting: any | null;
  setSelectedOlinkSetting: any;

  personalizate: IPersonalizationContext;
  setPersonalizate: any;

  notifications: INotification[];
  setNotifications: any;

  events: any;
  setEvents: any;

  loading: boolean;
  setLoading: any;

  qrCode: any;
  setQrCode: any;

  listLocations: any;
  setListLocations: any;

  listLocales: any;
  setListLocales: any;

  previewImage: any;
  setPreviewImage: any;

  inActiveEvents: any;
  setInActiveEvents: any;
  listOlink: any;
  setListOlink: any;

  timezoneOffset: number;
  setTimezoneOffset: any;

  timezone: string;
  setTimezone: any;

  payment: any;
  setPayment: any;

  previousAccessToken: any;
  setPreviousAccessToken: any;
  banner: any;
  setBanner: any;
}

export interface ITimezone {
  [key: string]: string;
}

export enum ENotificationType {
  events,
  payment,
  statistic,
  delegate,
}

export enum ENotificationStatus {
  new,
  read,
}

export interface INotification {
  id: number;
  created_at: string;
  updated_at: string;
  type: ENotificationType;
  date_start: string;
  date_end: string;
  title: string;
  message: string;
  status: ENotificationStatus;
}

export interface IEventContext {
  id: number;
  title: string;
  url: string;
  duration_state: boolean;
  duration_start?: string;
  duration_end?: string;
  device_state: boolean;
  device_mobile?: string;
  device_desktop?: string;
  device_tablet?: string;
  source_state: boolean;
  source?: string;
  browsers_state: boolean;
  browsers?: string;
  locale_state: boolean;
  locale: string;
  location_state: boolean;
  location: string;
  state: string;
  qr_codes: [];
  qr_code_state: boolean;
  created_at?: string;
  updated_at?: string;
}

import React, { useEffect } from "react";

import styles from "./Notification.module.scss";
import emoji from "../../../assets/svg/emoji/nice.svg";

import { useNavigate } from "react-router-dom";
import Banner from "../../ui/Banner/Banner";

import { useUser } from "../../../providers/user.provider";
import {
  ENotificationStatus,
  ENotificationType,
  INotification,
} from "../../../interfaces/user.context";
import moment from "moment";
import { useApi } from "../../../providers/api.provider";
import { useWindowDimensions } from "../../../services/widthCheck.service";
import PreviewPageHome from "../../ui/PreviewPageHome/PreviewPageHome";

const Notification = () => {
  const navigate = useNavigate();
  const user = useUser();
  const api = useApi();
  const { width } = useWindowDimensions();

  const colors = {
    yellow: "#FFD15F",
    green: "#7EDE93",
    orange: "#FC7B52",
    red: "#FF6251",
  };

  const getColor = (item: INotification) => {
    switch (item.type) {
      case ENotificationType.delegate:
        return colors.orange;
      case ENotificationType.events:
        return colors.yellow;
      case ENotificationType.payment:
        return colors.green;
      case ENotificationType.statistic:
        return colors.red;
      default:
        return colors.green;
    }
  };

  const getIcon = (item: INotification) => {
    let content = "";
    let color = "";
    switch (item.type) {
      case ENotificationType.events:
        color = colors.orange;
        content = `<rect x="3.71429" y="7.14258" width="20.5714" height="17.1429" rx="2" stroke='${color}' stroke-width="1.6"/>
                 <path d="M3.71429 9.88574H24.2857V11.4857H3.71429V9.88574Z" fill='${color}'/>
                 <path d="M8.28572 3.71436L8.28572 7.14293" stroke='${color}' stroke-width="1.6" stroke-linecap="round"/>
                 <path d="M19.7143 3.71436L19.7143 7.14293" stroke='${color}' stroke-width="1.6" stroke-linecap="round"/>
                 <rect x="8.28572" y="14" width="4.57143" height="1.82857" rx="0.5" fill='${color}'/>
                 <rect x="8.28572" y="18.5713" width="4.57143" height="1.82857" rx="0.5" fill='${color}'/>
                 <rect x="15.1429" y="14" width="4.57143" height="1.82857" rx="0.5" fill='${color}'/>
                 <rect x="15.1429" y="18.5713" width="4.57143" height="1.82857" rx="0.5" fill='${color}'/>`;
        break;
      case ENotificationType.payment:
        color = colors.green;
        content = `<path d="M9.24898 21.9634H16.5656C17.3466 21.9672 18.1007 21.6336 18.6803 21.0279C19.2599 20.4222 19.6233 19.588 19.6994 18.6886C19.7754 17.7892 19.5587 16.8893 19.0916 16.1651C18.6244 15.4408 17.9405 14.9444 17.1737 14.7729L10.8263 13.7614C10.0595 13.5899 9.3756 13.0934 8.90846 12.3692C8.44132 11.645 8.22457 10.7451 8.30064 9.84569C8.3767 8.94628 8.74011 8.1121 9.31972 7.50639C9.89934 6.90069 10.6534 6.56707 11.4345 6.57083H18.751" stroke=${color} stroke-width="1.6" stroke-linecap="round" stroke-linejoin="round"/>
                 <path d="M14.7506 5.58449L14.7506 3.71436" stroke=${color} stroke-width="1.6" stroke-linecap="round" stroke-linejoin="round"/>
                 <path d="M14.7506 24.2857L14.7506 22.4155" stroke=${color} stroke-width="1.6" stroke-linecap="round" stroke-linejoin="round"/>`;
        break;
      case ENotificationType.statistic:
        color = colors.orange;
        content = `<path d="M9.42857 11.7139L9.42857 18.571" stroke=${color} stroke-width="1.6" stroke-linecap="round" stroke-linejoin="round"/>
                 <path d="M14 14V18.5714" stroke=${color} stroke-width="1.6" stroke-linecap="round" stroke-linejoin="round"/>
                 <path d="M18.5714 9.42822V18.5711" stroke=${color} stroke-width="1.6" stroke-linecap="round" stroke-linejoin="round"/>
                 <rect x="3.71429" y="4.85693" width="20.5714" height="18.2857" rx="2" stroke=${color} stroke-width="1.6"/>`;
        break;
      case ENotificationType.delegate:
        color = colors.orange;
        content = `<rect x="22" y="10.5713" width="4.57143" height="4.57143" rx="2.28571" transform="rotate(90 22 10.5713)" stroke=${color} stroke-width="1.6"/>
                 <rect x="22" y="19.7144" width="4.57143" height="4.57143" rx="2.28571" transform="rotate(90 22 19.7144)" stroke=${color} stroke-width="1.6"/>
                 <rect x="4.85715" y="8.28564" width="4.57143" height="4.57143" rx="2.28571" transform="rotate(-90 4.85715 8.28564)" stroke=${color} stroke-width="1.6"/>
                 <path d="M7.14287 9.42871V18.0001C7.14287 19.8858 7.14287 20.8286 7.72865 21.4144C8.31444 22.0001 9.25725 22.0001 11.1429 22.0001H17.4286" stroke=${color} stroke-width="1.6"/>
                 <path d="M7.14287 8.28564V8.85707C7.14287 10.7427 7.14287 11.6855 7.72865 12.2713C8.31444 12.8571 9.25725 12.8571 11.1429 12.8571H17.4286" stroke=${color} stroke-width="1.6"/>`;
        break;
    }

    return (
      <svg
        width="28"
        height="28"
        viewBox="0 0 28 28"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        dangerouslySetInnerHTML={{ __html: content }}
      />
    );
  };

  const getTimeLeft = (item: INotification) => {
    const now = moment();
    const updated_time = moment(item.updated_at);

    const time = now.diff(updated_time, "minute");
    if (time > 1440) {
      return now.diff(updated_time, "days") + "d.";
    } else if (time > 60) {
      return now.diff(updated_time, "hours") + "h.";
    } else {
      return time + "m.";
    }
  };

  useEffect(() => {
    const data = user.notifications
      .filter((n) => n.status === ENotificationStatus.new)
      .map((n) => {
        if (n.status === ENotificationStatus.new) {
          return n.id;
        }
      });
    if (data && data.length > 0) {
      api.user.setNotifications(data as number[]).then((res) => {});
    }
  });

  return (
    <div className="main-content">
      <div className={`${styles.notification} bg-gray_light`}>
        {width >= 768 && (
          <div className="page-title">
            <h2 className="text-center text-black">Notification</h2>
          </div>
        )}
        <div className={`container`}>
          <Banner />
          {width < 768 ? (
            <div className="page_top">
              <h2 className="text-black text-center ff-gilroy">Notification</h2>
            </div>
          ) : null}

          {user.notifications.length > 0 ? (
            <div className={`${styles.notification__timeline} mt-20 card`}>
              <>
                {user.notifications.map((item, index) => {
                  return (
                    <div
                      key={index}
                      className={`${styles.timeline} ${
                        item.status === ENotificationStatus.read
                          ? styles.timeline__opened
                          : null
                      }`}
                    >
                      <picture
                        className={styles.timeline__icon}
                        style={{ borderColor: getColor(item) }}
                      >
                        {getIcon(item)}
                      </picture>

                      <div className={styles.timeline__text}>
                        <h6>{item.message}</h6>
                        {/*{item.message ? <p>{item.message}</p> : null}*/}
                      </div>

                      <time className={styles.timeline__time}>
                        {getTimeLeft(item)}
                      </time>
                    </div>
                  );
                })}
              </>
            </div>
          ) : (
            <div className={styles.notification__empty}>
              <figure>
                <img src={emoji} alt="emoji nice" />
              </figure>

              <h5
                className="ff-gilroy
                l-spacing_xs text-black mb-20 text-center fw-600"
              >
                No notifications yet
              </h5>

              <p className="text-center text-gray_dark">
                Notifications will be displayed here
              </p>
            </div>
          )}
        </div>

        {width > 767 && (
          <div className="right_bar right_bar_preview-window">
            <h4 className="ff-gilroy fw-700 l-spacing text-center">Preview</h4>
            <div className="right_bar__container">
              <PreviewPageHome />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Notification;

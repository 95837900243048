import React, { useState } from "react";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";

interface PropsName {
  name: string;
}
const Switches = (props: PropsName) => {
  const [activeBtn, setActiveBtn] = useState(false);

  const switchHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    setActiveBtn(event.target.checked);
  };

  return (
    <div>
      <FormControlLabel
        control={
          <Switch
            id={props.name}
            checked={activeBtn}
            onChange={(e) => switchHandler(e)}
          />
        }
        label={props.name}
      />
    </div>
  );
};

export default Switches;

import React, { useEffect, useRef, useState, useMemo } from "react";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import { useNavigate, Link } from "react-router-dom";
import { useApi } from "../../../providers/api.provider";
import { useUser } from "../../../providers/user.provider";

import tickCircle from "../../../assets/svg/tick-circle.svg";
import medal from "../../../assets/svg/medal-star.svg";

import styles from "./Premium.module.scss";
import { useWindowDimensions } from "../../../services/widthCheck.service";
import Tabs from "../../ui/Tabs/Tabs";
import Banner from "../../ui/Banner/Banner";

import moment from "moment";

const descriptionsForPayment: Record<string, any> = {
  "Premium Year": {
    feature2: "20 TOL with 3 per TOL custom QR codes.",
    feature3: "TOL logo can be hidden.",
    feature4: "5 delegate links",
    feature5: "Smart Redirects: 10 active.",
    feature6: "Lifetime Statistics",
    feature7: "Pro Link",
    feature8: "Subscription",
  },
  Premium: {
    feature2: "20 TOL with 3 per TOL custom QR codes.",
    feature3: "TOL logo can be hidden.",
    feature4: "5 delegate links",
    feature5: "Smart Redirects: 10 active.",
    feature6: "Lifetime Statistics",
    feature7: "Pro Link",
  },
  "Pro Year": {
    feature2: "3 TOL with 3 per TOL custom QR codes.",
    feature3: "TOL logo can be hidden.",
    feature4: "3 delegate links",
    feature5: "Smart Redirects: 10 active.",
    feature6: "Lifetime Statistics",
    feature7: "Pro Link",
    feature8: "Subscription",
  },
  Pro: {
    feature2: "3 TOL with 3 per TOL custom QR codes.",
    feature3: "TOL logo can be hidden.",
    feature4: "3 delegate links",
    feature5: "Smart Redirects: 10 active.",
    feature6: "Lifetime Statistics",
    feature7: "Pro Link",
  },
};

const shortUsernames: Record<string, any> = {
  "Premium Year": {
    feature2: "1-character - €390/year",
    feature3: "2-character - €290/year",
    feature4: "3-4 character - €190/year",
  },
  Premium: {
    feature2: "1-character - €390/year",
    feature3: "2-character - €290/year",
    feature4: "3-4 character - €190/year",
  },
  "Pro Year": {
    feature2: "1-character - €350/year",
    feature3: "2-character - €250/year",
    feature4: "3-4 character - €150/year",
  },
  Pro: {
    feature2: "1-character - €350/year",
    feature3: "2-character - €250/year",
    feature4: "3-4 character - €150/year",
  },
};

const specialOffer: Record<string, any> = {
  "Premium Year": "2 months free",
  // Premium: "Earlier users get $5/month",
  "Pro Year": "2 months free",
  // Pro: "Earlier users get $3/month",
};

interface IProps {
  dialog?: boolean;
  close?: any;
}
const Premium = React.memo((props: IProps) => {
  const navigate = useNavigate();
  const { width } = useWindowDimensions();
  const [selected, setSelected] = useState("Monthly");
  const api = useApi();
  const { setPayment, payment, user, setLoading, loading } = useUser();

  const handleOnBack = async () => {
    navigate(-1);
  };

  useEffect(() => {
    const fetchPaymentLinks = async () => {
      setLoading(true);
      try {
        const res = await api.user.getPaymentLinks();
        setPayment(res);
        setLoading(false);
      } catch (error) {
        setLoading(false);
      }
      setLoading(false);
    };

    fetchPaymentLinks();
  }, []);

  const handleClickTabs = (event: string) => {
    setSelected(event);
  };
  const getCurrencySymbol = (currencyCode: string | undefined): string => {
    if (!currencyCode) return "";

    const formattedParts = new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: currencyCode,
    }).formatToParts(1);

    const currencyPart = formattedParts.find(
      (part) => part.type === "currency"
    );

    return currencyPart?.value || "";
  };

  const handleClickCancel = () => {
    sessionStorage.setItem("CancelSubscription", "cancel");
  };

  const handleClickGetFree = () => {
    sessionStorage.setItem("freePlan", "free");
  };

  const handleClickClose = () => {
    localStorage.removeItem("payment");
    if (typeof props?.close === "function") {
      props?.close();
    }
  };

  return (
    <div className="main-content">
      <div
        className={`${styles.premium} olink-premium bg-gray_light`}
        style={{
          marginLeft: props?.dialog ? 0 : width >= 768 ? "255px" : 0,
          backgroundColor: props?.dialog ? "#fff" : "#f4f4f4",
        }}
      >
        {width >= 768 && !props?.dialog && (
          <div className="page-title" style={{ width: "calc(100% - 60px)" }}>
            <h2 className="text-center text-black">Plans</h2>
          </div>
        )}
        {props?.dialog && (
          <div
            className="page-title"
            style={{ position: "static", transform: "none", width: "100%" }}
          >
            <h2 className="text-center text-black">
              Get started with more options
            </h2>
          </div>
        )}
        <div className={`container`} style={{ overflowX: "hidden" }}>
          {!props?.dialog ? <Banner margin={5} plans={true} /> : ""}
          {!props?.dialog ? (
            <div className="page_top">
              <button
                type="button"
                className="btn_navigate btn_navigate_arrow"
                onClick={handleOnBack}
              >
                Back
              </button>
              {width < 768 ? (
                <h2 className="text-center ff-gilroy text-black">Premium</h2>
              ) : null}
            </div>
          ) : null}

          <div className={styles.subscription__tabs}>
            <Tabs handleClickTabs={handleClickTabs} />
          </div>

          <FormControl>
            <RadioGroup>
              {payment
                .filter((item: any) => {
                  const description = item.description;
                  return selected === "Annually"
                    ? description?.price?.recurring?.interval === "year"
                    : description?.price?.recurring?.interval === "month";
                })
                .map((item: any) => {
                  const descriptionDetails =
                    descriptionsForPayment[item.description.description];

                  const shortUsernamesDetails =
                    shortUsernames[item.description.description];

                  return (
                    <div
                      key={item.id}
                      className={`subscription-card${
                        item.description.description === "Pro"
                          ? " border-premium"
                          : ""
                      }`}
                    >
                      <div
                        className={`${styles.most__popular__badge}${
                          item.description.description !== "Pro" ? " hide" : ""
                        }`}
                      >
                        <img src={medal} alt="" width={24} height={24} />
                        Most Popular
                      </div>
                      <div className="hide">
                        <FormControlLabel
                          value="year"
                          control={<Radio />}
                          label={item.description.description}
                        />
                      </div>
                      <h3 className="subscription-card-title mb-5">
                        {item.description.description}
                      </h3>
                      {item.description?.price?.recurring?.interval ===
                      "year" ? (
                        <div className="badge border-premium mb-5">
                          <div className="premium-color">
                            {specialOffer[item.description.description]}
                          </div>
                        </div>
                      ) : (
                        ""
                      )}
                      <div className="mb-20">
                        <b className="fs-32 l-spacing">
                          {getCurrencySymbol(item.description.currency)}
                          {item.description.amount_total / 100}
                        </b>
                        &nbsp;
                        <span className="fs-16 text-uppercase fw-600">
                          {item.description.currency}
                        </span>
                        <div className="text-gray_dark_2 fw-600">
                          / {item.description.price.recurring.interval}
                        </div>
                      </div>
                      {user?.access +
                        (user?.typeSubscription === "year"
                          ? " " + user?.typeSubscription
                          : "") !==
                      item.description.description.toLowerCase() ? (
                        <Link
                          target="_blank"
                          to={
                            item.external_url + `?prefilled_email=${user.email}`
                          }
                        >
                          <button
                            className="btn__gradient mb-32"
                            disabled={
                              (user?.access === "premium" &&
                                item.description.description.toLowerCase() ===
                                  "pro") ||
                              (user?.access +
                                (user?.typeSubscription
                                  ? " " + user?.typeSubscription
                                  : "") ===
                                "premium year" &&
                                (item.description.description.toLowerCase() ===
                                  "pro year" ||
                                  item.description.description.toLowerCase() ===
                                    "premium")) ||
                              (user?.access +
                                (user?.typeSubscription
                                  ? " " + user?.typeSubscription
                                  : "") ===
                                "pro year" &&
                                (item.description.description.toLowerCase() ===
                                  "premium" ||
                                  item.description.description.toLowerCase() ===
                                    "pro"))
                            }
                            onClick={handleClickClose}
                          >
                            <span>Get started</span>
                          </button>
                        </Link>
                      ) : (
                        ""
                      )}
                      <div className="text-center mb-32">
                        {user?.access +
                          (user?.typeSubscription === "year"
                            ? " " + user?.typeSubscription
                            : "") ===
                        item.description.description.toLowerCase() ? (
                          <div
                            className={`${styles.badge__current__plan} mb-12`}
                          >
                            Your Current Plan
                          </div>
                        ) : (
                          ""
                        )}
                        {user?.access +
                          (user?.typeSubscription === "year"
                            ? " " + user?.typeSubscription
                            : "") ===
                        item.description.description.toLowerCase() ? (
                          <Link to="/settings/plans">
                            <button
                              className="btn-as-link"
                              onClick={handleClickCancel}
                            >
                              Cancel Subscription
                            </button>
                          </Link>
                        ) : (
                          ""
                        )}
                      </div>
                      <ul>
                        {Object.entries(descriptionDetails).map(
                          ([key, value]) => (
                            <li
                              key={key}
                              className="d-flex align-items-center gap-8 mb-8"
                            >
                              <img
                                src={tickCircle}
                                alt=""
                                width={24}
                                height={24}
                              />
                              {value}
                            </li>
                          )
                        )}
                      </ul>
                      <div className="mt-32">
                        <div className="fs-18 fw-600 mb-8 l-spacing_xs">
                          Short Usernames available
                        </div>
                        <ul>
                          {Object.entries(shortUsernamesDetails).map(
                            ([key, value]) => (
                              <li key={key} className="mb-8">
                                {value}
                              </li>
                            )
                          )}
                        </ul>
                      </div>
                    </div>
                  );
                })}
              <div className="subscription-card align-self-start">
                <h3 className="subscription-card-title mb-12">Free</h3>
                {!props?.dialog ? (
                  <>
                    {user.access !== "regular" ? (
                      <Link to="/settings/plans">
                        <button
                          className="btn btn-gradient-outline mb-32"
                          onClick={handleClickGetFree}
                        >
                          <span>Get Free</span>
                        </button>
                      </Link>
                    ) : (
                      <div className={`${styles.badge__current__plan} mb-32`}>
                        Your Current Plan
                      </div>
                    )}
                  </>
                ) : (
                  <button
                    className="btn btn-gradient-outline mb-32"
                    onClick={handleClickClose}
                  >
                    <span>Get Free</span>
                  </button>
                )}
                <ul>
                  <li className="d-flex align-items-center gap-8 mb-8">
                    <img src={tickCircle} alt="" width={24} height={24} />1 TOL
                    + 1 QR code
                  </li>
                  <li className="d-flex align-items-center gap-8 mb-8">
                    <img src={tickCircle} alt="" width={24} height={24} />
                    TOL logo
                  </li>
                  <li className="d-flex align-items-center gap-8 mb-8">
                    <img src={tickCircle} alt="" width={24} height={24} />
                    Smart Redirects: 1 active at a time
                  </li>
                  <li className="d-flex align-items-center gap-8 mb-8">
                    <img src={tickCircle} alt="" width={24} height={24} />
                    Statistic: 7 days
                  </li>
                </ul>
              </div>
            </RadioGroup>
          </FormControl>
        </div>

        {/* {width > 767 && (
          <div className="right_bar">
            <h4 className="ff-gilroy fw-700 l-spacing text-center">Preview</h4>
            <div className="right_bar__container"></div>
          </div>
        )} */}
      </div>
    </div>
  );
});

export default Premium;

import React from "react";
import Avatar from "@mui/material/Avatar";
import Stack from "@mui/material/Stack";
import avaSquareIcon from "../../../assets/svg/ava-square.svg";
import "./AvatarUser.module.scss";
import { useUser } from "../../../providers/user.provider";
import { Link, useLocation } from "react-router-dom";
import styles from "./AvatarUser.module.scss";
import logo from "../../../assets/svg/logo--light.svg";
import location from "../../../assets/svg/location--gray.svg";
import { useWindowDimensions } from "../../../services/widthCheck.service";
import { Badge } from "@mui/material";
import logoPremium from '../../../assets/svg/logo-premium-dark.svg'
import logoPro from '../../../assets/svg/logo-pro-dark.svg'

const AvatarUser = React.memo(() => {
  let url = useLocation();
  const user = useUser();
  const { width } = useWindowDimensions();

  return (
    <Stack direction="column" className={`${styles.avatar} align-items-center`}>
      {width > 767 && (
        <figure className={styles.avatar__logo}>
          <img src={user.user.access === "premium" ? logoPremium : user.user.access === "pro" ? logoPro :logo} 
					alt="The Only Link Logo" width={114} height={40} />
        </figure>
      )}
      <div className={styles.avatar__content}>
          <Avatar
              className={styles.avatar__photo}
              alt="User"
              src={
                  user?.currentProfile?.avatar ? user?.currentProfile?.avatar?.url : ""
              }
          />

          <div>
              <h4
                  className={`${styles.avatar__name} ff-gilroy fw-700 l-spacing mt-10`}
              >
                  {user?.currentProfile?.fullName}
              </h4>
              {/* <span className="fw-500 text-success small-text ff-gilroy">Active</span> */}
          </div>

          <Link
              to="olink/switch"
              className={`${styles.avatar__switch} ${
                  url.pathname === "/olink/switch" ? styles.active : ""
              }`}
          >
              {/* <Badge
            overlap="circular"
            color="secondary"
            variant="dot"
            className={"notification-badge notification-badge_white"}
          > */}
              <img src={avaSquareIcon} alt="icon" width={24} height={24} />
              {/* </Badge> */}
          </Link>

      </div>
    </Stack>
  );
});

export default AvatarUser;

import React, { useState, useEffect } from "react";
import { useUser } from "../../../providers/user.provider";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import copyIcon from "../../../assets/svg/copy.svg";
import style from "./OlinkAccountEdit.module.scss";

import {
  IconButton,
  TextField,
  InputAdornment,
  SelectChangeEvent,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import { useApi } from "../../../providers/api.provider";
import { Link, useNavigate } from "react-router-dom";
import { IAccountEditDTO } from "../../../interfaces/api.context";
import { ITimezone } from "../../../interfaces/user.context";
import timezones from "../../../timezones";
import save from "../../../assets/svg/save.svg";
import { useWindowDimensions } from "../../../services/widthCheck.service";
import { useGuest } from "../../../providers/guest.provider";
import PreviewPageHome from "../../ui/PreviewPageHome/PreviewPageHome";
import ChangePassword from "../ChangePassword/ChangePassword";
import Banner from "../../ui/Banner/Banner";

interface IErrors {
  firstName: string | null;
  lastName: string | null;
  oldPassword: string | null;
  password: string | null;
  repeatPassword: string | null;
}

const OlinkAccountEdit = () => {
  const { setActiveSnackBar, activeSnackBar } = useGuest();
  const { width } = useWindowDimensions();
  const user = useUser();
  const api = useApi();
  const navigate = useNavigate();
  const timeZones: ITimezone[] = [];
  for (const tz_key in timezones as ITimezone) {
    if (timezones.hasOwnProperty(tz_key)) {
      timeZones.push({
        name: tz_key,
        value: timezones[tz_key],
      });
    }
  }

  const handleClick = () => {
    if (validate()) {
      const data: IAccountEditDTO | any = {
        firstName: firstName,
        lastName: lastName,
      };

      if (oldPassword && password && repeatPassword) {
        data["oldPassword"] = oldPassword;
        data["password"] = password;
        data["repeatPassword"] = repeatPassword;
      }
      api.user.updateAccount(data).then((res) => {
        if (res) {
          const { ...newUser } = user.user;
          newUser.firstName = res.firstName;
          newUser.lastName = res.lastName;
          user.setUser(newUser);
          setStateBtn(true);
        }
      });
    }
  };

  const [firstName, setFirstName] = useState<string>(
    user.user.firstName ? user.user.firstName : ""
  );
  const [lastName, setLastName] = useState<string>(
    user.user.lastName ? user.user.lastName : ""
  );
  const [timezone, setTimezone] = useState<string>("Europe/London");
  const [oldPassword, setOldPassword] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [repeatPassword, setRepeatPassword] = useState<string>("");
  const [disableBtn, setDisableBtn] = useState<boolean>(true);
  const [errors, setErrors] = useState<IErrors>({
    firstName: "",
    lastName: "",
    oldPassword: "",
    password: "",
    repeatPassword: "",
  });

  const [showPassword, setShowPassword] = useState(false);
  const [showRepeatPassword, setShowRepeatPassword] = useState(false);
  const [showOldPassword, setShowOldPassword] = useState(false);
  const [text, setText] = useState(user.user.email!);
  const [stateBtn, setStateBtn] = useState<boolean>(false);

  /* todo: delete? */
  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = () => setShowPassword(!showPassword);

  const handleClickShowRepeatPassword = () =>
    setShowRepeatPassword(!showRepeatPassword);
  const handleMouseDownRepeatPassword = () =>
    setShowRepeatPassword(!showRepeatPassword);

  const handleClickShowOldPassword = () => setShowOldPassword(!showOldPassword);
  const handleMouseDownOldPassword = () => setShowOldPassword(!showOldPassword);

  const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    switch (event.target.id) {
      case "firstName":
        setFirstName(event.target.value?.replace(/[^\p{L}]/gu, ""));
        break;
      case "lastName":
        setLastName(event.target.value?.replace(/[^\p{L}]/gu, ""));
        break;
      case "oldPassword":
        setOldPassword(event.target.value);
        break;
      case "password":
        setPassword(event.target.value);
        break;
      case "repeatPassword":
        setRepeatPassword(event.target.value);
        break;
    }
  };

  const checkNewChanges = (): boolean => {
    let res = false;
    if (firstName !== user.user.firstName) {
      res = true;
    }
    if (lastName !== user.user.lastName) {
      res = true;
    }
    if (timezone !== user.user.timezone) {
      res = true;
    }
    if (oldPassword && password && repeatPassword) {
      res = true;
    }
    return res;
  };

  useEffect(() => {
    if (user.user.id) {
      setFirstName(user.user.firstName ? user.user.firstName : "");
      setLastName(user.user.lastName ? user.user.lastName : "");
      setText(user.user.email ? user.user.email : "");
      setTimezone(user.user.timezone ? user.user.timezone : "Europe/London");
    }
  }, [user.user.firstName, user.user.lastName]);

  useEffect(() => {
    if (checkNewChanges()) {
      setDisableBtn(false);
    } else {
      setDisableBtn(true);
    }
  });

  const validate = () => {
    const errs = { ...errors };
    let res = true;
    if (!firstName || firstName.length < 3) {
      errs.firstName = "Firstname is required!";
      res = false;
    } else {
      errs.firstName = null;
    }

    if (!lastName || lastName.length < 3) {
      errs.lastName = "Lastname is required!";
      res = false;
    } else {
      errs.lastName = null;
    }

    if (
      oldPassword.length < 8 &&
      password.length >= 8 &&
      repeatPassword.length >= 8
    ) {
      errs.oldPassword = "Password should be of minimum 8 characters length";
      res = false;
    } else {
      errs.oldPassword = null;
    }

    if (
      oldPassword.length >= 8 &&
      password.length < 8 &&
      repeatPassword.length >= 8
    ) {
      errs.password = "Password should be of minimum 8 characters length";
      res = false;
    } else {
      errs.password = null;
    }

    if (
      oldPassword.length >= 8 &&
      password.length >= 8 &&
      repeatPassword.length < 8
    ) {
      errs.repeatPassword = "Password should be of minimum 8 characters length";
      res = false;
    } else {
      errs.repeatPassword = null;
    }

    if (password !== repeatPassword) {
      errs.repeatPassword = "Both password need to be the same";
      res = false;
    } else {
      errs.repeatPassword = null;
    }

    setErrors(errs);
    return res;
  };

  const copy = async () => {
    await navigator.clipboard.writeText(text);
    setActiveSnackBar({
      state: true,
      name: "success",
      message: "Your email copied",
    });
  };

  const handleOnBack = async () => {
    navigate(-1);
  };

  const handleChangeTimezone = (event: SelectChangeEvent) => {
    setTimezone(event.target.value);
  };

  return (
    <div className="main-content">
      <div className={`${style.acc_edit} bg-gray_light account-edit`}>
        {width >= 768 && (
          <div className="page-title">
            <h2 className="text-center text-black">Account Edit</h2>
          </div>
        )}
        <div className={`${style.container} container`}>
          <Banner />
          <div className="page_top">
            <button
              type="button"
              className="btn_navigate btn_navigate_arrow"
              onClick={handleOnBack}
            >
              Back
            </button>
            {width < 768 ? (
              <h2 className="text-black text-center ff-gilroy">Account Edit</h2>
            ) : null}
          </div>

          <div className={`${style.acc_edit__wrapper} edit-card`}>
            <div className={`mb-20 flex-1`}>
              <div className={style.acc_edit__col}>
                <div className={`${style.email_block} container-inner`}>
                  <p
                    className={`${style.email_block__text} fs-14 text-gray_dark fw-400`}
                  >
                    Your email:
                  </p>
                  <p
                    className={`${style.email_block__email} text-gray_dark fw-600 text-overflow`}
                  >
                    {text}
                  </p>
                  <button
                    className={`${style.email_block__icon} btn_utility`}
                    onClick={copy}
                    disabled={!text}
                    aria-label={"Your email copy button"}
                  >
                    <img src={copyIcon} alt="" />
                  </button>
                </div>
                <TextField
                  id="firstName"
                  label="FirstName"
                  name="firstName"
                  variant="outlined"
                  value={firstName}
                  onChange={handleOnChange}
                  error={!!errors.firstName}
                  helperText={errors.firstName}
                />
                <TextField
                  id="lastName"
                  label="LastName"
                  name="lastName"
                  variant="outlined"
                  value={lastName}
                  onChange={handleOnChange}
                  error={!!errors.lastName}
                  helperText={errors.lastName}
                />
                <TextField
                  id="timezone"
                  label="Timezone"
                  name="timezone"
                  variant="outlined"
                  value={timezone}
                  disabled
                  InputLabelProps={{ style: { display: "block" } }}
                />
                {/* <FormControl>
                  <InputLabel id="timezones-lbl">Timezone</InputLabel>
                  <Select
                    labelId="timezones-lbl"
                    id="timezone"
                    label="Timezone"
                    name="timezone"
                    disabled={true}
                    value={timezone}
                    variant="outlined"
                    onChange={handleChangeTimezone}
                    IconComponent={() => null}
                    className={"styled_drop_arrow "}
                  >
                    {timeZones.map((tz: ITimezone) => {
                      return (
                        <MenuItem key={tz.name} value={tz.name}>
                          {tz.value}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl> */}
              </div>

              {/* <div>
              <h5 className="text-gray_dark fw-600 mt-5 mb-15 container-inner ff-gilroy">
                Change Password
              </h5>
              <TextField
                  id="oldPassword"
                  name="oldPassword"
                  label="Old password"
                  value={oldPassword}
                  type={showOldPassword ? "text" : "password"}
                  variant="outlined"
                  InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                              aria-label="toggle password visibility"
                              onClick={handleClickShowOldPassword}
                              onMouseDown={handleMouseDownOldPassword}
                              edge="end"
                          >
                            {showOldPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                    ),
                  }}
                  onChange={handleOnChange}
                  error={!!errors.oldPassword}
                  helperText={errors.oldPassword}
              />
              <TextField
                  id="password"
                  name="password"
                  label="New password"
                  value={password}
                  type={showPassword ? "text" : "password"}
                  variant="outlined"
                  InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                              aria-label="toggle password visibility"
                              onClick={handleClickShowPassword}
                              onMouseDown={handleMouseDownPassword}
                              edge="end"
                          >
                            {showPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                    ),
                  }}
                  onChange={handleOnChange}
                  error={!!errors.password}
                  helperText={errors.password}
              />
              <TextField
                  id="repeatPassword"
                  name="repeatPassword"
                  label="Repeat password"
                  value={repeatPassword}
                  type={showRepeatPassword ? "text" : "password"}
                  variant="outlined"
                  InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                              aria-label="toggle password visibility"
                              onClick={handleClickShowRepeatPassword}
                              onMouseDown={handleMouseDownRepeatPassword}
                              edge="end"
                          >
                            {showRepeatPassword ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                    ),
                  }}
                  onChange={handleOnChange}
                  error={!!errors.repeatPassword}
                  helperText={errors.repeatPassword}
              /> */}
              {/* </div> */}

              <div className={`${style.deleteAccount} text-center`}>
                <Link
                  to="/settings/delete"
                  className={` btn__delete text-error l-spacing_xs `}
                >
                  Delete Account
                </Link>
              </div>
            </div>

            {!stateBtn ? (
              <button
                type="submit"
                className="btn icon icon__center btn_desktop"
                disabled={disableBtn}
                onClick={handleClick}
              >
                <img
                  src={save}
                  alt="save"
                  aria-hidden="true"
                  role="presentation"
                />
                Save
              </button>
            ) : (
              <button
                type="submit"
                className={`btn btn--updated btn_desktop`}
                disabled={disableBtn}
              >
                Saved
              </button>
            )}
          </div>
          <div style={{ marginTop: 30 }}>
            <ChangePassword />
          </div>
        </div>

        {width > 767 && (
          <div className="right_bar right_bar_preview-window">
            <h4 className="ff-gilroy fw-700 l-spacing text-center">Preview</h4>
            <div className="right_bar__container">
              <PreviewPageHome />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default OlinkAccountEdit;

import React, { useEffect, useState } from "react";
import Switch from "@mui/material/Switch";
import FormControlLabel from "@mui/material/FormControlLabel";
import Avatar from "@mui/material/Avatar";
import { useUser } from "../../../providers/user.provider";
import { Link, useNavigate } from "react-router-dom";
import style from "./DetailsProfile.module.scss";

import delegate from "../../../assets/svg/delegate.svg";
import checked from "../../../assets/svg/checked--orange.svg";
import pen from "../../../assets/svg/pen--orange.svg";
import link from "../../../assets/svg/link.svg";
import { useApi } from "../../../providers/api.provider";
import { useWindowDimensions } from "../../../services/widthCheck.service";
import PreviewPageHome from "../../ui/PreviewPageHome/PreviewPageHome";
import Banner from "../../ui/Banner/Banner";

const DetailsProfile = () => {
  const { width } = useWindowDimensions();
  const user = useUser();
  const api = useApi();
  const navigate = useNavigate();

  const [active, setActive] = useState<boolean>(false);
  const [statusOlink, setStatusOlink] = useState<boolean>(false); // verify OLink true or false

  const handleOnBack = async () => {
    navigate("/olink/switch");
  };

  const switchHandler = async (event: React.ChangeEvent<HTMLInputElement>) => {
    if (user.selectedOlinkSetting?.id) {
      user.setLoading(true);
      await api.user
        .updateProfile(user.selectedOlinkSetting?.id, {
          isActive: event.target.checked,
        })
        .then(
          (res) => {
            setActive(res.isActive);
          },
          (error) => {}
        );
      user.setLoading(false);
    }
  };

  useEffect(() => {
    if (!user.selectedOlinkSetting) {
      navigate("/olink/switch");
    }
  });

  // console.log(user.selectedOlinkSetting);

  useEffect(() => {
    setActive(!!user.selectedOlinkSetting?.isActive);
  }, [user.selectedOlinkSetting]);

  return (
    <div className="main-content">
      <div className={`${style.details} bg-gray_light`}>
        {width >= 768 && (
          <div className="page-title">
            <h2 className="text-center text-black">Details</h2>
          </div>
        )}
        <div className={`${style.container} container`}>
          <Banner />
          <div className="page_top">
            <button
              type="button"
              className="btn_navigate btn_navigate_arrow"
              onClick={handleOnBack}
            >
              Back
            </button>
            {width < 768 && (
              <h2 className="text-center ff-gilroy text-black">Details</h2>
            )}
          </div>

          <div className={style.details__wrapper}>
            <div className={`${style.details__user}`}>
              <Avatar
                alt="User"
                src={
                  user.selectedOlinkSetting?.isDelegated === false
                    ? user.selectedOlinkSetting?.avatar
                      ? user.selectedOlinkSetting?.avatar.url
                      : ""
                    : user.selectedOlinkSetting?.profile?.avatar
                    ? user.selectedOlinkSetting?.profile?.avatar.url
                    : ""
                }
                className={style.details__ava}
              />
              <h4 className={`${style.details__name} ff-gilroy`}>
                {user.selectedOlinkSetting?.isDelegated === false
                  ? user.selectedOlinkSetting?.fullName
                    ? user.selectedOlinkSetting?.fullName
                    : ""
                  : user.selectedOlinkSetting?.profile
                  ? user.selectedOlinkSetting?.profile.fullName
                  : ""}
              </h4>
              <p className={`${style.details__text} small-text text_overflow`}>
                {process.env.REACT_APP_BACK_URL +
                  "/" +
                  (user?.selectedOlinkSetting?.isDelegated === false
                    ? user?.selectedOlinkSetting
                      ? user?.selectedOlinkSetting?.nickname
                      : ""
                    : user.selectedOlinkSetting?.profile?.nickname
                    ? user.selectedOlinkSetting?.profile?.nickname
                    : "")}
                {/* // (user.currentOLink?.path ? user.currentOLink?.path : "")} */}
              </p>
            </div>

            <div className={`${style.card_section} card `} role={"menu"}>
              {/* <div
                className={`${style.card_section__link} ${style.card_section__link__label}`}
              >
                <img className={style.card_section__icon} src={link} alt="" />
                <FormControlLabel
                  control={
                    <Switch
                      checked={active}
                      onChange={(e) => switchHandler(e)}
                    />
                  }
                  label="Active"
                  className="styled_label"
                />
              </div> */}
              <Link
                to={
                  user.selectedOlinkSetting?.isDelegated === false
                    ? "/olink/edit/" + user.selectedOlinkSetting?.id
                    : "/olink/edit/" + user.selectedOlinkSetting?.profile.id
                }
                className={style.card_section__link}
                role={"menuitem"}
              >
                <img className={style.card_section__icon} src={pen} alt="" />
                <span className={`${style.card_section__title} text_overflow`}>
                  Edit
                </span>
              </Link>
              {user.selectedOlinkSetting?.isDelegated === false ? (
                <Link
                  to={"delegate/" + user.selectedOlinkSetting?.id}
                  className={
                    // statusOlink
                    // ?
                    `${style.card_section__link}`
                    // : `${style.card_section__link} ${style.card_section__link__soon}`
                  }
                  role={"menuitem"}
                  tabIndex={!statusOlink ? -1 : 0}
                >
                  <img
                    className={style.card_section__icon}
                    src={delegate}
                    alt=""
                  />
                  <span
                    className={`${style.card_section__title} text_overflow`}
                  >
                    Delegate Rights
                  </span>

                  {/* {!statusOlink ? (
                    <p className={`${style.card_section__arrow} premium-color`}>
                      Soon
                    </p>
                  ) : null} */}
                </Link>
              ) : null}

              {user.selectedOlinkSetting?.isDelegated === false ? (
                <Link
                  to="/"
                  className={
                    statusOlink
                      ? `${style.card_section__link}`
                      : `${style.card_section__link} ${style.card_section__link__soon}`
                  }
                  role={"menuitem"}
                  tabIndex={!statusOlink ? -1 : 0}
                >
                  <img
                    className={`${style.card_section__icon}`}
                    src={checked}
                    alt=""
                  />
                  <span
                    className={`${style.card_section__title} text_overflow `}
                  >
                    Verify TOL
                  </span>

                  {!statusOlink ? (
                    <p className={`${style.card_section__arrow} premium-color`}>
                      Soon
                    </p>
                  ) : null}
                </Link>
              ) : null}
            </div>

            {user.selectedOlinkSetting?.isDelegated === false ? (
              <Link
                to={"/olink/delete-olink/" + user.selectedOlinkSetting?.id}
                className={`btn__delete text-error`}
              >
                Delete TOL
              </Link>
            ) : (
              <Link to={"waive"} className={`btn__delete text-error`}>
                Waive Rights
              </Link>
            )}
          </div>
        </div>

        {width > 767 && (
          <div className="right_bar right_bar_preview-window">
            <h4 className="ff-gilroy fw-700 l-spacing text-center">Preview</h4>
            <div className="right_bar__container">
              <PreviewPageHome />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default DetailsProfile;

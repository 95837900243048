import * as React from "react";
import {
  ApiContextType,
  IAccountEditDTO,
  IAddProfileDTO,
  ICodeDTO,
  IDeleteAccountDTO,
  IDeleteProfileDTO,
  IForgotDTO,
  IInterestDTO,
  // IProfileAcceptDTO,
  IProfileEditDTO,
  IResetDTO,
  ISigninDTO,
  ISignupDTO,
  IThemeSaveDTO,
  ITokensResponseDTO,
  IUserDTO,
  IEventDTO,
  Contacts,
  ISocialDTO,
  IStatisticByDTO,
} from "../interfaces/api.context";
import axios, { AxiosResponse } from "axios";
import { ILinkCreateDTO, ILinkUpdateDTO } from "../interfaces/link.interface";
import { useNavigate } from "react-router-dom";
import { ISocialAddDto } from "../interfaces/social.interface";

const API_URL = process.env.REACT_APP_MAIN_URL;
const ApiContext = React.createContext<ApiContextType>(null!);

export function ApiProvider({ children }: { children: React.ReactNode }) {
  const navigate = useNavigate();
  const getToken = () => {
    try {
      return JSON.parse(localStorage.getItem("tokens")!);
    } catch (e) {
      localStorage.removeItem("tokens");
      return {
        access_token: null,
        refresh_token: null,
      };
    }
  };
  const saveTokens = (data: ITokensResponseDTO) => {
    try {
      localStorage.setItem("tokens", JSON.stringify(data));
      setTokens(data);
    } catch (e) {}
  };

  const [tokens, setTokens] = React.useState<ITokensResponseDTO>(getToken());
  const [interests, setInterests] = React.useState<IInterestDTO[]>([]);

  const instance = axios.create({
    baseURL: API_URL,
    headers: {
      "Content-Type": "application/json",
    },
  });

  instance.interceptors.request.use(
    (config) => {
      const token = getToken();
      if (token && token.access_token) {
        if (config.headers) {
          config.headers["Authorization"] = `Bearer ${token.access_token}`;
        }
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  instance.interceptors.response.use(
    (res) => {
      return res;
    },
    async (err) => {
      const originalConfig = err.config;
      if (
        originalConfig &&
        originalConfig.url === "/auth/refresh-token" &&
        err.response &&
        err.response.status === 401
      ) {
        saveTokens({
          access_token: null,
          refresh_token: null,
        });
        navigate("/auth/signin");
        return Promise.resolve();
      }

      if (
        originalConfig &&
        originalConfig.url !== "/auth/signin" &&
        err.response
      ) {
        if (err.response.status === 401 && !originalConfig._retry) {
          originalConfig._retry = true;

          try {
            if (tokens) {
              const rs = await refresh(tokens.refresh_token!);
              instance.defaults.headers.common[
                "Authorization"
              ] = `Bearer ${rs.data.access_token}`;
              saveTokens(rs.data);
            }
            return instance(originalConfig);
          } catch (_error) {
            return Promise.reject(_error);
          }
        }
      }

      return Promise.reject(err);
    }
  );

  // Auth
  const signup = (data: ISignupDTO) => {
    return instance.post(`/auth/signup`, data).then((res: AxiosResponse) => {
      const tokens: ITokensResponseDTO = res.data;
      saveTokens(tokens);
      return tokens;
    });
  };
  const signin = (data: ISigninDTO): Promise<ITokensResponseDTO> => {
    return instance
      .post<ITokensResponseDTO>(`/auth/signin`, data)
      .then((res: AxiosResponse) => {
        const tokens: ITokensResponseDTO = res.data;
        saveTokens(tokens);
        return tokens;
      });
  };
  const otpcode = (data: ICodeDTO) => {
    return instance
      .post(`/auth/code`, data, {
        headers: {
          Authorization: `Bearer ${tokens.access_token}`,
        },
      })
      .then((res: AxiosResponse) => {
        const tokens: ITokensResponseDTO = res.data;
        saveTokens(tokens);
        return tokens;
      });
  };
  const forgot = (data: IForgotDTO) => {
    return instance.post(`/auth/forgot-pass`, data).then(() => {
      return true;
    });
  };
  const reset = (data: IResetDTO) => {
    return instance.post(`/auth/reset-pass`, data).then(() => {
      return true;
    });
  };
  const resendcode = () => {
    return instance.post(`/auth/resend-code`).then(() => {
      return true;
    });
  };
  const logout = () => {
    return instance.post(`/auth/logout`).then(() => {
      localStorage.removeItem("tokens");
      saveTokens({ access_token: null, refresh_token: null });
      return true;
    });
  };
  const clearDataLogout = () => {
    localStorage.removeItem("tokens");
    saveTokens({ access_token: null, refresh_token: null });
    return true;
  };
  const refresh = (refreshToken: string): Promise<any> => {
    return instance.post(`/auth/refresh-token`, { refreshToken });
  };
  const socialAuth = (data: any): Promise<any> => {
    return instance.post(`/auth/social`, data).then((res: AxiosResponse) => {
      const tokens: ITokensResponseDTO = res.data;
      saveTokens(tokens);
      return tokens;
    });
  };
  const googleLogin = (data: any): Promise<any> => {
    return instance
      .post(`/auth/google/login`, data)
      .then((res: AxiosResponse) => {
        const tokens: ITokensResponseDTO = res.data;
        saveTokens(tokens);
        return tokens;
      });
  };
  const facebookLogin = (data: any): Promise<any> => {
    return instance
      .post(`/auth/facebook/login`, data)
      .then((res: AxiosResponse) => {
        const tokens: ITokensResponseDTO = res.data;
        saveTokens(tokens);
        return tokens;
      });
  };

  // User
  const me = (): Promise<IUserDTO> => {
    return instance.get(`/users/me`).then((res) => res.data);
  };
  const switchOlink = (olinkId: number): Promise<IUserDTO> => {
    return instance
      .post("/profiles/" + olinkId + "/switch", { olinkId })
      .then((res) => res.data);
  };
  const updateProfile = (id: number, data: IProfileEditDTO) => {
    return instance.put("/profiles/" + id, data).then((res) => res.data);
  };
  const editProfile = (id: number, data: IProfileEditDTO) => {
    return instance.patch("/profiles/" + id, data).then((res) => res.data);
  };
  const acceptProfile = (id: number) => {
    return instance.put("/profiles/" + id + "/accept").then((res) => res.data);
  };
  const cancelProfile = (id: number) => {
    return instance
      .delete("/profiles/" + id + "/cancel")
      .then((res) => res.data);
  };
  const addOlink = (data: IAddProfileDTO) => {
    return instance.post("/profiles/add", data).then((res) => res.data);
  };
  const updateAccount = (data: IAccountEditDTO) => {
    return instance.put("/users/update-account", data).then((res) => res.data);
  };
  const deleteOlink = (id: number) => {
    return instance.delete("/profiles/" + id).then((res) => res.data);
  };
  const inviteNewUser = (email: string) => {
    return instance.post(`/users/invite`, { email }).then((res) => res.data);
  };
  const deleteAccount = (password: IDeleteAccountDTO) => {
    return instance
      .delete("/users/delete-account", { data: password })
      .then((res) => res.data);
  };
  const personalizate = (theme: string) => {
    return instance.post("", { data: theme }).then((res) => res.data);
  };
  const delegateOLink = (id: number, nickname: string) => {
    return instance
      .post("/profiles/" + id + "/delegate", { nickname })
      .then((res) => res.data);
  };
  const revokeOLink = (delegateId: number, olinkId: number) => {
    return instance
      .post("/profiles/" + olinkId + "/revoke", { delegateId })
      .then((res) => res.data);
  };
  const waiveOlink = (olinkId: number) => {
    return instance
      .put("/profiles/" + olinkId + "/waive")
      .then((res) => res.data);
  };
  const addOLinkNamed = (name: string, olinkId: number) => {
    return instance
      .post("/olink-names/add", { name, olinkId })
      .then((res) => res.data);
  };
  const editOLinkNamed = (olinkNamedId: number, name: string) => {
    return instance
      .put("/olink-names/" + olinkNamedId, { name, olinkNamedId })
      .then((res) => res.data);
  };
  const deleteOLinkNamed = (olinkNamedId: number) => {
    return instance
      .delete("/olink-names/" + olinkNamedId)
      .then((res) => res.data);
  };
  const getUserEmails = (): Promise<any> => {
    return instance.get(`/users/email-table`).then((res) => res.data);
  };
  const getProfileEmails = (profileId: number): Promise<any> => {
    return instance
      .get("/profiles/" + profileId + "/email-table")
      .then((res) => res.data);
  };
  const getNotifications = (): Promise<any> => {
    return instance.get(`/users/notifications`).then((res) => res.data);
  };
  const setNotifications = (data: number[]): Promise<any> => {
    return instance.put(`/users/notifications`, data).then((res) => res.data);
  };

  //Content links
  const addLink = (data: ILinkCreateDTO) => {
    return instance.post("/links/add", data).then((res) => res.data);
  };
  const deleteLink = (id: number) => {
    return instance.delete("/links/" + id).then((res) => res.data);
  };
  const updateLink = (id: number, data: ILinkUpdateDTO) => {
    return instance.put("/links/" + id, data).then((res) => res.data);
  };
  const updateOrder = (links: any) => {
    return instance.put("/links/update-order", links).then((res) => res.data);
  };
  const getLinkEmails = (linkId: number): Promise<any> => {
    return instance
      .get("/links/" + linkId + "/email-table")
      .then((res) => res.data);
  };
  const getLinks = (): Promise<any> => {
    return instance.get("/links/my").then((res) => res.data);
  };

  // Content socials
  const socialTypes = () => {
    return instance.get("/socials/types").then((res) => res.data);
  };
  const userSocials = () => {
    return instance.get("/socials/my").then((res) => res.data);
  };
  const addSocials = (socials: ISocialAddDto[]) => {
    return instance.post("/socials/add", socials).then((res) => res.data);
  };

  // Interests
  const getAllInterests = () => {
    return new Promise<IInterestDTO[]>((resolve, reject) => {
      if (interests && interests.length > 0) {
        resolve(interests);
      } else {
        instance.get("/interests/all").then(
          (res) => {
            setInterests(res.data);
            resolve(res.data);
          },
          (error) => {
            reject(error);
          }
        );
      }
    });
  };

  //Settings
  const saveTheme = (data: IThemeSaveDTO) => {
    return instance.post("/settings/theme", data).then((res) => res.data);
  };

  const getEvents = (
    page: number,
    limit: number,
    state: string | null,
    idProfile: number
  ) => {
    return instance
      .get(
        "/events?page=" +
          page +
          "&limit=" +
          limit +
          (state !== null ? "&state=" + state : "") +
          "&idProfile=" +
          idProfile
      ) // +"&idProfile="
      .then((res) => res.data);
  };

  const getEventsByIdProfile = (idProfile: number) => {
    return instance
      .get(`/events/all?idProfile=${idProfile}`)
      .then((res) => res.data);
  };

  // Add Event
  const addEvent = (data: IEventDTO) => {
    return instance.post("/events/add", data).then((res) => res.data);
  };

  // Delete Event
  const deleteEvent = (id: number) => {
    return instance.delete("/events/" + id).then((res) => res.data);
  };

  // Change Event
  const changeEvent = (data: IEventDTO, id: number) => {
    return instance.put("/events/" + id, data).then((res) => res.data);
  };

  // Delete avatar for user
  // /v1/api/profiles/{id}/avatar

  const addCoverForProfiles = (data: FormData, id: number) => {
    return instance
      .post(`/profiles/${id}/avatar`, data, {
        headers: {
          "content-type": "multipart/form-data",
        },
      })
      .then((res) => res);
  };

  const deleteUserAvatar = (id: number) => {
    return instance.delete(`/profiles/${id}/avatar`).then((res) => res);
  };

  const updatePassword = (data: any) => {
    return instance.put("/users/update-password", data).then((res) => {});
  };

  const addCoverProLink = (data: FormData, linkId?: number) => {
    if (typeof linkId === "number" && linkId > 0) {
      data.set("linkId", linkId.toString());
    }
    return instance.post("/links/" + linkId + "/cover", data, {
      headers: {
        "content-type": "multipart/form-data",
      },
    });
  };

  const deleteCoverProLink = (id: number) => {
    return instance.delete(`/links/${id}/cover`).then((res) => res);
  };

  const getLocations = () => {
    return instance.get("/locations/all").then((res) => res);
  };

  const getLocales = () => {
    return instance.get("/locales/all").then((res) => res);
  };

  const addStyleForQRCode = (data: string) => {
    return instance
      .post("/settings/qrcode", { options: data })
      .then((res) => res);
  };

  const addCoverForQRcode = (data: FormData, id?: number) => {
    if (typeof id === "number" && id > 0) {
      data.set("qrCodeId", id.toString());
    }
    return instance.post(`/settings/qrcode/${id}/logo`, data, {
      headers: {
        "content-type": "multipart/form-data",
      },
    });
  };

  const deleteCoverForQRcode = (id: number) => {
    return instance.delete(`/settings/qrcode/${id}/logo`).then((res) => res);
  };

  const contacts = (data: Contacts) => {
    return instance.post("/contacts/add", data).then((res) => res);
  };

  const otpLog = () => {
    localStorage.removeItem("tokens");
    saveTokens({ access_token: null, refresh_token: null });
    return true;
  };

  const key = "pk_31b9b73cb71db2285b225a742af7f49744e1480d";

  const getPreviewImage = (url: string) => {
    return axios
      .get(
        `https://links.theonly.link/services/link-data/extract-v4-2?url=${url}`,
        {
          headers: {
            "Content-Type": "application/json",
            "Accept": "*",
            // "Accept-Encoding": "gzip, deflate, br",
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Methods": "GET",
            "Access-Control-Allow-Headers": "Content-Type",
            // "Access-Control-Allow-Credentials": "true",
            "Cache-Control": "no-cache",
            // "Host": "links.theonly.link",
          },
        }
        // "https://jsonlink.io/api/extract?url=" + url + `&api_key=${key}`
        // `&api_key=${process.env.REACT_APP_PREVIEW_KEY}`
      )
      .then((res) => res);
  };

  const getProfiles = (id: number) => {
    // page: number, limit: number
    return instance
      .get("/profiles?" + "idUser=" + id) // + "&page=" + page + "&limit=" + limit
      .then((res) => res);
  };

  const getDelegates = (id: number) => {
    //  page: number, limit: number
    return instance
      .get("/delegates?" + "idUser=" + id) //+ "&page=" + page + "&limit=" + limit
      .then((res) => res);
  };

  const acceptDelegates = (id: number) => {
    return instance.put(`/delegates/${id}/accept`);
  };

  const cancelDelegates = (id: number) => {
    return instance.delete(`/delegates/${id}/cancel`);
  };

  const waiveDelegatesProfile = (id: number) => {
    return instance.put(`/delegates/${id}/waive`);
  };

  const revokeProfile = (id: number) => {
    return instance.post(`/delegates/${id}/revoke`);
  };

  const getOlink = () => {
    return instance.get(`/olink-names`);
  };

  const updateOrderForSocialLinks = (item: ISocialDTO) => {
    return instance.put("/socials/update-order", { socials: item });
  };

  const getData = () => {
    return instance.get("/statistics/all").then((res) => res.data);
  };

  const statisticBy = (data: IStatisticByDTO): Promise<any> => {
    return instance
      .post<IStatisticByDTO>("/statistics/by", data)
      .then((res) => res.data);
  };

  const getAllUserOlinks = () => {
    return instance.get("/olinks/all").then((res) => res.data);
  };

  const getAllRelatedOlinks = () => {
    return instance.get("/olinks/related").then((res) => res.data);
  };

  const getPaymentLinks = () => {
    return instance.get("/payment-links").then((res) => res.data);
  };

  const trialOrder = () => {
    return instance.post("/orders/trial", { qty: 1 }).then((res) => res.data);
    // TO DO: next feature for stripy
    // {
    // 	"qty": 1,
    // 	"totalAmount": 0,
    // 	"orderItems": [
    // 			{
    // 				"product": {
    // 							"name": "prod_trial"
    // 					}
    // 			}
    // 	]
    // }
  };

  let value: ApiContextType = {
    accessToken: tokens && tokens.access_token ? tokens.access_token : null,
    clearToken: () => {
      localStorage.removeItem("tokens");
      saveTokens({ access_token: null, refresh_token: null });
    },
    auth: {
      socialAuth,
      facebookLogin,
      googleLogin,
      signup,
      signin,
      otpcode,
      forgot,
      reset,
      resendcode,
      logout,
      clearDataLogout,
      otpLog,
    },
    user: {
      me,
      updateProfile,
      editProfile,
      acceptProfile,
      cancelProfile,
      addOlink,
      switchOlink,
      updateAccount,
      deleteOlink,
      inviteNewUser,
      deleteAccount,
      personalizate,
      delegateOLink,
      revokeOLink,
      waiveOlink,
      addOLinkNamed,
      editOLinkNamed,
      deleteOLinkNamed,
      getUserEmails,
      getProfileEmails,
      getNotifications,
      setNotifications,
      addEvent,
      deleteEvent,
      changeEvent,
      deleteUserAvatar,
      updatePassword,
      addCoverForProfiles,
      getEvents,
      getOlink,
      updateOrderForSocialLinks,
      getEventsByIdProfile,
      getPaymentLinks,
      trialOrder,
    },
    link: {
      addLink,
      deleteLink,
      updateLink,
      updateOrder,
      addCoverProLink,
      deleteCoverProLink,
      getPreviewImage,
      getLinkEmails,
      getLinks,
    },
    social: {
      socialTypes,
      userSocials,
      addSocials,
    },
    interest: {
      getAllInterests,
    },
    settings: {
      saveTheme,
      addStyleForQRCode,
      addCoverForQRcode,
      deleteCoverForQRcode,
    },
    listLocations: {
      getLocations,
    },
    listLocale: {
      getLocales,
    },
    support: {
      contacts,
    },
    profiles: {
      getProfiles,
    },
    delegates: {
      getDelegates,
      acceptDelegates,
      cancelDelegates,
      waiveDelegatesProfile,
      revokeProfile,
    },
    statistics: {
      getData,
      statisticBy,
    },
    olinks: {
      getAllUserOlinks,
      getAllRelatedOlinks,
    },
  };
  return <ApiContext.Provider value={value}>{children}</ApiContext.Provider>;
}

export function useApi() {
  return React.useContext(ApiContext);
}

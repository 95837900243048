import React, { useState } from "react";
import { Button } from "@mui/material";

import style from "./NotFound.module.scss";
import logo from "../../../assets/svg/logo-dark.svg";
import notFound from "../../../assets/svg/not_found.svg";
import styles from "../OlinkHome/OlinkHome.module.scss";
import { useNavigate } from "react-router-dom";
import {useWindowDimensions} from "../../../services/widthCheck.service";
import {configure} from "@testing-library/react";

const NotFound = () => {
  const { width } = useWindowDimensions();
  const navigate = useNavigate();
  const handleOnLanding = () => {
    navigate("/");
  };
  return (
    <div className={`main-content not_found`}>
      <div className={`${style.not_found} bg-gray_light`}>
        <div className={`${style.container} container`}>
          {width > 767 && (
            <figure className={style.not_found__logo}>
              <img  src={logo} alt="logo" />
            </figure>
          )}
          <div className={`${style.not_found__content} edit-card`}>
            <figure className={style.not_found__emoji}>
              <img src={notFound} alt="404 error" />
              <figcaption>
                <h1 className="text-center text-orange ff-gilroy fw-700 mb-10">
                  Oops, sorry
                </h1>
              </figcaption>
            </figure>
            <h4 className="text-center ff-gilroy fw-700 mb-20 l-spacing text-gray_dark">
              Page not found
            </h4>
            <div className={style.not_found__textContainer}>
              <p className="fw-400 text-center text-gray_dark_2">
                Maybe the user has deleted the page or&nbsp;hasn&rsquo;t created
                it&nbsp;yet)
              </p>
              <p className="fw-400 text-center text-gray_dark_2 mb-40">
                But this is&nbsp;not a&nbsp;problem, you can familiarize yourself
                with our service on&nbsp;the landing page
              </p>
            </div>
            <button className="btn" onClick={handleOnLanding}>
              To Landing Page
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NotFound;

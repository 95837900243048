export enum ELinkType {
  Regular = "regular",
  Audio = "audio",
  Video = "video",
  ProLink = "prolink",
  Subscription = "subscription",
}

export interface ILinkCreateDTO {
  type: string;
  title: string;
  url: string;
  visible: boolean;
  order: number;
  cover: FormData | null;
}

export interface ILinkUpdateDTO {
  type?: string;
  title?: string;
  url?: string | null | undefined;
  visible?: boolean;
  order?: number;
  cover?: { id: number | null; key: string | null; url: string | null };
}

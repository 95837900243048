import * as React from "react";
import { Navigate, useLocation } from "react-router-dom";
import jwtDecode from "jwt-decode";
import { useApi } from "../../../providers/api.provider";

const PrivateRoute = ({ children }: { children: JSX.Element }) => {
  let api = useApi();
  let location = useLocation();
  if (api.accessToken) {
    const { confirmed, ...other } = jwtDecode<any>(api.accessToken);
    if (confirmed === true) {
      return children;
    } else {
      return <Navigate to="/auth/code" state={{ from: location }} replace />;
    }
  } else {
    return <Navigate to="/auth/signin" state={{ from: location }} replace />;
  }
};

export default PrivateRoute;

import React, { useEffect, useState } from "react";
import styles from "./Header.module.scss";
import Sidebar from "../Sidebar/Sidebar";
import logo from "../../../assets/svg/logo-dark.svg";
import bell from "../../../assets/svg/bell--orange.svg";
import bellFilled from "../../../assets/svg/bell--notification--orange.svg";
import { useApi } from "../../../providers/api.provider";
import { useNavigate, useLocation } from "react-router-dom";
import { useUser } from "../../../providers/user.provider";
import {
  ENotificationStatus,
  INotification,
} from "../../../interfaces/user.context";
import { Badge } from "@mui/material";
import { useWindowDimensions } from "../../../services/widthCheck.service";
import Banner from "../Banner/Banner";

const Header = React.memo(() => {
  const api = useApi();
  const user = useUser();

  const navigate = useNavigate();

  const handleOnNotification = () => {
    navigate("/settings/notifications");
  };

  const [notifActive, setNotifActive] = useState<INotification[]>([]);

  useEffect(() => {
    if (user.notifications.length > 0) {
      const notif = user.notifications.filter(
        (item) => item.status === ENotificationStatus.new
      );
      if (notif.length > 0) {
        setNotifActive(notif);
      }
    }
  }, [user.notifications]);

  const { width } = useWindowDimensions();

  let url = useLocation();

  return (
    <>
      {(width >= 768 && url.pathname === "/auth/signin") ||
      (width >= 768 && url.pathname === "/auth/signup") ||
      (width >= 768 && url.pathname === "/auth/code") ||
      (width >= 768 && url.pathname === "/auth/forgot") ||
      (width >= 768 && url.pathname === "/auth/recovery") ||
      url.pathname === "/auth/welcome" ? null : (
        <>
          {/* <Banner /> */}
          <header
            style={{
              ...(url.pathname === "/olink/my-tol" ||
              url.pathname === "/olink/premium"
                ? { maxWidth: "100%" }
                : {}),
              ...(user.banner && width <= 768 ? { position: "static" } : {}),
            }}
            className={styles.header}
            data-testid="Header"
          >
            <div className={`${styles.container} container`}>
              {api.accessToken && url.pathname !== "/auth/code" ? (
                <>
                  <div className={styles.header__icon}>
                    <Sidebar />
                  </div>
                  {width < 768 && (
                    <img
                      className={styles.header__logo}
                      src={logo}
                      alt="logo"
                      width={114}
                      height={36}
                    />
                  )}

                  <button
                    type="button"
                    className={`${styles.header__premium} ${
                      url.pathname === "/settings/notifications"
                        ? styles.active
                        : ""
                    }`}
                    onClick={handleOnNotification}
                  >
                    <Badge
                      className={styles.header__badge}
                      badgeContent={notifActive.length}
                    >
                      <img
                        src={notifActive.length > 0 ? bellFilled : bell}
                        alt="notification"
                        width={24}
                        height={24}
                      />
                    </Badge>
                  </button>
                </>
              ) : (
                <img
                  className={styles.header__logo}
                  src={logo}
                  alt="logo"
                  width={114}
                  height={36}
                />
              )}
            </div>
          </header>
        </>
      )}
    </>
  );
});

export default Header;

import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import Dialog from "@mui/material/Dialog";
import Premium from "../../../pages/Premium/Premium";

const PaymentDialog = () => {
  const [close, setClose] = useState(false);
  const location = useLocation();

  useEffect(() => {
    if (localStorage.getItem("payment")) {
      setClose(true);
    }
  }, [location.pathname]);

  const handleClose = () => {
    localStorage.removeItem("payment");
    setClose(false);
  };

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="customized-dialog-title"
      open={close}
      className="popup"
      PaperProps={{
        style: {
          maxWidth: "1200px",
        },
      }}
    >
      <Premium dialog={close} close={handleClose} />
    </Dialog>
  );
};

export default PaymentDialog;

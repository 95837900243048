import React, { useState } from "react";
import Switch from "@mui/material/Switch";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switches from "../../ui/Switches/Switches";

import styles from "./Newsletter.module.scss";
import { useNavigate } from "react-router-dom";
import { useWindowDimensions } from "../../../services/widthCheck.service";
import PreviewPageHome from "../../ui/PreviewPageHome/PreviewPageHome";
import Banner from "../../ui/Banner/Banner";

const Newsletter = () => {
  const { width } = useWindowDimensions();
  const arrayNewsletter = ["Email Newsletter", "Title", "Title"];
  const navigate = useNavigate();
  const handleOnBack = async () => {
    navigate(-1);
  };
  return (
    <div className="main-content">
      <div className={`${styles.newsletter} newsletter bg-gray_light`}>
        {width >= 768 && (
          <div className="page-title">
            <h2 className="text-center text-black">Newsletter</h2>
          </div>
        )}
        <div className={`container`}>
          <Banner />
          <div className="page_top">
            <button
              type="button"
              className="btn_navigate btn_navigate_arrow"
              onClick={handleOnBack}
            >
              Back
            </button>
            {width < 768 && (
              <h2 className="text-black text-center ff-gilroy">Newsletter</h2>
            )}
          </div>

          <div className={`${styles.newsletter__list} newsletter__list card`}>
            {arrayNewsletter.map((item, index) => {
              return <Switches name={item} key={index} />;
            })}
          </div>
        </div>

        {width > 767 && (
          <div className="right_bar right_bar_preview-window">
            <h4 className="ff-gilroy fw-700 l-spacing text-center">Preview</h4>
            <div className="right_bar__container">
              <PreviewPageHome />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Newsletter;

import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import SettingsMain from "../../pages/SettingsMain/SettingsMain";
import OlinkAccountEdit from "../../pages/OlinkAccountEdit/OlinkAccountEdit";
import OLinkManagement from "../../pages/OLinkManagement/OLinkManagement";
import DeleteAccount from "../../pages/DeleteAccount/DeleteAccount";
import PrivacyPolicy from "../../pages/PrivacyPolicy/PrivacyPolicy";
import Notification from "../../pages/Notification/Notification";
import Newsletter from "../../pages/Newsletter/Newsletter";
import DeleteOlink from "../../pages/DeleteOlink/DeleteOlink";
import DetailsProfile from "../../pages/DetailsProfile/DetailsProfile";
import DelegateOlink from "../../pages/DelegateOlink/DelegateOlink";
import InviteNewUser from "../../pages/InviteNewUser/InviteNewUser";
import OlinkMyDelegated from "../../pages/OlinkMyDelegated/OlinkMyDelegated";
import WaiveRights from "../../pages/WaiveRights/WaiveRights";
import ChangePassword from "../../pages/ChangePassword/ChangePassword";
import ContactSupport from "../../pages/ContactSupport/ContactSupport";
import TermsConditions from "../../pages/TermsConditions/TermsConditions";
import Plans from "../../pages/Plans/Plans";

const Settings = () => (
  <Routes>
    <Route path="/main" element={<SettingsMain />} />
    <Route path="/account-edit" element={<OlinkAccountEdit />} />
    {/* <Route path="/management" element={<OLinkManagement />} /> */}
    <Route path="/delete" element={<DeleteAccount />} />
    <Route path="/privacy-policy" element={<PrivacyPolicy />} />
    <Route path="/terms-conditions" element={<TermsConditions />} />
    <Route path="/support" element={<ContactSupport />} />
    <Route path="/notifications" element={<Notification />} />
    <Route path="/newsletter" element={<Newsletter />} />
    {/* <Route path="/change-password" element={<ChangePassword />} /> */}
    <Route path="/delete-olink/:id" element={<DeleteOlink />} />
    <Route path="/details" element={<DetailsProfile />} />
    <Route path="/details/delegate/:id" element={<DelegateOlink />} />
    <Route path="/details/delegated-to-me" element={<OlinkMyDelegated />} />
    <Route path="/details/waive" element={<WaiveRights />} />
    <Route path="/plans" element={<Plans />} />
    {/* <Route path="/details/invite" element={<InviteNewUser />} /> */}
    <Route path="*" element={<Navigate to="/" />} />
  </Routes>
);

export default Settings;

import React from "react";
import Dialog from "@mui/material/Dialog";
import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemButton,
} from "@mui/material";
import { useUser } from "../../../../providers/user.provider";
import Typography from "@mui/material/Typography";
import { useNavigate } from "react-router-dom";

import "./LogoutDialog.scss";

import sad from "../../../../assets/svg/emoji/sad.svg";

interface ITolDialog {
  open: boolean;
  onClose: () => void;
  onLogout: () => void;
  [key: string]: any;
}

const LogoutDialog = (props: ITolDialog) => {
  const { open, onClose, onLogout, ...other } = props;

  const handleClose = () => {
    onClose && onClose();
  };

  const handleLogout = () => {
    onLogout && onLogout();
  };

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="customized-dialog-title"
      open={props.open}
      className="popup"
    >
      <DialogContent>
        <DialogTitle className="text-black">Are You Log Out?</DialogTitle>
        <figure className={"dialog-image"}>
          <img src={sad} alt="sad emoji" width={200} height={200} role={"presentation"}/>
        </figure>
        <Typography gutterBottom>
          Come back soon!
        </Typography>
      </DialogContent>
      <DialogActions disableSpacing={true}>
        <button
          onClick={handleLogout}
          className="btn"
        >
          Log Out
        </button>
        <button
          onClick={handleClose}
          className="btn__outline"
        >
          Cancel
        </button>
      </DialogActions>
    </Dialog>
  );
};

export default LogoutDialog;

import React, { useState } from "react";
import {
  GuestContextType,
  GuestProfileDTO,
  GuestUserAlert,
} from "../interfaces/guest.context";
import axios from "axios";
import moment from "moment";
import {
  browserName,
  deviceDetect,
  isTablet,
  isDesktop,
  isMobile,
} from "react-device-detect";

const API_URL = process.env.REACT_APP_MAIN_URL;
const GuestContext = React.createContext<GuestContextType>(null!);

export function GuestProvider({ children }: { children: React.ReactNode }) {
  const [id, setId] = useState<string | null>(null);
  const [idUser, setIdUser] = useState<number | null>(null);
  const [idProfile, setIdProfile] = useState<number | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [previewImage, setPreviewImage] = useState([]);

  const [activeSnackBar, setActiveSnackBar] = useState<GuestUserAlert>({
    state: false,
    name: "",
    message: "",
  });

  const instance = axios.create({
    baseURL: API_URL,
    headers: {
      "Content-Type": "application/json",
    },
  });

  const date = new Date();
  const currentTime = moment();
  const userAgent = navigator.userAgent;
  const checkLanguage = navigator.language.split(/-|_/)[0];

  const checkUserDevice = deviceDetect(userAgent);

  const checkSettingsEvent = async (events: any) => {
    return events.filter((el: any) => {
      if (el.qr_code_state && el.qr_code_state !== true) {
        if (currentTime.isSameOrAfter(el.duration_start) !== false) {
          let check = false;
          const settingsUser =
            el.device_state &&
            el.browsers_state &&
            el.locale_state &&
            el.location_state &&
            el.source_state;

          const checkBrowser = el.browsers
            .split(",")
            .filter((i: any) => browserName.includes(i) && i !== "").length;

          const checkDesktop = el.device_desktop
            .split(",")
            .filter((i: any) =>
              isDesktop ? checkUserDevice.osName.includes(i) && i !== "" : null
            ).length;

          const checkMobile = el.device_mobile
            .split(",")
            .filter((i: any) =>
              isMobile
                ? checkUserDevice.os.toLowerCase() === i.toLowerCase() &&
                  i !== ""
                : null
            ).length;

          const checkTablet = el.device_tablet
            .split(",")
            .filter(
              (i: any) =>
                isTablet &&
                checkUserDevice.os.toLowerCase() === i.toLowerCase() &&
                i !== ""
            ).length;

          const checkLocales = el.locales.filter((item: any) => {
            return checkLanguage.includes(item.code) && item.code !== "";
          }).length;

          if (
            (currentTime.isSameOrAfter(el.duration_start) &&
              moment(date).isAfter(el.duration_end) !== true) ||
            el.duration_end === null
          ) {
            if (el.device_state) {
              if (checkDesktop > 0 || checkMobile > 0 || checkTablet > 0) {
                check = true;
              } else {
                check = false;
                return;
              }
            }
            if (el.browsers_state) {
              if (checkBrowser > 0) {
                check = true;
              } else {
                check = false;
                return;
              }
            }
            if (el.location_state) {
              check = false;
              return;
              // if (checkLocation > 0) {
              //   check = true;
              // } else {
              //   check = false;
              //   return;
              // }
            }

            if (el.locale_state) {
              if (checkLocales > 0 || el.locale === "all") {
                check = true;
              } else {
                check = false;
                return;
              }
            }
            if (el.source_state) {
              // if (el.source) {
              check = false;
              return;
              // }
            }
          }

          if (
            moment(date).isAfter(el.duration_end) !== true ||
            (el.duration_end === null && !settingsUser)
          ) {
            check = true;
          } else {
            check = false;
          }

          return el.state === "active" && check;
        }
      }
    });
  };

  const getGuest = (checkAction?: boolean): Promise<GuestProfileDTO> => {
    setLoading(true);
    let isRedirect = false;
    return instance
      .get(`/guest/user-by-id?id=${id}`)
      .then(async (res: any) => {
        if (res.data.events.length !== 0) {
          const [activeEvent] = await checkSettingsEvent(res.data.events);
          if (activeEvent && activeEvent.state === "active") {
            window.location.replace(activeEvent.url);
            isRedirect = true;
          }
        }
        setIdUser(res.data.userId);
        setIdProfile(res.data.profileId);
        return res.data;
      })
      .catch(() => {})
      .finally(() => {
        if (!isRedirect) {
          setLoading(false);
        }
      });
  };

  const addEmail = (email: string, idLink: number) => {
    return instance
      .post(`/guest/add-email`, {
        email: email,
        userId: idUser,
        profileId: idProfile,
        subscriptionId: idLink,
      })
      .then((res) => res.data);
  };

  let value: GuestContextType = {
    id,
    setId,
    getGuest,
    addEmail,
    activeSnackBar,
    setActiveSnackBar,
    loading,
    setLoading,
    previewImage,
    setPreviewImage,
  };

  return (
    <GuestContext.Provider value={value}>{children}</GuestContext.Provider>
  );
}

export function useGuest() {
  return React.useContext(GuestContext);
}

import React, { useCallback, useState, useRef } from "react";
import { useGuest } from "../../providers/guest.provider";
import { ESnackBarType } from "../ui/SnackBar/SnackBar";
import { Cropper } from "react-cropper";
import "cropperjs/dist/cropper.css";
import Dialog from "@mui/material/Dialog";
import iconPlus from "../../assets/svg/plus.svg";
import { DialogActions } from "@mui/material";
import { useApi } from "../../providers/api.provider";
import { useUser } from "../../providers/user.provider";
import imageCompression from "browser-image-compression";

interface ICropper {
  className?: any;
  type?: string;
  picture?: (file: FormData, picture: string, fileName: any) => void;
  actionForSaveImage?: (formData: FormData) => void;
  dataImage?: any;
  typeInput?: string;
  maxSizeWidth?: any;
  clear?: boolean;
  loading?: () => void; // TODO: Disabled loading for edit cover
}

export const CropperForImage: React.FC<ICropper> = (props: ICropper) => {
  const cropperRef = useRef<Cropper | any>(null);
  const cropperInstance = useRef(null);
  const [image, setImage] = useState("");
  const [open, setOpen] = useState(false);
  const [avatarFile, setAvatarFile] = useState("");
  const [cropper, setCropper] = useState<any>();
  const [typeFile, setTypeFile] = useState<any>("");
  const [imageName, setImageName] = useState<any>("");
  const user = useUser();
  const { setActiveSnackBar } = useGuest();

  const onChange = (e: any) => {
    if (open) {
      cropperRef.current?.reset();
    }
    if (e.target.files[0]) {
      if (
        e.target.files[0].type === "image/png" ||
        e.target.files[0].type === "image/jpg" ||
        e.target.files[0].type === "image/jpeg" ||
        e.target.files[0].type === "image/gif"
      ) {
        // console.log("target", e.target.files?.[0]);
        const file = e.target.files[0];
        const fileSizeInMB = file.size / (1024 * 1024);
        if (fileSizeInMB <= 5) {
          const reader = new FileReader();
          reader.onloadend = () => {
            const imageUrl = reader.result;
            const image = new Image();
            image.src = imageUrl as any;
            setImage(reader.result as any);
            image.onload = () => {
              const width = image.width;
              const height = image.height;
              // console.log(fileSizeInMB);
              // console.log(width, height);
              // if (width <= 2048 && height <= 2048) {
              setOpen(!open);
              e.preventDefault();
              let files;
              if (e.dataTransfer) {
                files = e.dataTransfer.files;
              } else if (e.target) {
                files = e.target.files;
              }

              setTypeFile(e.target.files?.[0]);
              setAvatarFile(e.target.files[0].name);
              setImageName(e.target.files[0].name);
              // } else {
              //   setOpen(false);
              //   setImage("");
              //   setActiveSnackBar({
              //     state: true,
              //     name: ESnackBarType.error,
              //     message: "Maximum size of 2048x2048 pixels",
              //   });
              // }
            };
          };
          reader.readAsDataURL(e.target.files[0]);
        } else {
          setOpen(false);
          setImage("");
          setActiveSnackBar({
            state: true,
            name: ESnackBarType.error,
            message: "Maximum file size of 5MB",
          });
        }
      } else {
        setOpen(false);
        setImage("");
        setActiveSnackBar({
          state: true,
          name: ESnackBarType.error,
          message: "You cannot upload this type of file. Only PNG, JPG, GIF",
        });
      }
    }
  };

  // 	const formData = new FormData();
  // 	console.log(blob);
  // 	formData.append("file", blob, imageName);
  // 	if (props.type !== "create") {
  // 		user.setLoading(true);
  // 		await props.actionForSaveImage?.(formData);
  // 		user.setLoading(false);
  // 	} else {
  // 		props.picture?.(formData, blob);
  // 	}
  // });

  const saveImg = async () => {
    // user.setLoading(true);
    // props.loading?.(); // TODO: Disabled loading for edit cover
    const options = {
      maxSizeMB: 0.4,
      maxWidthOrHeight: 1920,
      useWebWorker: true,
    };
    cropper.getCroppedCanvas().toBlob(async (blob: any) => {
      if (props.type !== "create") {
        // user.setLoading(true);
        const compressedFile = await imageCompression(blob, options);
        const formData = new FormData();
        formData.append("file", compressedFile, imageName);
        await props.actionForSaveImage?.(formData);
        // user.setLoading(false);
      } else {
        const compressedFile = await imageCompression(blob, options);
        const formData = new FormData();
        formData.append("file", compressedFile, imageName);
        await props.picture?.(formData, blob, imageName);
        // user.setLoading(false);
      }
    });

    // user.setLoading(false);
    setOpen(!open);
    setImage("");
  };

  const handleClickDialog = () => {
    setOpen(!open);
  };

  return (
    <div className={"imgCropper"}>
      <Dialog
        onClose={handleClickDialog}
        aria-labelledby="customized-dialog-title"
        open={open}
        className="img-cropper"
      >
        <Cropper
          style={props.className}
          className={"img-cropper__body mb-40"}
          zoomTo={0.1}
          initialAspectRatio={1}
          src={image}
          viewMode={2}
          minCropBoxHeight={10}
          minCropBoxWidth={10}
          background={false}
          responsive={true}
          autoCropArea={1}
          checkOrientation={false}
          aspectRatio={NaN}
          guides={true}
          cropBoxResizable={true}
          cropBoxMovable={true}
          onInitialized={(instance: any) => {
            setCropper(instance);
          }}
        />
        <DialogActions>
          <button className="btn icon justify-content-center" onClick={saveImg}>
            <img
              src={iconPlus}
              width={24}
              height={24}
              alt=""
              role="presentation"
            />
            Add
          </button>
        </DialogActions>
      </Dialog>

      <label
        // onClick={handleClickDialog}
        className="edit-card-block__add-photo d-block text-orange
                  text-underline text-center ff-gilroy l-spacing_xs"
        // htmlFor={"imgInput"}
      >
        <span>{props.dataImage ? "Change photo" : "Add photo"}</span>
        <input
          key={"key" + open}
          className="visually-hidden"
          type="file"
          id={props.typeInput === "change" ? "imgInputProLink" : "imgInput"}
          onChange={onChange}
        />
      </label>
    </div>
  );
};

export default CropperForImage;

import React, { useEffect } from "react";
import styles from "./WaiveRights.module.scss";
import warning from "../../../assets/svg/warning.svg";
import { useNavigate } from "react-router-dom";
import { useApi } from "../../../providers/api.provider";
import { useUser } from "../../../providers/user.provider";
import {
  IDelegateProfilesContext,
  IProfileContext,
} from "../../../interfaces/user.context";
import { useWindowDimensions } from "../../../services/widthCheck.service";
import Banner from "../../ui/Banner/Banner";

const WaiveRights = () => {
  const { width } = useWindowDimensions();
  const navigate = useNavigate();
  const api = useApi();
  const user = useUser();

  const handleOnBack = async () => {
    navigate(-1);
  };

  // console.log(user);

  const getValueForProfilesUser = async () => {
    if (user.user.id && user?.userProfiles?.length === 0) {
      await api.profiles.getProfiles(user.user.id).then((res) => {
        //, 1, 10
        user.setUserProfiles(res.data);
      });
    }
  };

  // console.log(user.delegates);
  const getValueForDelegatesUser = async () => {
    if (user.user.id && user?.delegates?.length === 0) {
      await api.delegates.getDelegates(user.user.id).then((res) => {
        //, 1, 10
        user.setDelegates(res.data);
      });
    }
  };

  useEffect(() => {
    if (user.user.id) {
      getValueForProfilesUser();
    }
  }, [user.user.id, user?.userProfiles?.length]);

  useEffect(() => {
    if (user.user.id) {
      getValueForDelegatesUser();
    }
  }, [user.user.id, user?.delegates?.length]);

  const handleWaive = async () => {
    if (user.user.id && user.selectedOlinkSetting?.id) {
      api.delegates
        .waiveDelegatesProfile(user.selectedOlinkSetting?.id)
        .then((res: any) => {
          if (
            user.selectedOlinkSetting?.profile?.id ===
            user?.user?.selectedProfile
          ) {
            res.data.profile.role = res.data.role;
            let olink = res.data.profile.olinks?.find(
              (o: any) => o.state === true
            );
            if (olink) {
              user.setCurrentOLink(olink);
            }
            const { profile, ...newUserData } = res.data;
            user.setUser(newUserData);
            if (res.data.profile.interest) {
              user.setProfileInterest(res.data.profile.interest);
            }
            if (res.data.profile?.settings?.theme) {
              const { theme } = res.data.profile?.settings;

              user.setPersonalizate({
                themeName: theme.themeName,
                color: {
                  background: theme.color_background,
                  type: theme.color_type,
                },
                btn: {
                  rounding: theme.btn_rounding,
                  outline: theme.btn_outline,
                  white: theme.btn_white,
                  shadow: theme.btn_shadow,
                },
                fontsName: theme.fontsName,
                statusLogo: theme.statusLogo,
              });
            }
            user.setCurrentProfile(res.data.profile);
            api.social.userSocials().then((res) => {
              user.setUserSocials(res);
            });
            api.link.getLinks().then((res) => {
              const newArray = [...res];
              const indexProlink = res.findIndex(
                (el: any) => el.type === "prolink"
              );

              if (indexProlink !== -1) {
                const prolinkElement = newArray.splice(indexProlink, 1)[0];
                newArray.unshift(prolinkElement);
              }

              newArray.sort(
                (itemOne, itemTwo) => itemOne.order - itemTwo.order
              );
              user.setProfileLinks(newArray);
            });
          }
          const cProfile = user.userProfiles.data.find(
            (p: any) => p.id === res.selectedProfile
          );

          if (cProfile) {
            const {
              interest,
              delegates,
              userSocials,
              links,
              settings,
              ...otherProfile
            } = cProfile;
            user.setCurrentOLink([]);
            user.setEvents([]);
            user.setListOlink([]);
            user.setInActiveEvents([]);
            user.setCurrentProfile(cProfile);
            user.setPersonalizate({
              themeName: cProfile.settings!.theme.themeName,
              color: {
                background: cProfile.settings!.theme.color_background,
                type: cProfile.settings!.theme.color_type,
              },
              btn: {
                rounding: cProfile.settings!.theme.btn_rounding,
                outline: cProfile.settings!.theme.btn_outline,
                white: cProfile.settings!.theme.btn_white,
                shadow: cProfile.settings!.theme.btn_shadow,
              },
              fontsName: cProfile.settings!.theme.fontsName,
              statusLogo: cProfile.settings!.theme.statusLogo,
            });

            let qrCodeOptions;
            if (
              cProfile.settings?.qrcode &&
              cProfile.settings?.qrcode.options
            ) {
              qrCodeOptions = JSON.parse(cProfile.settings!.qrcode.options);
            } else {
              qrCodeOptions = {
                width: 140,
                height: 140,
                image: "",
                dotsOptions: {
                  color: "",
                  type: "extra-rounded",
                },
                cornersSquareOptions: {
                  type: "none",
                  color: "",
                },
                cornersDotOptions: {
                  type: "none",
                  color: "",
                },
                imageOptions: {
                  crossOrigin: "anonymous",
                  imageSize: 0.5,
                  margin: 10,
                },
                backgroundOptions: {
                  color: "#fff",
                },
              };
            }

            if (
              cProfile?.settings?.qrcode?.logo &&
              cProfile?.settings?.qrcode?.logo.url
            ) {
              qrCodeOptions = {
                ...qrCodeOptions,
                image: cProfile.settings!.qrcode.logo.url,
              };
            } else {
              qrCodeOptions = {
                ...qrCodeOptions,
                image: null,
              };

              user.setQrCode(qrCodeOptions);
            }

            user.setProfileInterest(interest);
          }

          const updateDelegate = user.delegates.data.filter((item: any) => {
            return item.id !== user.selectedOlinkSetting?.id;
          });
          // console.log(updateDelegate);
          user.setDelegates((prevState: any) => ({
            ...prevState,
            data: updateDelegate,
          }));
          user.setSelectedOlinkSetting(null);
          navigate("/olink/switch");
        });
    }
  };
  // console.log(user.delegates.data);

  return (
    <div className="main-content">
      <div className={`${styles.delete} bg-gray_light`}>
        {width >= 768 && (
          <div className="page-title">
            <h2 className="text-center text-error">Waive Rights</h2>
          </div>
        )}
        <div className={`${styles.container} container`}>
          <Banner />
          <div className="page_top">
            <button
              type="button"
              className="btn_navigate btn_navigate_arrow"
              onClick={handleOnBack}
            >
              Back
            </button>
            {width < 768 ? (
              <h2 className="text-center ff-gilroy text-error">Waive Rights</h2>
            ) : null}
          </div>

          <div className="deleteContent edit-card">
            <p className=" deleteContent__text fw-400 text-color text-gray_dark_2 text-center mb-50">
              Are you sure you want to&nbsp;refuse rights to&nbsp;this OLink.
              By&nbsp;waiving the rights, you will lose the ability to&nbsp;edit
              this OLink and it&nbsp;will disappear from your OLink list.
            </p>

            <div className="deleteContent__wrapper">
              <figure className={styles.delete__warning}>
                <img src={warning} alt="Warning sign" />
                <figcaption className="text-gray_dark fw-600 text-center">
                  Are you sure you want to&nbsp;waive the rights to&nbsp;this
                  OLink?
                </figcaption>
              </figure>

              <div className={`${styles.answers} delete__answers`}>
                <button
                  className={`${styles.answers__no} fs-16`}
                  onClick={handleOnBack}
                  aria-label={"No go back"}
                >
                  No
                </button>
                <button
                  className={`${styles.answers__yes} text-error`}
                  onClick={handleWaive}
                >
                  Yes, waive
                </button>
              </div>
            </div>
          </div>
        </div>

        {width > 767 && (
          <div className="right_bar">
            <h4 className="ff-gilroy fw-700 l-spacing text-center">Preview</h4>
            <div className="right_bar__container"></div>
          </div>
        )}
      </div>
    </div>
  );
};

export default WaiveRights;
